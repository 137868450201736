import useProcessedWeather from "hooks/useProcessedWeather";
import {RibbonLabel} from "./ribbonlabel";
import {isUndefined} from "lodash";

export const WavePeriodRibbonLabel = (props) => {
  const {data} = useProcessedWeather(props.currentPlacedActivity.place);

  if (
    isUndefined(data?.chartdata) ||
    data.chartdata.length === 0 ||
    data?.metadata?.wave_period_types.length === 0
  )
    return;

  const wave_period_types = data?.metadata?.wave_period_types;
  const label_lookup = {
    waveperiod: "Wave Period",
    waveperiod2: "Wave Period 2",
  };
  return (
    <RibbonLabel dims={props.dims}>
      {props.prependLabel && (
        <span className="legend-place legend-text">{props.prependLabel}</span>
      )}
      {wave_period_types.map((t) => {
        return (
          <span key={t} className={"legend-text " + t}>
            {label_lookup[t]}
          </span>
        );
      })}
      {/* 
      <span className={"legend-text waveperiod"}>
        {wave_period_types.length > 1? "Wave Periods" : "Wave Period"}
      </span> */}
    </RibbonLabel>
  );
};
