import { isUndefined } from "lodash";
import {useLocation, useNavigate} from "react-router-dom";


const useLocationState = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pageData = location.state?.pageData;

    const setPlace = (place) => {
        const pageData = location.state?.pageData;
        const pathToPlace = location.state?.pathToPlace;
        if (pageData) {
            if (pathToPlace === "place") {
                pageData.place = place;
                pageData.tracking = false;
                if (pageData.name == "New Page") {
                    pageData.name = place.name;
                }
            } else {
                pageData.memberribbons[parseInt(pathToPlace)].place = place
            }
        } else {
            throw new Error("pageData not found in location state")
        }
    }

    const getPlace = () => {
        const pageData = location.state?.pageData;
        const pathToPlace = location.state?.pathToPlace;
        if (pageData) {
            if (pathToPlace === "place") {
                return pageData.place;
            } else {
                return pageData.memberribbons[parseInt(pathToPlace)].place
            }
        } else {
            return undefined;
        }
    }
    const navToCallback =(updatePageData) => {
        const callback = location.state?.callback;

        if (callback) {
            if (isUndefined(updatePageData)) {
                navigate(callback, {state: {pageData: pageData}});
            
            } else {
                navigate(callback, {state: {pageData: updatePageData}});
            }
        } else {
            throw new Error("callback not found in location state")
        }
    }

    const navToMap = (pageDataToSend, index) => {
        
        navigate("/map", {
            state: {
              callback: window.location.pathname,
              pathToPlace: isUndefined(index) ? "place": index,
              pageData: pageDataToSend,
            },
          });
    }
    const hasCallback = () => {
        const callback = location.state?.callback;
        return !isUndefined(callback)}

    return {pageData, hasCallback, setPlace, getPlace, navToCallback, navToMap} 
}

export default useLocationState;