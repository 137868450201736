import {useEffect, useRef} from "react";

import Locate from "leaflet.locatecontrol";
import {useMap} from "react-leaflet";

/**
 * Button to show user's current GPS location
 *
 * @returns {null}
 */
export const FindGPSLocationButton = () => {
  const map = useMap({});

  const lc = useRef(null);

  useEffect(() => {
    const locateOptions = {
      position: "topleft",
      maxZoom: 16,
      zoom: 11,
      drawMarker: false,
      strings: {
        title: "Find my location",
      },
    };
    if (lc.current === null) {
      lc.current = new Locate(locateOptions);
      lc.current.addTo(map);
    }
  }, [map, lc]);

  return null;
};
