export const RibbonLabel = (props) => {
  return (
    <div
      className="ribbon"
      style={{
        height: props.dims.svgheight + props.dims.ribbonMargin + props.dims.legendOffset,
        width: props.dims.width,
        marginTop: -props.dims.legendOffset,
      }}>
      <div className="floatlegend">
          {props.children}
       </div>
    </div>
  );
};
