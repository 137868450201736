import {toast} from "react-hot-toast";

export let API_HOST = process.env.REACT_APP_BACKEND_API_URL;

export const ACTIVITY_PATH = "/activities/";
export const LOGIN_PATH = "/login/";
export const PLACE_PATH = "/places/";
export const PLACEDACTIVITY_PATH = "/placedactivities/";
export const PLACEDACTIVITYSET_PATH = "/placedactivitysets/";
export const USERDATA_PATH = "/userdata/";
export const FACTORSET_PATH = "/factorsets/";
export const GRIDDATA_PATH = "/griddataendpoints/";
export const DATASOURCE_PATH = "/datasourcelocations/";
// stripe redirects 
export const CHECKOUT_PATH = "/subscriptions/checkout";
export const BILLING_PATH = "/subscriptions/manage"; 
export const PAGE_PATH = "/pages/"; 
export const RIBBON_PATH = "/ribbons/"
export const TIDE_PATH = "/tidestations/"
export const MAILINGLIST_ADD_PATH = "/mailinglist/subscribe"
export const MAILINGLIST_REMOVE_PATH = "/mailinglist/unsubscribe"
export const OPENMETEO_PATH = "/openmeteo/weather"
export const OPENMETEO_REPLACE_NWS_PATH = "/openmeteo/bigweather"
export const CHATCREATE_PATH = "/pages/chatcreate/"
export const ASTRO_PATH = "/astro/"


// Common headers sent with all API calls
const HEADERS = {"Content-Type": "application/json"};

if (API_HOST?.includes("staging-django")) {
  // if case of PR subdomain from render
  API_HOST = "https://staging-api.getoutweather.com";
} else if (API_HOST?.includes("prod-django")) {
  //this is some other render.com given URL
  API_HOST = "https://api.getoutweather.com";
}
// otherwise, we are probably in dev and
// API_HOST is probably http://127.0.0.1:8000 so we'll just leave it

/**
 * Calls API to delete path
 *
 * @param {String} path
 * @returns {Promise<any>}
 */
export async function api_delete(path) {
  const drf_token = localStorage.getItem("drfToken");
  if (drf_token) {
    HEADERS["Authorization"] = `Token ${drf_token}`;
  }

  const response = await fetch(`${API_HOST}${path}`, {
    method: "DELETE",
    credentials: "include",
    headers: HEADERS,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  // just return because we don't need to parse the empty 204 response
  return;
}

/**
 *
 *
 * @param path
 * @returns {Promise<any>}
 */
export async function api_get(path) {
  const drf_token = localStorage.getItem("drfToken");
  if (drf_token) {
    HEADERS["Authorization"] = `Token ${drf_token}`;
  }

  const response = await fetch(`${API_HOST}${path}`, {
    credentials: "include",
    headers: HEADERS,
  });

  if (!response.ok) {
      const error = await response.json()
      throw new Error(`Request failed with status ${response.status}: ${error.detail}`);
    }
  return await response.json();
}

/**
 *
 * @param path
 * @param body
 * @returns {Promise<any>}
 */
export async function api_post(path, body, options={}) {
  const drf_token = localStorage.getItem("drfToken");
  if (drf_token) {
    HEADERS["Authorization"] = `Token ${drf_token}`;
  }

  const response = await fetch(`${API_HOST}${path}`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(body),
    headers: HEADERS,
    signal: options.signal,
  });

  if (!response.ok) {
    if (response.status === 400) {
      console.log("400 Error", response);
      toast.error(response.statusText, {duration: 4000});
    }
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
}



/**
 *
 * @param path
 * @param body
 * @returns {Promise<any>}
 */
export async function api_put(path, body) {
  const drf_token = localStorage.getItem("drfToken");
  if (drf_token) {
    HEADERS["Authorization"] = `Token ${drf_token}`;
  }

  const response = await fetch(`${API_HOST}${path}`, {
    method: "PUT",
    body: JSON.stringify(body),
    credentials: "include",
    headers: HEADERS,
  });

  if (!response.ok) {
    if (response.status === 409) {
      toast.error("Refreshing page.  Try again.", {duration: 1000});
      return api_get(path);
    } else if (response.status === 403) {
      toast.error("Permission Error.", {duration: 2000});
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  }

  return await response.json();
}
