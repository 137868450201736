import useSWR, {mutate} from "swr";
import {toast} from "react-hot-toast";

const getGPSCoordinates = async (_, forceLookup = false) => {
  return new Promise((resolve, reject) => {
    const storedLocation = localStorage.getItem("currentGeoLocation");
    // const currentTime = new Date().getTime();
    if (!forceLookup && storedLocation) {
      // Check in local storage first
      const rawLocation = JSON.parse(storedLocation);
      const locationData = {
        ...rawLocation,
        lat: parseFloat(rawLocation.lat),
        lon: parseFloat(rawLocation.lon),
      };
      // recursively call self with forceLookup true to update the location
      getGPSCoordinates("geolocation", true)
        .then((place) => {
          // don't mutate unless the location has changed over 0.0001 degrees
          if (
            locationData.lat.toFixed(4) !== place.lat.toFixed(4) ||
            locationData.lon.toFixed(4) !== place.lon.toFixed(4)
          ) {
            mutate("geolocation", place, false);
          }
        })
        .catch((error) => {
          toast.error(error.message + ". Using last known location.");
        });
      resolve(locationData);
    } else {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const freshPlace = {
              lat: parseFloat(position.coords.latitude.toFixed(5)),
              lon: parseFloat(position.coords.longitude.toFixed(5)),
              name: "Current Location",
              preposition: "at",
            };
            localStorage.setItem(
              "currentGeoLocation",
              JSON.stringify(freshPlace),
            );
            resolve(freshPlace);
          },
          (error) => {
            reject(error);
          },
        );
      } else {
        reject("Location services are not supported");
      }
    }
  });
};

function useGeoLocation(needed) {
  const {data, mutate, error} = useSWR(
    needed ? "geolocation" : null,
    getGPSCoordinates,
    {revalidateOnFocus: false}, // Disable automatic revalidation on focus
  );

  const setGPSLocation = (newPlace) => {
    //new place shoudl have the format:
    //  {
    //   lat: 41.2342,
    //   lon: -71.234,
    //   name: "Current Location",
    //   preposition: "at",
    // };
    // Update the location in local storage
    localStorage.setItem("currentGeoLocation", JSON.stringify(newPlace));
    // Mutate the data to update the UI optimistically
    mutate(newPlace, false);
  };

  // const wrapLocation = (lat, lon, name = "Current Location") => {
  //   return {place: makePlace(lat, lon, name), activity: play};
  // };

  return {
    place: data,
    setGPSLocation,
    error,
  };
}

export default useGeoLocation;
