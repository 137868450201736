// an ES7 component the same as ChatCreateDialog.js except that it hosts a long form text entry
// and a submit button.  The submit button should be disabled until the user has entered some text.
// the submit handler will post the text to the backend and then close the dialog.
// there will also be a cancel button in the bottom left that just closes the dialog.

import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal} from "react-bootstrap";
import {Form, Row, Col} from "react-bootstrap";
import {useEffect} from "react";
import {api_post, CHATCREATE_PATH} from "api/backend";
import {useNavigate, useLocation} from "react-router-dom";

const ChatCreateDialog = ({title = "Get Out Goal", showOnLoad = false}) => {
  const [show, setShow] = useState(showOnLoad);
  const [textAreaValue, setTextAreaValue] = useState("");
const [abortController, setAbortController] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    setShow(showOnLoad);
  }, [showOnLoad]);

  const [isLoading, setIsLoading] = useState(false);

  const handleTextAreaChange = (event) => {
    setTextAreaValue(event.target.value);
  };

  const handleSubmit = (event) => {
    const controller = new AbortController();
    const signal = controller.signal;
    event.preventDefault();
    // Send the contents of textarea
    setIsLoading(true);
    setAbortController(controller);
    api_post(
      `${CHATCREATE_PATH}`,
      {
        prompt: textAreaValue,
      },
      {signal}
    )
      .then((response) => {
        setIsLoading(false);
        console.log("Response from Prompt", response);
        if (response.slug) {
          console.log("Prompt slug", response.slug);          
          setShow(false);
          navigate("/page/view/" + response.slug);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.log("Error submitting Prompt", error);
        }
      });
  };
  const handleCancel = () => {
    if (abortController) {
        abortController.abort();
    }
    navigate(-1);
    setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              Enter your Get Out Goal, and I will build a weather page for it.
              What are you planning and where?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
          </Form.Group>

          <Row>
            <Col>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
            <Col className="text-end">
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Submit"
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChatCreateDialog;
