import useSWR from "swr";

import {api_get, PLACE_PATH, GRIDDATA_PATH, OPENMETEO_REPLACE_NWS_PATH} from "api/backend";

// const useScoredWeather = (placedactivity) => {
//   const [lat, lon, activity] = [lat, lon, activity];
//   const {data, error} = useSWR(
//     lat && lon && activity ? {lat, lon, activity} : null,
//     loadWeather,
//     {refreshInterval: 15 * 60 * 1000, dedupingInterval: 5 * 60 * 1000}
//   );
//   return {data, error};
// };

// const fetchWeather = (place) => {
//   const reply = await api_get(`${PLACE_PATH}${place.slug}/weather/`);
// console.log(reply)
//   return reply;
// }
const useNWSWeather = (place, snowhist=false) => {
  const {data, error, mutate} = useSWR(
    place?.slug
      ? `${PLACE_PATH}${place.slug}/weather/?snowhist=${snowhist}`
      : place?.lat && place?.lon
      ? `${OPENMETEO_REPLACE_NWS_PATH}?lat=${place.lat}&lon=${place.lon}&snowhist=${snowhist}&units=US`
      : null,
    api_get,
    {
      refreshInterval: 15 * 60 * 1000,
      dedupingInterval: 5 * 60 * 1000,
      errorRetryCount: 2,
      errorRetryInterval: 2000,
    }
  );
  return {data, error, mutate};
};


export default useNWSWeather;
