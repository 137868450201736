import * as d3 from "d3";


import useScoredWeather from "hooks/useScoredWeather";
import {draw_time_axes_tz} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import variables from "styles/custom.scss";
import {useCallback} from "react";
import { isUndefined } from "lodash";

const draw_grades = (
  g,
  data,
  width,
  height,
  activity,
  xScale,
  ymargin,
  notimes,
  noshownow
) => {
  //Yscales

  const grade_domain = [
    undefined,
    "F",
    "D",
    "D+",
    "C-",
    "C",
    "C+",
    "B-",
    "B",
    "B+",
    "A-",
    "A",
    "A+",
  ];
  var yScale = d3.scalePoint().domain(grade_domain).range([0, -height]);


  // gridlines in y axis function
  function make_y_gridlines() {
    return d3.axisLeft(yScale).ticks(8);
  }

  if (!notimes) {
    const yAxis = d3
      .axisLeft(yScale)
      .tickSize(0)
      .tickValues(["D", "C", "B", "A"]);

    g.select("#yaxis").remove();
    g.append("g")
      .attr("id", "yaxis")
      .attr("transform", `translate(0,${height + ymargin})`)
      .call(yAxis);

    g.select("#ygrid").remove();
    g.append("g")
      .attr("class", "grid")
      .attr("id", "ygrid")
      .attr("transform", `translate(0,${height + ymargin})`)
      .call(make_y_gridlines().tickSize(-width).tickFormat(""));
  }
  draw_time_axes_tz(
    g,
    xScale,
    height + 2 * ymargin,
    data.nightblocks,
    notimes,
    data?.metadata?.timezone,
    noshownow? false: true
  );

  var color = d3
    .scaleOrdinal()
    .domain(grade_domain)
    .range([
      undefined,
      variables.fcolor,
      variables.dcolor,
      variables.dpluscolor,
      variables.cminuscolor,
      variables.ccolor,
      variables.cpluscolor,
      variables.bminuscolor,
      variables.bcolor,
      variables.bpluscolor,
      variables.aminuscolor,
      variables.acolor,
      variables.apluscolor,
    ]);

  g.append("g")
    .selectAll("rect")
    .data(
      data.chartdata.filter(
        (d) => d["activities"][activity.slug].grade !== "NA"
      )
    )
    .join("rect")
    .attr("x", (d) => xScale(d.time))
    .attr(
      "y",
      (d) =>
        height + yScale(d["activities"][activity.slug].grade) + ymargin
    )
    .attr("width", (width / data.chartdata.length) * 0.7)
    .attr("height", (d) => {
      return Math.abs(yScale(d["activities"][activity.slug].grade));
    })
    .attr("fill", (d) => color(d["activities"][activity.slug].grade));
};

export const GradeRibbon = (props) => {
  const {data} = useScoredWeather(
    props.currentPlacedActivity
  );

  const draw_func = useCallback(
    (svg) => {
      const xScale = make_xscale(
        props.dims.width,
        props.dims.pixelPerHour,
        data?.chartdata
      );
     
      const ymargin = 20;
      draw_grades(
        svg,
        data,
        props.dims.width,
        props.dims.unitHeight - ymargin * 2,
        props.currentPlacedActivity?.activity,
        xScale,
        ymargin,
        props.notimes,
        props.noshownow
      );
    },
    [data, props.currentPlacedActivity?.activity, props.dims, props.notimes, props.noshownow]
  );

  if (isUndefined(data?.chartdata) || data.chartdata.length === 0)
    return (
      <div className="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );

  if (isUndefined(props.currentPlacedActivity?.activity))
      return (
        <div> Error: This Score Ribbon Needs an Activity </div>
        )

  return (
        <Ribbon draw_func={draw_func} dims={props.dims} />
  );
};
