import {useState, useEffect} from "react";
import {isEmpty} from "lodash";
import Button from "react-bootstrap/Button";
import L from "leaflet";
import {GeoSearchControl} from "leaflet-geosearch";
import LocationIQProvider from "util/mylocationiqprovider";
import {
  MapContainer,
  TileLayer,
  useMap,
  useMapEvents,
  ZoomControl,
} from "react-leaflet";
import toast from "react-hot-toast";
import {useLocation} from "react-router-dom";
import useUserData from "hooks/useUserData";
import {FindGPSLocationButton} from "components/map/findgpslocationbutton";
import {NewMarker} from "components/map/newmarker";
import {PlaceMarkers} from "components/map/placemarkers";
import {isUndefined} from "lodash";
import "leaflet-geosearch/dist/geosearch.css";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";
import "leaflet/dist/leaflet.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import useLocationState from "hooks/useLocationState";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Fly map to current placed activity
// REPLACED BY THE BUILTIN autoPan behavior for markers.
function FlyToCurrentPlacedActivity({place}) {
  const map = useMap();

  useEffect(() => {
    if (place)
      map.flyTo([place.lat, place.lon], Math.max(15, map.getZoom()), {
        animate: true,
        duration: 2,
      });
  }, [map, place]);

  return null;
}

// sets a new clickedMapLocation state when user clicks
const ClickToAddPlace = ({setClickedMapLocation, helperToastId}) => {
  useMapEvents({
    click: (e) => {
      toast.dismiss(helperToastId);
      setClickedMapLocation({
        lat: e.latlng.lat.toFixed(5),
        lon: e.latlng.lng.toFixed(5),
      });
    },
  });
};

/*
Map view allows users to browse and create placed activities

The map view has a few jobs
1. reference for where the app believe the user is currently, arrived at when a user clicks from 
a "current location" link at the top of the ribbons
2. to highlight a selected saved place
3. to allows users to click to add a new place

*/
const Map = () => {
  // The location of the last click, where to show a temporary location marker before getting named
  const [clickedMapLocation, setClickedMapLocation] = useState(null);
  const [map, setMap] = useState(null);
  // geoSearchLoaded ensures the Search bar is loaded first in the top left corner
  const [geoSearchLoaded, setGeoSearchLoaded] = useState(false);

  const [hasPlace, setHasPlace] = useState(false);
  const helperToastId = "helperToast";
  // const {placeslug} = useParams();

  // pull in any place that was passed in via React Router
  const { getPlace, hasCallback} = useLocationState();
  const [curPlace, setCurPlace] = useState(undefined);
  const [showMarkers, setShowMarkers] = useState(false);

  const location_place = getPlace();

  const handleZoomEnd = () => {
    setShowMarkers(true);
  };

  useEffect(() => {
    setShowMarkers(false);
  }, []);

  // useEffect(() => {

  // }, [location_place, hasPlace]);

  // This pushed the window back to top left in case the user scrolled away in another view
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "instant"});
  }, []);

  const {userdata, isLoggedIn} = useUserData();

  useEffect(() => {
    if (map) {
      map.on("zoomend", handleZoomEnd);
    }
    const provider = new LocationIQProvider({
      params: {
        key: process.env.REACT_APP_LOCATIONIQ_API_KEY,
        countrycodes: ["US"],
        addressdetails: 1,
      },
    });
    const searchControl = new GeoSearchControl({
      provider: provider,
      style: "button",
      position: "topleft",
      autoClose: true,
      keepResult: true,
      showMarker: false,
      resultFormat: function (t) {
        return "" + t.result.label;
      },
    });

    if (map && !geoSearchLoaded) {
      map.addControl(searchControl);
      map.on("geosearch/showlocation", (e) => {
        setClickedMapLocation({
          lat: e.location.y,
          lon: e.location.x,
          name: e.location.label,
        });
      });
      setGeoSearchLoaded(true);
    }
    if (location_place) {
      setCurPlace(location_place);
      toast.dismiss(helperToastId);
      if (!hasPlace) {
        setHasPlace(true);
      }
    }
    // else {
    //   // only look for user's location when a location wasn't passed in via React Router
    //   // with location = useLocation()

    //   if (map && !geoSearchLoaded) {
    //     if (isUndefined(curPlace)) {
    //       const toastId = toast.loading("Finding Location...", {
    //         duration: 4000,
    //       });
    //       map.on("locationfound", () => {
    //         toast.success("Found Your Location", {
    //           id: toastId,
    //         });
    //       });
    //       map.on("locationerror", () => {
    //         toast.error(
    //           "Unable to find your location automatically. Click magnifier to find place by name.",
    //           {
    //             id: toastId,
    //           },
    //         );
    //       });

    //       map.locate({
    //         setView: true,
    //         maxZoom: 12,
    //       });
    //     }
    //   }
    // }
  }, [map, geoSearchLoaded, hasPlace, curPlace, location_place]);

  useEffect(() => {
    if (!hasPlace && isEmpty(userdata?.places)) {
      toast(
        (t) => (
          <span>
            <h5>
              Add Place by tapping the map or search with{" "}
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </h5>
          </span>
        ),
        {duration: 4000, id: helperToastId},
      );
    }
    return () => {
      toast.dismiss(helperToastId);
    };
  }, [userdata, hasPlace]);

  return (
    <MapContainer
      // Container size is set in custom.scss
      id="mapcontainer"
      ref={setMap}
      // center = {[41.29555582638414, -96.00313057761886]}
      center={
        curPlace ? [curPlace.lat, curPlace.lon] : [41.2955, -96.0031] //middle of USA
      }
      scrollWheelZoom={true}
      zoom={curPlace ? 10 : 4}
      zoomControl={false}>
      <TileLayer
        attribution='Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'
        url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}"
        maxZoom={16}
      />
      {hasPlace && <FlyToCurrentPlacedActivity place={curPlace} />}

      {/* Wait for the  geoSearch to occupy top left before adding*/}

      {geoSearchLoaded && <FindGPSLocationButton />}
      {geoSearchLoaded && <ZoomControl position="topleft" />}

      <ClickToAddPlace
        setClickedMapLocation={setClickedMapLocation}
        helperToastId={helperToastId}
      />
      {(!hasPlace || showMarkers) && <PlaceMarkers curPlace={curPlace} />}

      {hasCallback() && <NewMarker
        clearNewLocation={() => setClickedMapLocation(null)}
        isActive
        isLoggedIn={isLoggedIn}
        clickedMapLocation={clickedMapLocation}
        map={map}
      />}
    </MapContainer>
  );
};

export default Map;
