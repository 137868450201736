import useProcessedWeather from "hooks/useProcessedWeather";
import {RibbonLabel} from "./ribbonlabel";
import {isUndefined} from "lodash";

export const WaveHeightRibbonLabel = (props) => {
  const {data} = useProcessedWeather(props.currentPlacedActivity.place);

  if (
    isUndefined(data?.chartdata) ||
    data.chartdata.length === 0 ||
    data?.metadata?.wave_height_types?.length === 0
  )
    return;

  const wave_height_types = data.metadata.wave_height_types
    ? data.metadata.wave_height_types
    : "waveheights";

  const label_lookup = {
    waveheight: "Wave Height",
    windwaveheight: "Wind Wave Height",
    waveheights: "Wave Heights", // generic to use while loading
  };
  return (
    <RibbonLabel dims={props.dims}>
      {props.prependLabel && (
        <span className="legend-place legend-text">{props.prependLabel}</span>
      )}
      {wave_height_types.map((t) => {
        return (
          <span key={t} className={"legend-text " + t}>
            {label_lookup[t]}
          </span>
        );
      })}
    </RibbonLabel>
  );
};
