import SunCalc from "suncalc3";
import {DateTime} from "luxon";

export const makeNightBlock = (datetimes, lat, lon, timezone) => {
  let night_blocks = [];
  const first = datetimes[0]

  const zonify = (sunevent) => {
    return DateTime.fromISO(sunevent.value.toISOString(), {zone: timezone});
    //return DateTime.fromISO(sunevent.value.toISOString());

  };

  const makeSunInfo = (datetime) => {
    try {
      let sun_info = SunCalc.getSunTimes(
        datetime.toUTC().toJSDate(),
        lat,
        lon,
        0,
        false,
        false
      );
      zonify(sun_info.sunsetEnd);
      return sun_info;
    } catch {
      console.log("ERROR", datetime);
    }
  };
  //TODO: add  height

  let sun_info = makeSunInfo(first);
  let previousDay = first;

  if (first > zonify(sun_info.sunsetEnd)) {
    // time starts after sunset
    night_blocks.push(first);
  } else if (first < zonify(sun_info.sunriseStart)) {
    // first is before dawn
    night_blocks.push(first);
    night_blocks.push(zonify(sun_info.sunriseStart));
    night_blocks.push(zonify(sun_info.sunsetEnd));
  } else {
    // time starts mid-day
    night_blocks.push(zonify(sun_info.sunsetEnd));
  }
  const finaltime = datetimes[datetimes.length - 1];

  datetimes.forEach((datetime) => {
    if (datetime.ordinal > previousDay.ordinal) {
      // it's a new day!
      // const test_date = new Date(t.year, t.month, t.date, 12, 0, 0, 0, 0);
      sun_info = makeSunInfo(datetime);
      if (finaltime > zonify(sun_info.sunriseStart)) {
        night_blocks.push(zonify(sun_info.sunriseStart));
      } else {
        night_blocks.push(finaltime);
      }
      if (finaltime > zonify(sun_info.sunsetEnd)) {
        night_blocks.push(zonify(sun_info.sunsetEnd));
      }
      previousDay = datetime;
    }
  });
  if (night_blocks[night_blocks.length - 1].equals(zonify(sun_info.sunsetEnd))) {
    // if last appended was a sunsetEnd
    // close out final block
    night_blocks.push(finaltime);
  }

  let block_pairs = [];
  night_blocks.forEach((t, i) => {
    if (i % 2 === 0) block_pairs.push([t, night_blocks[i + 1]]);
  });
  return block_pairs;
};

function roundMinutes(rawdate) {
  const date = new Date(rawdate);
  date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
  date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

  return date;
}

// return if it is a night time, benefit of doubt goes to day eg: exact sunset or sunrise
export const is_night = (block_pairs, time) => {
  //var is_night = time < new Date(block_pairs[0][1].getTime() - 20*60*1000)
  let is_night = false;

  if (time > roundMinutes(block_pairs[0][0])) {
    is_night = time < roundMinutes(block_pairs[0][1]);
  }

  // iterate over pairs of night time start and end time (sunset = pair[0], sunrise = pair[1])
  for (const pair of block_pairs) {
    const earlylight = roundMinutes(pair[1]);
    const lastlight = roundMinutes(pair[0]);
    if (time < lastlight) {
      // if time in question is less that "current" sunset
      // stop looking and return whatever was most recently found
      break;
    }
    if (time >= lastlight && time < earlylight) {
      is_night = true;
    }
  }
  return is_night;
};
