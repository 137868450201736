import useSWR from "swr";

import {api_get, OPENMETEO_PATH} from "api/backend";
import {isUndefined} from "lodash";
import {DateTime} from "luxon";

// export const processWeatherData = (url) => {
//   const data = api_get(url).then((data) => {
//     // Get the current local time
//     //const localTime = DateTime.local();
//     // Calculate the difference in minutes

//     if (!isUndefined(data?.chartdata)) {
//       // TODO: move this to server side to support alerts.
//       // calc and add activity data to chartdata
//       //const timeDifference = localTime.setZone(data.metadata.timezone).offset - localTime.offset;
//       const times = data.chartdata.map((d) =>
//         DateTime.fromISO(d.utctimestr, {
//           zone: data.metadata.timezone,
//         }),
//       );

//       const new_chartdata = data.chartdata.map((record) => {
//         const new_record = {
//           ...record,
//           time: new Date(record.utctimestr),
//           datetime: DateTime.fromISO(record.utctimestr, {
//             zone: data.metadata.timezone,
//           }),
//           //"offsetMinutes": timeDifference,
//         };

//         return new_record;
//       });
//       return {...data, chartdata: new_chartdata};
//     } else {
//       // end check if data isUndefined
//       return undefined;
//     }
//   });
//   return data;
// };

const useOpenMeteo = (place, weatherdata) => {
  const {data: meteoData, error, mutate} = useSWR(
    place?.lat && place?.lon
      ? `${OPENMETEO_PATH}?lat=${place.lat}&lon=${place.lon}&units=US`
      : null,
    api_get,
    {
      refreshInterval: 15 * 60 * 1000,
      dedupingInterval: 5 * 60 * 1000,
      errorRetryCount: 2,
      errorRetryInterval: 2000,
    },
  );
  const newData = {...weatherdata};

  newData.metadata = {...meteoData?.metadata, ...newData.metadata, units: {...meteoData?.metadata?.units, ...newData.metadata?.units}};

  newData?.chartdata &&
  meteoData?.chartdata &&
  newData.chartdata.forEach((record, i) => {
    newData.chartdata[i] = {...meteoData.chartdata[record.utctimestr], ...record}    
  });

  return {data: newData, error, mutate};
};

export default useOpenMeteo;
