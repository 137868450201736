import * as d3 from "d3";

import useProcessedWeather from "hooks/useProcessedWeather";
import {
  draw_time_axes_tz,
  draw_yaxes,
  draw_line,
  draw_marker_text,
} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import {isUndefined} from "lodash";

export const WaveHeightRibbon = (props) => {
  const {data} = useProcessedWeather(props.currentPlacedActivity.place);

  if (
    isUndefined(data?.chartdata) ||
    data.chartdata.length === 0 ||
    data?.metadata?.wave_height_types?.length === 0
  )
    return;
  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    data?.chartdata
  );

  const draw_wave_heights = (g, data, width, height, waveTypeList, xScale) => {
    //Yscales
    let maxheight = d3.max(data.chartdata, function (d) {
      return d3.max(waveTypeList.map((name) => d[name]));
    });
    // roundup to closest 10
    maxheight = Math.ceil(maxheight / 10) * 10 + 5;

    const yScale = d3
      .scaleLinear()
      .domain([-4, maxheight])
      .range([height, 0])
      .nice();

    draw_time_axes_tz(
      g,
      xScale,
      height,
      data.nightblocks,
      false,
      data.metadata.timezone
    );
    draw_yaxes(g, yScale, width, "ft");

    waveTypeList.forEach((name) => {
      draw_line(g, data.chartdata, name, xScale, yScale);
      draw_marker_text(
        g,
        data.chartdata,
        name,
        name !== "windwaveheight",
        xScale,
        yScale,
        ""
      );
    });
  };
  let draw_func = (svg) => {
    draw_wave_heights(
      svg,
      data,
      props.dims.width,
      props.dims.unitHeight,
      data.metadata.wave_height_types,
      xScale
    );
  };

  return (
    <div className="ribbon">
      <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
