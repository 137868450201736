import useSWR from "swr";
import {api_get, api_post, api_delete, ACTIVITY_PATH} from "api/backend";
import useUserData from "hooks/useUserData";
import {isUndefined} from "lodash";

const useActivities = (getlist) => {
  const {userdata, updateUserData} = useUserData();

  const {data: activities, error, mutate} = useSWR(getlist ? `${ACTIVITY_PATH}`: null, api_get);

  const addActivity = async (newactivity) => {
    if (isUndefined(newactivity)) {
      newactivity = {name: "New Score", requirements: {times: {day: true, night: true}}, public: true}
    }
    const returned_newactivity = await api_post(ACTIVITY_PATH, newactivity);
    mutate();
    const newSelectedActivityList = [...userdata.activities, returned_newactivity];
    userdata.activities = newSelectedActivityList;
    updateUserData(userdata);
    return returned_newactivity;
  };


  const deleteActivity = async (slug) => {
    await api_delete(ACTIVITY_PATH + slug + "/");
    mutate();
    return;
  };

  return {
    activities: activities ? activities.results : undefined,
    isLoading: !error && !activities,
    isError: error,
    mutate,
    addActivity,
    deleteActivity
  };
};

export default useActivities;
