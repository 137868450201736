import {Container, Row, Col, Card} from "react-bootstrap";
import {useEffect, useRef} from "react";

export const WorkPricing = () => {
  const myRef = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    /* <script async src="https://eomail6.com/form/9f38451e-5f2a-11ee-932d-3d5242983a8b.js" data-form="9f38451e-5f2a-11ee-932d-3d5242983a8b"></script>*/

    script.src =
      "https://eomail6.com/form/9f38451e-5f2a-11ee-932d-3d5242983a8b.js";
    script.async = true;
    const id = "9f38451e-5f2a-11ee-932d-3d5242983a8b";
    script.setAttribute("data-form", id);

    const myRefNode = myRef.current;
    if (myRefNode) myRefNode.appendChild(script);

    return () => {
      const nodes = document.querySelectorAll(`[data-form="${id}"]`);
      nodes.forEach(function (node) {
        node.parentNode.removeChild(node);
      });
    };
  }, [myRef]);

  return (
    <>
      <Container className="pricing-container">
        <Row>
          <Col md={{span: 9, offset: 1}}>
            <h2 className="py-2 pt-3">Get Out Weather For Work</h2>
            <h3>Weather based email marketing.</h3>
            Send emails to your customers when the weather is perfect for your
            business. Run weather-based promotions:
            <Container className="m-3">
              <Row>
                "Sweat more, get more. Special Sale for over 100 degrees!"
              </Row>
              <Row>"The Patio will be open tomorrow!"</Row>
              <Row>
                "Today is Cold and Grey. Come in for 20% off Hot Soup!"{" "}
              </Row>
              <Row>"Low winds and blue skies this weekend at the course!"</Row>
            </Container>
            <p>
              <li>Restaurant</li>
              <li>Mini Golf</li>
              <li>Outdoor Events</li>
            </p>
            <h3>Auomate Weather Decisions</h3>
            <p>
              Do you work outside, or does the weather effect your business?
              Make consistent timely decisions about when to work, and when to
              cancel. Change plans with unexpected weather. Take the guess work
              out of planning around weather.
              <li>Landsape Crews</li>
              <li>House Painters</li>
              <li>Plow Operators</li>
              <li>Wedding Photographers</li>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="pt-3" md={{span: 9, offset: 1}}>
            <Card>
              <Card.Header>
                <Card.Title>Connect with Us</Card.Title>
              </Card.Header>
              <Card.Body>
                Use this form to reach out. This will result a single personal
                email to you. Your email will not be shared or used in any other
                way.
                <div
                  className="d-flex justify-content-center"
                  ref={myRef}></div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
