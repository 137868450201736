import * as d3 from "d3";

import useProcessedWeather from "hooks/useProcessedWeather";

import {
  draw_time_axes_tz,
  draw_yaxes,
  draw_line,
  draw_marker_text,
  draw_horizontal_line,
  draw_multiple_marker_text
} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import {isUndefined} from "lodash";

export const TempRibbon = (props) => {
  const {data} = useProcessedWeather(props.currentPlacedActivity.place);

  if (isUndefined(data?.chartdata) || data.chartdata.length === 0) return;
  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    data?.chartdata
  );

  const draw_temps = (g, data, width, height, tempTypeList, xScale) => {
    //Yscales
    let maxtemp = d3.max(data.chartdata, function (d) {
      return d3.max(tempTypeList.map((name) => d[name]));
    });
    // roundup to closest 10
    maxtemp = Math.ceil(maxtemp / 10) * 10 + 10;

    let mintemp = d3.min(data.chartdata, function (d) {
      return d3.min(tempTypeList.map((name) => d[name]));
    });
    mintemp = Math.floor(mintemp / 10) * 10 - 15;
    const yScale = d3
      .scaleLinear()
      .domain([mintemp, maxtemp])
      .range([height, 0])
      .nice();

    draw_yaxes(g, yScale, width);
    draw_time_axes_tz(
      g,
      xScale,
      height,
      data.nightblocks,
      false,
      data.metadata.timezone
    );

    // freezing line
    //draw_horizontal_line(g, width, yScale, 32, "freezeline");

    tempTypeList.forEach((name) => {
      draw_line(g, data.chartdata, name, xScale, yScale);
      // let lastadded = 0;
      // const extrema = data.chartdata.filter((r, i) => {
      //   if (lastadded + 2 > i || isUndefined(r) || isUndefined(data.chartdata[i - 1]) || isUndefined(data.chartdata[i + 1])) return false;
      //   console.log(r[name], data.chartdata[i - 1][name], data.chartdata[i + 1][name])

      //   if ((r[name] > data.chartdata[i - 1][name] &&
      //     r[name] >= data.chartdata[i + 1][name]) ||
      //     (r[name] < data.chartdata[i - 1][name] &&
      //       r[name] <= data.chartdata[i + 1][name])) {
      //         lastadded = i
      //         return true
      //       }
      // });

      
    } );

    draw_multiple_marker_text(
      g,
      data.chartdata,
      tempTypeList,
      xScale,
      yScale,
      "°",
      3
    );
  };
  const temp_types = data.temp_types.filter((n) => n !== "dewpoint");
  let draw_func = (svg) => {
    draw_temps(
      svg,
      data,
      props.dims.width,
      props.dims.unitHeight,
      temp_types,
      xScale
    );
  };

  return (
    <div className="ribbon">
      <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
