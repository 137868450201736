import * as d3 from "d3";


import useProcessedWeather from "hooks/useProcessedWeather";
import {
  draw_time_axes_tz,
  draw_yaxes,
  draw_line,
  draw_marker_text,
} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import { isUndefined } from "lodash";


export const HumidityRibbon = (props) => {
  const {data} = useProcessedWeather(
    props.currentPlacedActivity.place
  );

  if (isUndefined(data?.chartdata) || data.chartdata.length === 0) return;
  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    data?.chartdata
  );

  const draw_percents = (
    g,
    data,
    width,
    height,
    xScale,
    ymargin = 20
  ) => {

    const yScale = d3.scaleLinear().domain([0, 100]).range([height, 0]).nice();
    draw_yaxes(g, yScale, width, "%", ymargin);
    draw_time_axes_tz(g, xScale, height + 2 * ymargin, data.nightblocks, false, data.metadata.timezone);

    const plotg = g.append("g").attr("transform", `translate(0,${ymargin})`);


    draw_line(plotg, data.chartdata, "humidity", xScale, yScale);
    draw_marker_text(plotg, data.chartdata, "humidity", true, xScale, yScale, "%");
  };
  let draw_func = (svg) => {
    draw_percents(
      svg,
      data,
      props.dims.width,
      props.dims.unitHeight - 40,
      xScale
    );
  };
  return (
    <div className="ribbon">
      <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
