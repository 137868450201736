// Page to enable Adding Ribbons to a View.
// usese useRibbons to retrieve a list of available ribbons
// page slug is passed in as a url arg, and is used as a target to add the selected ribbon
// the page content is just cards in rows where each card contains a description of the ribbon
// and the an "Add" button.
// In the future, we will also have an image preview of the ribbon

import {useEffect} from "react";
import {isEmpty} from "lodash";
import {useNavigate, useParams} from "react-router-dom";
import useRibbons from "hooks/useRibbons";
import {Card, Row, Col, Button, Container} from "react-bootstrap";
import HelpDialog from "components/HelpDialog";
// import usePage from "hooks/usePage";
import useLocationState from "hooks/useLocationState";
import useStripe from "hooks/useStripe";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown} from "@fortawesome/free-solid-svg-icons";

export const RibbonAdder = () => {
  const {isCustomer} = useStripe();
  const {ribbons} = useRibbons();
  const {slug} = useParams();

  // const location = useLocation();
  // const callback = location.state?.callback;
  // const pageData = location.state?.pageData;
  const {pageData, navToCallback, hasCallback, setPlace, getPlace} =
    useLocationState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  // const pageRibbons = page?.memberribbons?.map((member) => member.ribbon.jsclass);
  // const filteredRibbons = ribbons?.filter(
  //   (ribbon) => !pageRibbons.includes(ribbon.jsclass)
  // );

  const handleAdd = (ribbon) => {
    const page_plus_ribbon = {
      ...pageData,
      memberribbons: [{ribbon: ribbon}, ...pageData.memberribbons],
    };
    if (hasCallback()) {
      navToCallback(page_plus_ribbon);
      //navigate(callback, {state: {pageData: page_plus_ribbon}});
    } else {
      navigate(`/page/edit/${slug}`, {state: {pageData: page_plus_ribbon}});
    }
  };

  const handleCancel = () => {
    if (hasCallback()) {
      navToCallback(pageData);
      //navigate(callback, {state: {pageData: pageData}});
    } else {
      navigate(`/page/edit/${slug}`, {state: {pageData: pageData}});
    }
  };

  return (
    <Container>
      <Row className="py-2">
        <Col sm={2}>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
      </Row>

      {isEmpty(ribbons) ? (
        <Row>
          <Card>
            <Card.Body>
              <Card.Title>No Ribbons to Add</Card.Title>
              <Card.Text>
                The Page <b>{pageData?.name} </b> already has all available
                ribbons.
              </Card.Text>
            </Card.Body>
          </Card>
          <Button onClick={() => navigate("/page/edit/" + pageData.slug)}>
            Back To Page Editor
          </Button>
        </Row>
      ) : (
        <>
          <Row className="mt-4">
            <Row>
              <Col>
                <h5>Premium Ribbons</h5>
              </Col>
              {!isCustomer() && (
                <Col className="mb-2">
                  <Button
                    variant="primary"
                    onClick={() => navigate("/pricing")}>
                    <FontAwesomeIcon icon={faCrown} /> Uprade to Premium
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
              {ribbons
                .filter((ribbon) => ribbon.group?.name === "premium")
                .map((ribbon, index) => (
                  <Col
                    className="p-1"
                    key={index}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={4}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xs={2}>
                            <HelpDialog title={ribbon.name}>
                              {ribbon.description}
                            </HelpDialog>
                          </Col>
                          <Col xs={6}>
                            <Card.Title>{ribbon.name}</Card.Title>
                            {/* <Card.Text>{ribbon.description}</Card.Text> */}
                          </Col>
                          <Col xs={4}>
                            <Button
                              variant={isCustomer() ? "primary" : "secondary"}
                              disabled={!isCustomer()}
                              onClick={() => handleAdd(ribbon)}>
                              {isCustomer() ? "Add" : <FontAwesomeIcon icon={faCrown} />}
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
          </Row>
          <Row className="mt-4">
            <h5>Standard Ribbons</h5>
            {ribbons
              .filter((ribbon) => ribbon.group?.name !== "premium")
              .map((ribbon, index) => (
                <Col
                  className="p-1"
                  key={index}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={4}>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col xs={2}>
                          <HelpDialog title={ribbon.name}>
                            {ribbon.description}
                          </HelpDialog>
                        </Col>
                        <Col xs={6}>
                          <Card.Title>{ribbon.name}</Card.Title>
                          {/* <Card.Text>{ribbon.description}</Card.Text> */}
                        </Col>
                        <Col xs={4}>
                          <Button
                            variant="primary"
                            onClick={() => handleAdd(ribbon)}>
                            Add
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        </>
      )}
    </Container>
  );
};
