import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';

const HelpDialog = ({ title = 'Help', buttonText='Got it', showOnLoad=false, children }) => {
  const [show, setShow] = useState(showOnLoad);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <span>
      <Button variant="link" className="align-middle" onClick={handleShow}>
        <FontAwesomeIcon style={{color: "orange"}}  icon={faQuestionCircle} />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
              {buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

export default HelpDialog;
