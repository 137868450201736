/** @file
 *
 * Setup file, to be shared with Cypress, as recommended in
 * https://docs.cypress.io/guides/component-testing/styling-components#Importing-Stylesheets
 */
import React from "react";

import {BrowserRouter as Router} from "react-router-dom";
import {GoogleOAuthProvider} from "@react-oauth/google";

import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/custom.scss";

export const createApp = () => (
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <App />
      </Router>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
