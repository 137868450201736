import isNull from "lodash/isNull";

import {API_HOST, LOGIN_PATH} from "./backend";

/**
 * Gets or Creates user from our backend
 *
 * @returns User object from our database
 */
export const backend_login = async (code, addToMailingList) => {
  const response = await fetch(API_HOST + LOGIN_PATH, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({code: code, addToMailingList: addToMailingList}),
    headers: {"Content-Type": "application/json"},
  });

  if (response.ok) {
    const result = await response.json();
    if (!isNull(result)) {
      localStorage.setItem("user", JSON.stringify(result));
      localStorage.setItem("drfToken", result.drf_token);
    }
    return result;
  } else {
    console.log(response)
    throw new Error(response.error);
  }
};
