import * as d3 from "d3";


import useProcessedWeather from "hooks/useProcessedWeather";
import {draw_time_axes_tz, draw_yaxes, xScaleHourWidth} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import { isUndefined, round } from "lodash";


export const PrecipAmountRibbon = (props) => {
  const {data} = useProcessedWeather(
    props.currentPlacedActivity.place
  );

  if (isUndefined(data?.chartdata) || data.chartdata.length === 0) return;
  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    data?.chartdata
  );

  const draw_qpfs = (
    g,
    data,
    width,
    height,
    xScale,
    ymargin = 20
  ) => {

    const unit = data.metadata.units.qpf == 'inch' ? '"' : data.metadata.units.qpf;

    //Yscales
    let maxqpf = d3.max(data.chartdata, function (d) {
      return d.qpf;
    });
    // roundup to closest 10
    maxqpf = Math.max(maxqpf + 0.2, 0.25);

    let minqpf = d3.min(data.chartdata, function (d) {
      return d.qpf;
    });
    //minqpf = Math.floor(minqpf / 10) * 10 - 5;
    const yScale = d3
      .scaleLinear()
      .domain([minqpf, maxqpf])
      .range([height, 0])
      .nice();
      
    draw_yaxes(g, yScale, width, '"', ymargin, "0.2f");
    draw_time_axes_tz(g, xScale, height + 2 * ymargin, data.nightblocks, false, data.metadata.timezone);
    g.append("g")
      .selectAll("rect")
      .data(data.chartdata.filter((d, i) => d.qpf > 0 && i < data.chartdata.length - 1))
      .join("rect")
      .attr("x", (d) => xScale(d.time))
      .attr("y", (d) => yScale(d.qpf) + ymargin)
      .attr("width", (width / xScaleHourWidth(xScale)) * 0.65)
      .attr("height", (d) => height - yScale(d.qpf))
      .attr("class", "qpf");

    //  draw_line(g, chartdata, "qpf", xScale, yScale);
    //draw_marker_text(g, chartdata.filter((d) => d.qpf > 0), "qpf", false, xScale, yScale, '"');

    g.selectAll("#amarkertextqpf")
      .data(
        data.chartdata
           .filter((d, i) => (i % 2 == 0))
          .filter((d, i) => i > 0 && d.qpf !== data.chartdata[i - 1].qpf)
          .filter((d) => d.qpf > 0)
      )
      .join("text")
      .attr("x", (d) => xScale(d.time))
      .attr("y", (d) => yScale(d["qpf"]) + ymargin - 5)
      .attr("class", "marker-text  qpf")
      .text((d) => d["qpf"] + unit)
      .attr("id", "markertextqpf");


 // sum up total of all snowfall per day
  
 let precipperday = {};
 data.chartdata.forEach((d) => {
   const day = d.datetime.day
   if (precipperday[day] === undefined) {
     precipperday[day] = {'total': d.qpf, 'time': d.time}
   } else {
     precipperday[day] = {...precipperday[day], total: precipperday[day].total + d.qpf}
   }
 });


 precipperday = Object.values(precipperday)
// show totals
g.append("g")
 .selectAll("rect")
 .data(precipperday.filter((d) => d.total > 0))
 .join("rect")
 .attr("x", (d) => xScale(d.time) + (width / xScaleHourWidth(xScale)*0.5 ))
 .attr("y", 10)
 .attr("width", (width / xScaleHourWidth(xScale)*23))
 .attr("height", (d) => 20)
 .attr("class", "qpf-total");

 g.append("g")
 .selectAll("text")
 .data(precipperday.filter((d) => d.total > 0))
 .join("text")
 .attr("x", (d) => xScale(d.time) + width / xScaleHourWidth(xScale)* 12)
 .attr("y", 26)
 .style("text-anchor", "middle")
 .text((d) => round(d.total, 1) + ' ' + unit + " rain equiv.")
 .attr("class", "qpf-total-text");

  };

  let draw_func = (svg) => {
    draw_qpfs(
      svg,
      data,
      props.dims.width,
      props.dims.unitHeight - 40,
      xScale
    );
  };

  return (
    <div className="ribbon">
      <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
