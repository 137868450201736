import isEmpty from "lodash/isEmpty";
import useSWR from "swr";

export const blankUser = () => {
  return {
    userdata: {
      newuser: true,
      places: [],
      activities: [],
      placedactivities: [],
      placedactivitysets: [{name: "Favorites", placedactivities: []}],
      events: [],
    },
  };
};

export const loadUserProfile = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!isEmpty(user)) {
    return user;
  }
  return blankUser();
};

export const useUser = () => {
  const {data: user, mutate} = useSWR("user", loadUserProfile);

  return {
    user,
    mutate,
  };
};
