import {Container, Row, Col, Table, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown} from "@fortawesome/free-solid-svg-icons";

export const About = () => {
 
  return (
    <>
      <Container className="pricing-container">
        <Row>
          <Col lg={{span: 8, offset: 2}}>
            <h2 className="py-2 pt-3">About Get Out Weather</h2>
            <p>
              Get Out Weather aims to be the most complete weather dashboard you
              can get for USA weather. When weather really matters, we aim to
              help you make the best decisions for your outings.{" "}
              <b>
                It is the only app that provides all weather data including tides in
                a single stacked view.
              </b>
            </p>
            <p>
              Get Out is great for sailing, fishing, kite boarding, hiking,
              biking, picnicing, lounging at the beach, and more! Our mission is
              to help you <i>get out</i> when the conditions are right.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={{span: 8, offset: 2}} className="mt-1">
            <Card className="my-2">
              <Card.Header>
                <Card.Title>
                  <b>Features</b>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        Weather data plots, aka <b>Ribbons</b>, that show a week
                        of data from the NWS and other sources.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Pages</b> that pan and zoom with ribbons stacked to
                        show all factors for a week in a single view.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Customizable</b> ribbon selection and layout on each
                        page.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Score Ribbons</b> that summarize conditions and
                        highlight opportunities for your favorite activities,
                        with a single score compiled from many factors.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        (<FontAwesomeIcon icon={faCrown} /> premium only) As
                        many pages as you want to easily check weather at
                        multiple places.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        (<FontAwesomeIcon icon={faCrown} /> premium only)
                        Specialty ribbons including <b>Tides</b>.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col lg={{span: 8, offset: 2}} className="pt-3">
            <Card>
              <Card.Header>
                <Card.Title>Connect with Us</Card.Title>
              </Card.Header>
              <Card.Body>
                Join our mailing list. It's never shared, and it's the primary
                way we update you on new features!
                {message && <p className={success?"text-success":"text-danger"}>{message}</p>}
                {!success && 
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    controlId="formBasicEmail"
                    className="mb-2 d-flex">
                    <div className="w-100">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        className="mr-2"
                      />
                    </div>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form.Group>
                </Form>}

              </Card.Body>
            </Card>
          </Col>
        </Row> */}
        <Row className="my-5">
          <Col lg={{span: 8, offset: 2}}>
            <h5>A Note from Tim</h5>
            {/* <p>
              I was inspired to create this app by fire pits. I love a good fire
              pit, but breezes blowing smoke in faces can ruin the mood! Wind is
              often underplayed in forecasts unless it's super windy. I always
              saught the straight numbers to make my plans. For over a decade, I
              used the National Weather Service 48 hr weather graph. The
              inspiration is obvious. I started by taking that graph concept and
              making it mobile ready. I don't know who wrote 48 hour weather
              graph, but if it was you, thank you so much! I hope you see Get
              Out an as homage to your work.
            </p>
            <p>
              Over time, I started thinking about the challenge of capturing the
              impact of numerous weather indicators at once. For a great trip to
              beach near my house, it needs to be high tides, temps over 75, and
              not too windy. If I could capture that in one number, that unlocks
              some great possibilities. I started thinking about how to do that,
              and came up with the idea of a score ribbon. I also spoke with a
              close friend who explained a similar approach to catching great
              powder days in the mountais. Stay tuned for more or that!
            </p> */}
            <p>
              Thank you for visiting. I know there are ways I can make this a
              better tool for you. I will read all the emails sent to our feedback
              address that you can find on your <a href="/profile">profile</a>{" "}
              page after creating a free account. Please reach out.  I am curious 
              how you think about weather, how you make decisions about going 
              out, and how this tool could help you catch more great weather days.
            </p>
            <p>-Tim</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};
