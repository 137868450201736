import useSWR from "swr";

import {api_get, FACTORSET_PATH} from "api/backend";

const useFactorSet = (slug) => {

  const {
    data: factorSet,
    error
  } = useSWR(`${FACTORSET_PATH}${slug}`, api_get);


  return {
    factorSet: factorSet,
    isLoading: !error && !factorSet,
    isError: error,
  };
};

export default useFactorSet;
