
import useActivities from "hooks/useActivities";
import useUserData from "hooks/useUserData";
import {useUser} from "hooks/useUser";

import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {isUndefined} from "lodash";


export const ScoreRibbonSettings = ({pageData, setPageData, memberribbon, index}) => {
  const {addActivity} = useActivities();
  const navigate = useNavigate();
  const {userdata} = useUserData();
  const {user} = useUser();

  const setGradeRibbonActivity = (activity) => {
    const new_ribbons = [...pageData.memberribbons];
    new_ribbons[index].activity = activity;
    setPageData({...pageData, memberribbons: new_ribbons});
  };

  const onEditScore = () => {
    const activity = memberribbon?.activity;

    if (activity.owner && activity.owner === user?.id) {
      navigate(`/score/edit/${activity.slug}`, {
        state: {
          pageData: pageData,
          callback: window.location.pathname,
          ribbonIndex: index,
        },
      });
    } else {
      const submitActivity = {
        ...activity,
        id: undefined,
        slug: undefined,
        public: true,
      };
      addActivity(submitActivity).then((newactivity) => {
        navigate(`/score/edit/${newactivity.slug}`, {
          state: {
            pageData: pageData,
            callback: window.location.pathname,
            ribbonIndex: index,
          },
        });
      });
    }
  };
  const onAddNewScore = () => {
    addActivity().then((newactivity) => {
      // select this activity in the dropdown
      setGradeRibbonActivity(newactivity);
      navigate(`/score/edit/${newactivity.slug}`, {
        state: {
          pageData: pageData,
          callback: window.location.pathname,
          ribbonIndex: index,
        },
      });
    });
  };

  return (

        <div
          style={{
            display: "flex",
            alignItems: "left",
            flexDirection: "column",
          }}>
          <DropdownButton
            className="mb-1"
            variant="outline-primary"
            title={memberribbon?.activity?.name || "Select Score"}>
            {userdata?.activities.map((activity) => (
              <Dropdown.Item
                key={activity.slug}
                onClick={() => {
                  setGradeRibbonActivity(activity);
                }}>
                {activity.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>

        <Button
        className="mb-1"
          variant="outline-primary"
          disabled={isUndefined(memberribbon?.activity)}
          onClick={() => {
            onEditScore();
          }}>
          Edit {memberribbon?.activity?.name} Score
        </Button>

        <Button
        
          variant="outline-primary"
          onClick={() => {
            onAddNewScore();
          }}>
          Create New Score
        </Button>
      </div>
  );
};
