import {Button, Container, Form, Col, Row, Card} from "react-bootstrap";
import SliderList from "components/SliderList";
import {useState, useEffect} from "react";
import useActivity from "hooks/useActivity";
import useFactorSet from "hooks/useFactorSet";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import ConfirmDialog from "components/ConfirmDialog";
import {isUndefined} from "lodash";

/**
 * Activity Editor Page
 *
 * @returns {JSX.Element}
 */
export const ActivityEditor = () => {
  const {activityslug} = useParams();
  const {activity, updateActivity} = useActivity(activityslug);
  const [data, setData] = useState(activity);
  const [unsaved, setUnsaved] = useState(false);
  const {factorSet} = useFactorSet("default");
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    setData(activity);
  }, [activity]);

  const handleRevert = () => {
    setData(activity);
    setUnsaved(false);
  };

  const handleSave = () => {
    const callback = location.state?.callback;
    const pageData = location.state?.pageData;
    const ribbonIndex = location.state?.ribbonIndex;
    
    const activity_with_zero_weights_removed = {
      ...data,
      requirements: {
        ...data.requirements,
        ranges: data.requirements.ranges
          ? data.requirements.ranges.filter((r) => r.weight > 0)
          : [],
      },
    };

    updateActivity(activity_with_zero_weights_removed).then((freshActivity) => {
      setUnsaved(false);
      const updated_memberribbons = pageData?.memberribbons;
      if (!isUndefined(ribbonIndex) && pageData) {
        updated_memberribbons[ribbonIndex].activity = freshActivity;
      }      
      if (callback) {
        navigate(callback, {state: {pageData: {...pageData, memberribbons: updated_memberribbons}}});
      }
    });
  };

  const handleBack = () => {
    const callback = location.state?.callback;
    const pageData = location.state?.pageData;
    // const ribbonIndex = location.state?.ribbonIndex;
    if (!unsaved) {
      navigate(callback, {state: pageData});
    } else {
      setShowConfirmBack(true);
    }
  };

  const cancelBack = () => {
    setShowConfirmBack(false);
  };
  const handleTitleChange = (event) => {
    setUnsaved(true);
    setData({...data, name: event.target.value});
  };

  const handleToggleDay = (event) => {
    setUnsaved(true);
    setData({
      ...data,
      requirements: {
        ...data.requirements,
        times: {...data.requirements.times, day: event.target.checked},
      },
    });
  };

  const handleToggleNight = (event) => {
    setUnsaved(true);
    setData({
      ...data,
      requirements: {
        ...data.requirements,
        times: {...data.requirements.times, night: event.target.checked},
      },
    });
  };

  const handleSliderChange = (ranges) => {
    setUnsaved(true);
    const newRequirements = {...data.requirements, ranges: ranges};
    setData({...data, requirements: newRequirements});
  };

  return (
    <div className="container">
      <ConfirmDialog
        show={showConfirmBack}
        onCancel={cancelBack}
        onConfirm={() => navigate("/profile")}
        message={
          "You have unsaved changes.  Do you want to leave and lose them?"
        }
      />
      {data && (
        <Container>
          {/* Add a Text input field for users to provide the name of the activity.  add a name of the fielf and instruction*/}
          <Card className="editor-card mt-2">
            <Row>
              <Col>
                <Button
                  variant="outline-secondary"
                  className="float-start m-1"
                  onClick={handleBack}>
                  Back
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={!unsaved}
                  variant={unsaved ? "warning" : "secondary"}
                  className="float-start m-1"
                  onClick={handleRevert}>
                  Revert
                </Button>
              </Col>
              <Col>
                <Button className="float-end m-1" onClick={handleSave}>
                  Save
                </Button>
              </Col>
            </Row>
          </Card>
          <Card className="editor-card mt-1">
            <Card.Body>
              <Form.Group>
                <Row>
                  <Col sm={4}>
                    <Form.Label>Activity Name</Form.Label>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      value={data.name || ""}
                      onChange={handleTitleChange}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={6}>
                    <Form.Check
                      key="day"
                      type="switch"
                      id="custom-switch"
                      label="Day Time"
                      checked={data?.requirements?.times?.day || false}
                      onChange={handleToggleDay}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Check
                      key="night"
                      type="switch"
                      id="custom-switch"
                      label="Night Time"
                      checked={data?.requirements?.times?.night || false}
                      onChange={handleToggleNight}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Card.Body>
          </Card>
          <SliderList
            factors={factorSet?.factors}
            allRanges={data.requirements.ranges}
            onChange={(ranges) => handleSliderChange(ranges)}
          />
        </Container>
      )}
      <p></p>
    </div>
  );
};
