import {Container, Row, Col, Button, Form} from "react-bootstrap";
import useLogin from "hooks/useLogin";
import {useState} from "react";
import Google_G from "img/Google_G.svg"

export const Register = () => {
  const {login, addToMailingList, setAddToMailingList} = useLogin("/", "/", true);
  const [isRegistering, setisRegistering] = useState(false);
  const handleLogin = () => {
    setisRegistering(true);
    login();
  }
  const handleCheckboxChange = (event) => {
    setAddToMailingList(event.target.checked);
  }
  //   useEffect(() => {
  //     const script = document.createElement("script");
  // {/* <script async src="https://eomail6.com/form/7f57e2de-6260-11ee-a909-7d35ee84337b.js" data-form="7f57e2de-6260-11ee-a909-7d35ee84337b"></script> */}
  //     script.src =
  //       "https://eomail6.com/form/7f57e2de-6260-11ee-a909-7d35ee84337b.js";
  //     script.async = true;
  //     const id = "7f57e2de-6260-11ee-a909-7d35ee84337b";
  //     script.setAttribute("data-form", id);

  //     const myRefNode = myRef.current;
  //     myRefNode.appendChild(script);

  //     return () => {
  //       const nodes = document.querySelectorAll(`[data-form="${id}"]`);
  //       nodes.forEach(function (node) {
  //         node.parentNode.removeChild(node);
  //       });
  //     };
  //   }, []);

  return (
    <>
      <Container className="pricing-container">
        <Row>
          <Col md={{span: 9, offset: 1}}>
            <h2 className="py-2 pt-3">Sign up</h2>
            <p>
              Creating an account is free and allows you to create one default
              page. You can choose to have this page show the weather at your
              current location or at a fixed place.
            </p>
            <p>
              If you want to get low traffic (~ 1 per month) product updates and announcements in your inbox, select to add
              your email to our mailing list.  You can unsubscribe at any time.
            </p>
            <Col xs={12} className="d-flex justify-content-center my-3">
              <Form.Check
                type="switch"
                label="Add me to the mailing list"
                checked={addToMailingList}
                onChange={handleCheckboxChange}
              />
            </Col>
            <Col xs={12} className="d-flex justify-content-center">
              <Button variant="outline-dark" onClick={handleLogin} disabled={isRegistering}>
                <img
                  width="20px"
                  style={{marginBottom: "3px", marginRight: "5px"}}
                  alt="Google sign-in"
                  src={Google_G}
                />
                {isRegistering ? "Registering..." : "Register with Google"}
              </Button>
            </Col>
          </Col>
        </Row>
        {/* <Row>
        <Col className="pt-3" md={{span: 9, offset: 1}}>
        <Card>
              <Card.Header>
                <Card.Title>Connect with Us</Card.Title>
              </Card.Header>
              <Card.Body>
                Join our mailing list.  This is not shared, and is the primary way we update you on new features.  
                <div className="d-flex justify-content-center" ref={myRef}></div>
              </Card.Body>
            </Card>
            </Col>
            </Row> */}
      </Container>
    </>
  );
};
