import * as d3 from "d3";


import useProcessedWeather from "hooks/useProcessedWeather";
import {draw_time_axes_tz, xScaleHourWidth} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import {isUndefined} from "lodash";
import {WEATHER_TO_NUM} from "util/constants";
import variables from "styles/custom.scss";

export const WeatherTypeRibbon = (props) => {
    const {data} = useProcessedWeather(
    props.currentPlacedActivity.place
  );

  if (isUndefined(data?.chartdata) || data.chartdata.length === 0) return;
  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    data?.chartdata
  );

  const draw_precip_types = (g, data, width, height, xScale, ymargin = 20) => {


    // Add Y axis
    var s = d3.scaleLinear().domain([0, 4]).range([height, 0]);
    var yScale = d3
      .scaleLinear()
      //.domain(["", "SChc", "Chc", "Lkly", "Ocnl"])
      .domain([0,4])
      .range([height, 0])
      .unknown(3);

    // gridlines in y axis function
    function make_y_gridlines() {
      return d3.axisLeft(yScale).ticks(8);
    }

    g.select("#ygrid").remove();
    g.append("g")
      .attr("class", "grid")
      .attr("id", "ygrid")
      .attr("transform", `translate(0,${ymargin})`)
      .call(make_y_gridlines().tickSize(-width).tickFormat(""));

      draw_time_axes_tz(
        g,
        xScale,
        height + 2 * ymargin,
        data.nightblocks,
        false,
        data.metadata?.timezone
      );
    // Another scale for subgroup position?
    // var xSubgroup = d3
    //   .scaleBand()
    //   .domain(data.precip_types)
    //   .range([0, width / xScaleHourWidth(xScale)])
    //   .padding([0.05]);

    // color palette = one color per subgroup

    const weatherColors = {
      blowing_dust: variables.blowing_dust,
      blowing_sand: variables.blowing_sand,
      blowing_snow: variables.blowing_snow,
      drizzle: variables.drizzle,
      fog: variables.fog,
      freezing_fog: variables.freezing_fog,
      freezing_drizzle: variables.freezing_drizzle,
      freezing_rain: variables.freezing_rain,
      freezing_spray: variables.freezing_spray,
      frost: variables.frost,
      hail: variables.hail,
      haze: variables.haze,
      ice_crystals: variables.ice_crystals,
      ice_fog: variables.ice_fog,
      rain: variables.rain,
      rain_showers: variables.rain_showers,
      sleet: variables.sleet,
      smoke: variables.smoke,
      snow: variables.snow,
      snow_showers: variables.snow_showers,
      thunderstorms: variables.thunderstorms,
      volcanic_ash: variables.volcanic_ash,
      water_spouts: variables.water_spouts,
    };

    var color = d3
      .scaleOrdinal()
      .domain(Object.keys(weatherColors))
      .range(Object.values(weatherColors));
    // .domain(["rain", "thunderstorms", "snow", "freezingrain", "sleet"])
    // .range(["#666666", "#ffd500", "#4397c7", "#c49bc9", "#df6e2b"]);

    // let bars = [];
    // data.chartdata
    //   .filter((n) => n.weathercode !== undefined)
    //   .slice(0, -1)
    //   .forEach((n) => {
    //     n.weathercode.forEach((d) => {
    //       bars.push({time: n.time, ...d});
    //     });
    //   });

    g.append("g")
      .selectAll("rect")
      .data(data.chartdata.filter((n) => n.weathercode !== undefined))
      .join("rect")
      .attr("x", (d) => xScale(d.time))
      .attr("y", (d) => yScale(d.weathercode.level) + ymargin)
      .attr("width", (width / xScaleHourWidth(xScale)) * 0.65)
      .attr("height", (d) => height - yScale(d.weathercode.level))
      .attr("fill", (d) => color(d.weathercode.base_name));
  };

  let draw_func = (svg) => {
    draw_precip_types(
      svg,
      data,
      props.dims.width,
      props.dims.unitHeight - 40,
      xScale
    );
  };

  return (
    <div className="ribbon">
      <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
