import {useState, useEffect} from "react";
import {Button, Row, Col, Card, Container, Modal} from "react-bootstrap";
import {DragDropContext, Droppable, Draggable} from "@hello-pangea/dnd";
import {faCrown, faPencilAlt, faPlusCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HelpDialog from "components/HelpDialog";
import {useNavigate} from "react-router-dom";
import {isUndefined} from "lodash";
import useUserData from "hooks/useUserData";
import useStripe from "hooks/useStripe";

/**
    All Pages
    A page that shows all pages to allow the user to select a page to edit, delete a page,
    change the order of pages, create a new page, and set the default page (the top page in the list).
    @returns {JSX.Element}
*/

export const AllPages = () => {
  const {userdata, updateUserData} = useUserData();

  const [data, setData] = useState(userdata?.pages);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const {isCustomer} = useStripe();
  const navigate = useNavigate();

  useEffect(() => {
    setData(userdata?.pages);
  }, [userdata?.pages]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // setData(items);
    updateUserData({...userdata, pages: items});
  };

  const confirmDelete = (index) => {
    // set the state to show the modal dialog
    setDeleteIndex(index);
    setShowDeleteModal(true);
  };

  const handleDelete = (index) => {
    const new_page_list = data.filter((_, i) => i !== index);
    setData(new_page_list);
    setShowDeleteModal(false);
    updateUserData({...userdata, pages: new_page_list});
  };

  const handleEdit = (index) => {
    navigate("/page/edit/" + data[index].slug);
  };

  // const handleCardChange = (index, key, value) => {
  //   const new_ribbons = [...data.memberribbons];
  //   new_ribbons[index][key] = value;
  //   setData({...data, memberribbons: new_ribbons});
  // };

  //   const handleSave = () => {
  //     updateUserData({...userdata, pages: data}).then(() => {
  //       navigate("/");
  //     });
  //   };

  //   const handleCancel = () => {
  //     navigate("/");
  //   };

  if (isUndefined(data)) {
    return <p>Loading Editor</p>;
  }

  const getItemStyle = (isDragging) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "lightyellow" : "white",
    boxShadow: isDragging ? "-5px 5px 4px rgba(0, 0, 0, 0.3)" : "",
    marginLeft: isDragging ? "6px" : "0px",
  });

  return (
    <>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete <b>{data[deleteIndex]?.name}</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => handleDelete(deleteIndex)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Container className="page-editor">
        {/* <Row className="my-2">
          <Col>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          </Col>
        </Row> */}

        <Row className="mt-2">
          <h2>All Pages</h2>
          <Col style={{margin: "10px"}}>
            Click or tap and hold to pickup and move pages and change order.
            Click X to remove the page.
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <Button variant="primary" onClick={() => navigate("/page/new")}>
              <FontAwesomeIcon icon={faPlusCircle} className="me-2"/>Add New Page
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="pages">
                {(provided) => (
                  <div
                    className="pages"
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {data?.map((page, index) => (
                      <Draggable
                        key={index}
                        draggableId={page.slug + index}
                        index={index}>
                        {(provided, snapshot) => (
                          <div
                            className="page"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <Card
                              style={getItemStyle(snapshot.isDragging)}
                              className="mb-2">
                              <Card.Body>
                                <Row>
                                  <Col>
                                    <Card.Title>
                                    <HelpDialog
                                      title={page.name}
                                      buttonText="Got it">
                                      {page.description}
                                    </HelpDialog>
                                      <a href={`/page/view/${page.slug}`}>
                                        {page.name}
                                      </a>
                                    </Card.Title>
                                  </Col>
                                  <Col>
                                    <Button
                                      variant="danger"
                                      className="float-end m-1"
                                      onClick={() => confirmDelete(index)}>
                                      <FontAwesomeIcon icon={faTimes} />
                                    </Button>

                                    <Button
                                      variant="info"
                                      className="float-end m-1"
                                      onClick={() => handleEdit(index)}>
                                      <FontAwesomeIcon icon={faPencilAlt} />
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Col>
        </Row>
        {!isCustomer() && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  {" "}
                  <Card.Title>Want More Pages?</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Subcribe to <FontAwesomeIcon icon={faCrown} className="me-2"/>
                    Premium and get unlimited pages.
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={() => navigate("/pricing")}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default AllPages;

/**
 * Ribbon Editor
 * @param ribbon
 * @param handleCardChange
 * @returns {JSX.Element}
 */
