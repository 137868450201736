import {api_get, CHECKOUT_PATH, BILLING_PATH} from "api/backend";
import useUserData from "./useUserData";
import useLogin from "./useLogin";
// simple page that doesn't load anything and just redirects to checkout page

const useStripe = () => {
  const {isLoggedIn, login} = useLogin();
  const {userdata} = useUserData();

  const isCustomer = () => {
    return userdata?.usergroups && userdata?.usergroups.includes("premium");
  };

  const billingRedirect = () => {
    api_get(BILLING_PATH).then((data) => {
      window.location.href = data.management_url;
    });
  };

  const checkoutRedirect = () => {
    if (!isLoggedIn()) {
      login();
    } else {
      if (!isCustomer()) {
        api_get(CHECKOUT_PATH).then((data) => {
          window.location.href = data.checkout_url;
        });
      }
    }
  };

  return {checkoutRedirect, billingRedirect, isCustomer};
};

export default useStripe;
