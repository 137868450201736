import * as d3 from "d3";
import {DateTime} from "luxon";
import {isUndefined} from "lodash";

export const draw_yaxes = (
  g,
  yScale,
  width,
  units = "°",
  ymargin = 0,
  formatchar = "d",
) => {
  // gridlines in y axis function
  function make_y_gridlines() {
    return d3.axisLeft(yScale).ticks(8);
  }

  // left side y axes
  const yAxis = d3
    .axisLeft(yScale)
    .ticks(5)
    .tickSize(0)
    .tickFormat((t) => {
      if (t < 1000) {
        return d3.format(formatchar)(t) + units;
      } else {
        return d3.format(formatchar)(t / 1000) + "k" + units;
      }
    });
  // right side y axis
  const yAxis2 = d3
    .axisRight(yScale)
    .ticks(5)
    .tickSize(0)
    .tickFormat((t) => {
      if (t < 1000) {
        return d3.format(formatchar)(t) + units;
      } else {
        return d3.format(formatchar)(t / 1000) + "k" + units;
      }
    });

  g.select("#yaxis").remove();
  g.append("g")
    .attr("id", "yaxis")
    .attr("transform", `translate(0,${ymargin})`)
    .call(yAxis);

  g.select("#yaxis2").remove();
  g.append("g")
    .attr("transform", `translate(${width},${ymargin})`)
    .attr("id", "yaxis")
    .call(yAxis2);

  g.select("#ygrid").remove();
  g.append("g")
    .attr("class", "grid")
    .attr("id", "ygrid")
    .attr("transform", `translate(0,${ymargin})`)
    .call(make_y_gridlines().tickSize(-width).tickFormat(""));
};

export const draw_line = (svg, data, attr_name, xScale, yScale) => {
  const isGood = (v) => v !== null && isFinite(v);
  const line = d3
    .line()
    .x((d) => xScale(d.time))
    .y((d) => yScale(d[attr_name]))
    .defined((d) => isGood(d[attr_name]))
    .curve(d3.curveCardinal.tension(0.7));

  svg.select("#" + attr_name).remove();
  svg
    .append("path")
    .attr("d", (value) => line(data))
    .attr("id", attr_name)
    .attr("class", "line " + attr_name);

  svg
    .selectAll("#marker" + attr_name)
    .data(data.filter((d) => isGood(d[attr_name])))
    .join("circle")
    .attr("class", "marker " + attr_name)
    .attr("cx", (d) => xScale(d.time))
    .attr("cy", (d) => yScale(d[attr_name]))
    .attr("r", 1.5)
    .attr("id", "marker" + attr_name);
};

export const draw_horizontal_line = (
  svg,
  width,
  height,
  yScale,
  value,
  className,
) => {
  if (isUndefined(value)) return;
  // Use class instead of id to avoid duplicates, this assumes class names are unique
  const line = svg.selectAll("line." + className).data([value,]); // Bind the provided value as data for the line

  line
    .enter()
    .append("line") // Enter selection: Create new line elements
    .merge(line) // Merge enter and update selections
    .attr("x1", 0) // Set the start of the line on the x-axis
    .attr("x2", width) // Set the end of the line on the x-axis
    .attr("y1", (d) => yScale(d)) // Set the start of the line on the y-axis
    .attr("y2", (d) => yScale(d)) // Set the end of the line on the y-axis
    .attr("class", className); // Apply the class name
  line.exit().remove(); // Exit selection: Remove lines that are no longer needed

  // write text above the line
};

export const draw_marker_text = (
  svg,
  data,
  attr_name,
  above,
  xScale,
  yScale,
  units = "",
  every = 3,
) => {
  const isGood = (v) => v !== null && isFinite(v);
  const shift = above ? -10 : +16;

  svg
    .selectAll("#amarkertext" + attr_name)
    .data(
      data
        .filter((d, i) => (i + 1) % every === 0 && i !== data.length - 1)
        .filter((d) => isGood(d[attr_name])),
    )
    .join("text")
    .attr("x", (d) => xScale(d.time) - 6)
    .attr("y", (d) => yScale(d[attr_name]) + shift)
    .attr("class", "marker-text " + attr_name)
    .text((d) => d[attr_name] + units)
    .attr("id", "markertext" + attr_name);
};

export const draw_multiple_marker_text = (
  svg,
  data,
  attr_names,
  xScale,
  yScale,
  units = "",
  every = 3,
) => {
  const isGood = (v) => v !== null && isFinite(v);

  if (attr_names) {
    const hilo = data.map((d) => {
      // determine the attr_name with the highest value
      let max = -Infinity;
      let max_attr_name = null;
      let output = {};
      attr_names.forEach((attr_name) => {
        if (d[attr_name] > max) {
          max = d[attr_name];
          max_attr_name = attr_name;
        }

        // make outputs as an object with 16 as the value for every attr_name
        output[attr_name] = 16;
        // make the max_attr_name as -10
      });
      output[max_attr_name] = -10;
      return output;
    });

    attr_names.forEach((attr_name) => {
      svg
        .selectAll("#amarkertext" + attr_name)
        .data(
          data
            .filter((d, i) => (i + 1) % every === 0 && i !== data.length - 1)
            .filter((d) => isGood(d[attr_name])),
        )
        .join("text")
        .attr("x", (d) => xScale(d.time) - 6)
        .attr("y", (d, i) => yScale(d[attr_name]) + hilo[i][attr_name])
        .attr("class", "marker-text " + attr_name)
        .text((d) => d[attr_name] + units)
        .attr("id", "markertext" + attr_name);
    });
  }
};

export const draw_legend = (zone, offset, label, attr_name) => {
  const bglayer = zone.append("g");
  const textlayer = zone
    .append("g")
    .attr("transform", "translate(" + (offset + 4) + ",5.5)")
    .append("text") // add element to class
    .attr("class", "legend-text")
    .text(label);
  const bbox = textlayer.node().getBBox();
  textlayer.attr("y", bbox.height - 4);

  bglayer
    .attr("transform", "translate(" + offset + ",4)")
    .append("rect")
    .attr("width", bbox.width + 8)
    .attr("height", bbox.height + 1)
    .attr("class", "legend " + attr_name)
    .attr("id", "legend");
  return [offset + bbox.width + 8, bbox.height + 5];
};

const make_x_gridlines = (xScale) => {
  let tickValueshourly = [];
  const maxMillis = xScale.domain()[1];
  const minMillis = xScale.domain()[0];
  const tickInterval1hr = 1000 * 60 * 60;
  for (let i = minMillis; i <= maxMillis; i += tickInterval1hr) {
    tickValueshourly.push(i);
  }
  return d3.axisBottom(xScale).tickValues(tickValueshourly);
};

// export const draw_time_axes = (
//   g,
//   xScale,
//   height,
//   nightblocks,
//   hide_hours = false
// ) => {
//   g.selectAll("#night")
//     .data(nightblocks)
//     .join("rect")
//     .attr("x", (b) => xScale(b[0]))
//     .attr("width", (b) => xScale(b[1]) - xScale(b[0]))
//     .attr("height", height)
//     .attr("class", "night")
//     .attr("id", "night");

//   let tickValues3h = [];
//   const tickInterval3h = 1000 * 60 * 60 * 3;
//   const maxMillis = xScale.domain()[1];
//   const minMillis = xScale.domain()[0];
//   for (let i = minMillis; i <= maxMillis; i += tickInterval3h) {
//     tickValues3h.push(i);
//   }

//   let tickValuesdaily = [];
//   const tickIntervalDay = 1000 * 60 * 60 * 24;
//   for (let i = minMillis; i <= maxMillis; i += tickIntervalDay) {
//     tickValuesdaily.push(i);
//   }

//   const hoursAxis = d3
//     .axisTop(xScale)
//     .tickFormat((d, i) => {
//       const t = DateTime.fromMillis(d);
//       return t.toFormat(hide_hours ? "" : "ha");
//     })
//     .tickValues(tickValues3h)
//     //.ticks(d3.timeHour.every(3), hide_hours ? "" : "%I%p")
//     .tickSize(0);

//   g.select("#hoursAxis").remove();
//   g.append("g")
//     .attr("transform", `translate(0,${height})`)
//     .attr("id", "hoursAxis")
//     .call(hoursAxis)
//     .selectAll("text")
//     .attr("transform", `translate(0,0)`)
//     .attr("class", "timeaxis");

//   if (hide_hours) {
//     const dayAxis = d3
//       .axisBottom(xScale)
//       //.ticks(d3.timeDay, "%a")
//       .tickValues(tickValuesdaily)
//       .tickFormat((d) => DateTime.fromMillis(d).toFormat("ccc"))
//       .tickSize(0);

//     g.select("#dayAxis").remove();
//     g.append("g")
//       .attr("id", "dayAxis")
//       .call(dayAxis)
//       .selectAll("text")
//       .attr("transform", `translate(22, ${height - 13})`)
//       .style("text-anchor", "middle")
//       .attr("class", "mini-plot-day-text");

//     g.selectAll("#dayAxis .tick").select("line").style("stroke", "#bbbbbb");
//   } else {
//     g.select("#xgrid").remove();
//     g.append("g")
//       .attr("class", "grid")
//       .attr("transform", "translate(0," + height + ")")
//       .attr("id", "xgrid")
//       .call(make_x_gridlines(xScale).tickSize(-height).tickFormat(""));

//     g.select("#outline").remove();
//     g.append("rect")
//       .attr("width", xScale.range()[1] - xScale.range()[0])
//       .attr("height", height)
//       .attr("class", "outline")
//       .attr("id", "outline")
//       .call(make_x_gridlines(xScale).tickSize(-height).tickFormat(""));

//     //   const dayAxis = d3.axisBottom(xScale).ticks(d3.timeDay, "%a").tickSize(10);;

//     //   g.select("#dayAxis").remove();
//     //   g.append("g")
//     //     .attr("id", "dayAxis")
//     //     .call(dayAxis)
//     //     .selectAll("text")
//     //     .attr("transform", `translate(5,124)`)
//     //     .style("text-anchor", "start")
//     //     .attr("class", "extra-day-label")

//     //  g.append("g")
//     //     .attr("id", "dayAxis")
//     //     .call(dayAxis)
//     //     .selectAll("text")
//     //     .attr("transform", `translate(475,124)`)
//     //     .style("text-anchor", "end")
//     //     .attr("class", "extra-day-label")

//     //   g.select("#dayAxis")
//     //     .selectAll(".tick line")
//     //     .attr("transform", "translate(0,140)");
//   }
// };

export const draw_time_axes_tz = (
  g,
  xScale,
  height,
  nightblocks,
  hide_hours = false,
  timezone,
  shownow = true,
) => {
  g.selectAll(".night") // use class instead of id for selection
    .data(nightblocks, (d) => d[0]) // use the first element of each block as key for data binding
    .join("rect")
    .attr("x", (b) => xScale(b[0]))
    .attr("width", (b) => xScale(b[1]) - xScale(b[0])) // removed the redundant return statement
    .attr("height", height)
    .attr("class", "night"); // removed the id attribute, class is enough for CSS styling and JavaScript selection

  let tickValues3h = [];
  const tickInterval3h = 1000 * 60 * 60 * 3;
  const maxMillis = xScale.domain()[1];
  let minMillis = xScale.domain()[0];
  // shift to be on the threes
  minMillis =
    minMillis +
    (3 - (DateTime.fromMillis(minMillis, {zone: timezone}).hour % 3)) *
      60 *
      60 *
      1000;
  for (let i = minMillis; i <= maxMillis; i += tickInterval3h) {
    tickValues3h.push(i);
  }

  let tickValuesdaily = [];
  const tickInterval1hr = 1000 * 60 * 60;
  let i = minMillis;
  while (i <= maxMillis) {
    const t = DateTime.fromMillis(i, {zone: timezone});
    if (
      t.hour === 0 &&
      t.minute === 0 &&
      t.second === 0 &&
      t.millisecond === 0
    ) {
      tickValuesdaily.push(i);
    }
    i += tickInterval1hr;
  }

  if (hide_hours) {
    const dayAxis = d3
      .axisBottom(xScale)
      //.ticks(d3.timeDay, "%a")
      .tickValues(tickValuesdaily)
      .tickFormat((d) => {
        const t = DateTime.fromMillis(d).setZone(timezone);
        return t.toFormat("ccc");
      })
      .tickSize(0);

    // g.select("#dayAxis").remove();
    // g.append("g")
    //   .attr("id", "dayAxis")
    //   .call(dayAxis)
    //   .selectAll("text")
    //   .attr("transform", `translate(22, ${height - 16})`)
    //   .style("text-anchor", "middle")
    //   .attr("class", "mini-plot-day-text");

    g.selectAll("#dayAxis .tick").select("line").style("stroke", "#bbbbbb");
  } else {
    g.select("#xgrid").remove();
    g.append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0," + height + ")")
      .attr("id", "xgrid")
      .call(make_x_gridlines(xScale).tickSize(-height).tickFormat(""));

    g.select("#outline").remove();
    g.append("rect")
      .attr("width", xScale.range()[1] - xScale.range()[0])
      .attr("height", height)
      .attr("class", "outline")
      .attr("id", "outline")
      .call(make_x_gridlines(xScale).tickSize(-height).tickFormat(""));

    // const dayAxis = d3.axisBottom(xScale).ticks(d3.timeDay, "%a").tickSize(10);;
    const dayAxis = d3
      .axisBottom(xScale)
      .tickValues(tickValuesdaily)
      .tickFormat("")
      .tickSize(height - 20); // Set tick size to negative height

    g.select("#dayAxis").remove();
    g.append("g")
      .attr("id", "dayAxis")
      .call(dayAxis)
      .selectAll(".tick line")
      .attr("class", "midnight-line");

    //  g.append("g")
    //     .attr("id", "dayAxis")
    //     .call(dayAxis)
    //     .selectAll("text")
    //     .attr("transform", `translate(475,124)`)
    //     .style("text-anchor", "end")
    //     .attr("class", "extra-day-label")
  }
  
  const hoursAxis = d3
    .axisTop(xScale)
    .tickFormat((d, i) => {
      const t = DateTime.fromMillis(d, {zone: timezone});
      return t.toFormat(hide_hours ? "" : "ha");
    })
    .tickValues(tickValues3h)
    //.ticks(d3.timeHour.every(3), hide_hours ? "" : "%I%p")
    .tickSize(0);

  g.select("#hoursAxis").remove();
  g.append("g")
    .attr("transform", `translate(0,${height})`)
    .attr("id", "hoursAxis")
    .call(hoursAxis)
    .selectAll("text")
    .attr("transform", `translate(0,0)`)
    .attr("class", "timeaxis");

  

  if (shownow) {
    g.select("#nowline").remove();
    g.append("rect")
      .attr("width", 2)
      .attr("height", height)
      .attr("class", "nowline")
      .attr("id", "nowline")
      .attr("x", xScale(DateTime.utc().toMillis()));
  }
};

export function xScaleHourWidth(xScale) {
  const dt2 = xScale.domain()[1];
  const dt1 = xScale.domain()[0];
  //var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  let diff = (dt2 - dt1) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
}

export const make_xscale = (width, pixelPerHour, chartdata) => {
  // const startTime = 0;
  // const indexWidth = width / pixelPerHour;
  // const maxVal = Math.min(chartdata.length - 1, startTime + indexWidth);
  // const minVal = Math.min(maxVal - indexWidth, Math.max(startTime, 0));
  // const minIndex = Math.floor(minVal);
  // const maxIndex = Math.floor(maxVal);
  // const minFraction = Math.round((minVal - minIndex) * 60 * 60 * 1000);
  // const maxFraction = Math.round((maxVal - maxIndex) * 60 * 60 * 1000);
  const minTime = DateTime.fromMillis(
    chartdata[0].time.getTime(), //+ minFraction
  );
  const maxTime = DateTime.fromMillis(
    chartdata[chartdata.length - 1].time.getTime(), //+ maxFraction
  );
  const scale = d3.scaleLinear().domain([minTime, maxTime]).range([0, width]);
  return scale;
};
