import {Table, Button, Container, Row, Col, Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRight,
  faCircleCheck,
  faInfinity,
  faCrown,
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import useUserData from "hooks/useUserData";
import useStripe from "hooks/useStripe";
import useLogin from "hooks/useLogin";

export const PricingTable = () => {
  //render the pricing table
  const {isLoggedIn} = useUserData();
  const {checkoutRedirect, isCustomer} = useStripe();
  const {login} = useLogin();

  return (
    <Container className="pricing-container">
       <Row>
        <Col className="my-3" sm={12} lg={{span: 8, offset: 2}}>
        <h3>Plan For Work</h3>
        <p>
          <FontAwesomeIcon icon={faArrowRight}/> Do you plan around weather <Link to="/forwork"> for work</Link>?
          </p>
          </Col>
      </Row>
      <Row>
        <Col sm={12} lg={{span: 8, offset: 2}}>
          <h3>Plan For Fun</h3>
          <li>100% Ad free</li>
          <li>Exceptional outdoor planning tool with all key factors in on place.</li>
          <li>The most comprehensive weather dashboard on the web.<superscript>*</superscript></li>
          <li>$2/month or $12/year.</li>
          <li><b>Cancel easily any time</b> from the Stripe Billing Portal on your{" "}
            <Link to="/profile">profile</Link> page.
          </li>

          <Table responsive bordered className="pricing-table mt-3">
            <thead style={{bgcolor: "#F5F5DC"}}>
              <tr>
                <th>Feature</th>
                <th>
                  <FontAwesomeIcon
                    className="me-2"
                    icon={faCrown}
                    style={{color: "orange"}}
                  />
                  Premium
                </th>
                <th>Basic</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Your Status</td>
                <td>
                  {isCustomer() ? (
                    <Badge bg="success">Current Plan</Badge>
                  ) : (
                    <Button
                      onClick={() => {
                        checkoutRedirect();
                      }}>
                      Subscribe
                    </Button>
                  )}
                </td>
                <td>
                  {!isLoggedIn() ? (
                    <Button
                      onClick={() => {
                        login();
                      }}
                      variant="outline-dark">
                      Register
                    </Button>
                  ) : isCustomer() ? (
                    ""
                  ) : (
                    <Badge bg="dark">Current Plan</Badge>
                  )}
                </td>
              </tr>
              <tr className="hover">
                <td>Price</td>
                <td>$2/month or $12/year</td>
                <td>Free</td>
              </tr>
              <tr className="hover">
                <td>Pages</td>
                <td>
                  <FontAwesomeIcon icon={faInfinity} />
                </td>
                <td>1</td>
              </tr>
              <tr className="hover">
                <td>7 Day Hourly Forecast</td>
                <td>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </td>
              </tr>
              <tr className="hover">
                <td>Basic Weather Ribbons</td>
                <td>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </td>
              </tr>
              <tr className="hover">
                <td>
                  Premium Ribbons (
                  <span style={{color: "orange"}}>
                    <b>Tides</b>, <b>Recent Snow</b>
                  </span>
                  )
                </td>
                <td>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCircle} />
                </td>
              </tr>

              <tr className="hover">
                <td>Custom Activity Scores</td>
                <td>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCircle} />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
        * At least <i>we</i> think so, but we think you will agree!  
        </Col>
      </Row>
     
    </Container>
  );
};
