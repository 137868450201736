import useDataSources from "hooks/useDataSources";

import { Col, Dropdown, DropdownButton, Row} from "react-bootstrap";
import { isEmpty} from "lodash";

export const TideRibbonSettings = ({
  pageData,
  setPageData,
  place,
}) => {

  const {
    datasources,
    isLoading: isLoadingDatasources,
  } = useDataSources(place, "tide");

  const addSourceToPagePlace = (source) => {
    // attachDataSource(place.slug, source.id).then((newPlace) => {
    //   setPageData({...pageData, place: newPlace});
    // });
    // remove any existing tide sources in pageData.page.place.datasources
    const datasourcesWithoutTide = pageData.place.datasources?.filter(ds => ds.sourcetype !== 'tide') || [];
    const newPlace = {...pageData.place, datasources: [...datasourcesWithoutTide, source]};
    setPageData({...pageData, place: newPlace});
    
  };

  // const detachAllDatasources = () => {
  //   clearDataSources(place.slug).then((new_place) => {
  //     setPlace(new_place);
  //   });
  // };

  // places have lists of sources, and only one type of each kind is allowed
  // filter to grab the datsource with sourcetype 'tide'
  const tidesource = place?.datasources?.filter(
    (ds) => ds.sourcetype === "tide"
  )[0];
  return (
    
        <div
          style={{
            display: "flex",
            alignItems: "left",
            flexDirection: "column",
          }}>

          
          {!pageData.tracking ? (<>
            <span>Select Tides Station: </span>
          <DropdownButton
            disabled={isLoadingDatasources || isEmpty(datasources)}
            variant="outline-primary"
            title={tidesource ? tidesource.name : "None"}>
            {datasources?.map((source, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => {
                  addSourceToPagePlace(source);
                }}>
                {source.name} ({Math.round(source.distance)} km)
              </Dropdown.Item>
            ))}
          </DropdownButton></>
          ) : (
            <span>Tracking pages automatically select closest tide station.  Change to fixed place to select a station.</span>
          )}
        </div>

  );
};
