import {useState} from "react";

import {Modal, Button, Row, Col} from "react-bootstrap";
import {useNavigate, Link} from "react-router-dom";

import useLogin from "hooks/useLogin";
import Google_G from "img/Google_G.svg";

/**
 * Routes protected by Google Login
 *
 * @param children
 * @returns {*|JSX.Element}
 */
const PrivateRoute = ({requireBeta, children}) => {
  const [showModal, setShowModal] = useState(true);
  const {login, isLoggedIn, userdata} = useLogin();
  const [loggingIn, setLoggingIn] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setShowModal(false);
    navigate("/");
  };

  const googleLogin = () => {
    setLoggingIn(true);
    login();
  };

  if (!isLoggedIn()) {
    // user is not authenticated
    return (
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login to continue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          No Account? <Link to="/register">Register</Link> Here
          {/* A row with centered text */}
          <Row className="py-2">
            {/* add a columns that spans only the middle 3rd of the row */}
            <Col xs={12} className="d-flex justify-content-center">
              <Button variant="outline-dark" onClick={googleLogin} disabled={loggingIn}>
                <img
                  width="20px"
                  style={{marginBottom: "3px", marginRight: "5px"}}
                  alt="Google sign-in"
                  src={Google_G}
                />
                {loggingIn ? "Logging in..." : "Login with Google"}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  if (requireBeta) {
    if (userdata?.usergroups && userdata?.usergroups.includes("beta")) {
      return children;
    } else {
      return (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Beta Access Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              You must be a beta tester to access this page.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  
  } else {
   return children;
  }
};

export default PrivateRoute;
