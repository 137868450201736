
import useProcessedWeather from "hooks/useProcessedWeather";
import {RibbonLabel} from "./ribbonlabel";
// import { isUndefined } from "lodash";
// import useStripe from "hooks/useStripe";
import useTides from "hooks/useTides";  
import useStripe from "hooks/useStripe";

export const TideRibbonLabel = (props) => {
  const {data} = useProcessedWeather(
    props.currentPlacedActivity.place
  );
// retrieve tides if they are not already present in this data.
const {data: tideData} = useTides(
  props.currentPlacedActivity.place,
  data
);

const {isCustomer} = useStripe(); 

// if (
//   isUndefined(tideData?.chartdata) ||
//   tideData.chartdata.length === 0 ||
//   isUndefined(tideData?.metadata?.tide)
// )
//   return;

  return (
    <RibbonLabel dims={props.dims}>
          <span className={"legend-text tide"}>
            {isCustomer() ? (
            <>Tides - {tideData.metadata?.tide ? tideData.metadata.tide.location.name : "No Tide Data"}</>
            ) : (
              <span><a href="/pricing">Upgrade to Premium for Tides</a></span>
            )}
            </span>
    </RibbonLabel>
  );
};
