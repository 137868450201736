import useSWR from "swr";

import {api_get, TIDE_PATH} from "api/backend";
import {DateTime} from "luxon";
import {isUndefined} from "lodash";
import useStripe from "hooks/useStripe";

const useTides = (place, weatherdata) => {
  const starttime = weatherdata?.chartdata ? weatherdata?.chartdata[0].datetime : undefined
  const hours = weatherdata?.chartdata ? weatherdata?.chartdata.length + 48 : undefined
  const newdata = {...weatherdata}
  const {isCustomer} = useStripe(); 

  // use of isUndefined(weatherdata.metadata?.tide) prevents overwriting of tide data
  // when it's already been collected in a weather query 
  const {data: tideData, error, mutate} = useSWR(
    isCustomer() && (isUndefined(weatherdata.chartdata) || isUndefined(weatherdata.chartdata[0].tide)) && place?.lat && place?.lon && starttime && hours
      ? `${TIDE_PATH}tides/?lat=${place.lat}&lon=${place.lon}&starttime=${starttime}&hours=${hours}`
      : null,
    api_get
  );  

  if (weatherdata?.metadata && tideData?.metadata?.tide) {
    newdata.metadata.tide = tideData?.metadata?.tide;
  }
  newdata?.chartdata &&
  tideData?.tides &&
  newdata.chartdata.forEach((record, i) => {
    newdata.chartdata[i].tide = tideData.tides[record.utctimestr];
    if (newdata.chartdata[i].tide?.exact_time) {
      newdata.chartdata[i].tide.exact_time = DateTime.fromISO(
        newdata.chartdata[i].tide.exact_time,
        {
          zone: newdata.metadata.timezone,
        }
      );
    }
  });

  return {data: newdata, error, mutate};
};

export default useTides;
