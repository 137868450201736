import usePage from "hooks/usePage";
import {useParams} from "react-router-dom";

import {CloudRibbon} from "./cloudribbon";
import {DewpointRibbon} from "./dewpointribbon";
import {GradeRibbon} from "./graderibbon";
import {HumidityRibbon} from "./humidityribbon";
import {PrecipAmountRibbon} from "./precipamountribbon";
import {PrecipProbRibbon} from "./precipprobribbon";
import {PrecipRibbon} from "./precipribbon";
import { WeatherTypeRibbon } from "./weathertyperibbon";
import { WeatherTypeRibbonLabel } from "./weathertyperibbonlabel";
import {PrecipRibbonLabel} from "./precipribbonlabel";
import {RecentSnowRibbon} from "./recentsnowribbon";
import {RibbonLabel} from "./ribbonlabel";
import {RibbonView} from "./ribbonview";
import {SnowfallRibbon} from "./snowfallribbon";
import {TempRibbon} from "./tempribbon";
import {TempRibbonLabel} from "./tempribbonlabel";
import {TideRibbon} from "./tideribbon";
import {TideRibbonLabel} from "./tideribbonlabel";
import {TimeRibbon} from "./timeribbon";
import {WaveHeightRibbon} from "./waveheightribbon";
import {WaveHeightRibbonLabel} from "./waveheightribbonlabel";
import {WavePeriodRibbon} from "./waveperiodribbon";
import {WavePeriodRibbonLabel} from "./waveperiodribbonlabel";
import {WetBulbRibbon} from "./wetbulbribbon";
import {WindRibbon} from "./windribbon";
import {FreezingLevelRibbon} from "./freezinglevelribbon";
import {AstroRibbon} from "./astroribbon";
import useGeoLocation from "hooks/useGeoLocation";
import useLocationState from "hooks/useLocationState";
import {isNull, isUndefined} from "lodash";
import {useEffect, useMemo, useState} from "react";
import {Button, Modal} from "react-bootstrap";

import logo from "img/logo.svg";
import useUserData from "hooks/useUserData";

const getRibbonAndLabelFromName = (name, pa, dims, index, prependLabel) => {
  switch (name) {
    // case "ScoreRibbon":
    case "GradeRibbon":
      return [
        <GradeRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <RibbonLabel dims={dims} key={index}>
          {prependLabel && (
            <span className="legend-place legend-text">{prependLabel}</span>
          )}
          <span className={"legend-text activity"}>
            {pa?.activity?.name}
            {/* {pa?.place?.preposition} {pa?.place?.name} */}
          </span>
        </RibbonLabel>,
      ];
    case "TempRibbon":
      return [
        <TempRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <TempRibbonLabel
          key={index}
          dims={dims}
          currentPlacedActivity={pa}
          prependLabel={prependLabel}
        />,
      ];
    case "WindRibbon":
      return [
        <WindRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <RibbonLabel key={index} dims={dims}>
          {prependLabel && (
            <span className="legend-place legend-text">{prependLabel}</span>
          )}
          <span className="windsustained legend-text">Winds</span>
          <span className="windgust legend-text">Gusts</span>
        </RibbonLabel>,
      ];
    case "PrecipRibbon":
      return [
        <WeatherTypeRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <WeatherTypeRibbonLabel
          key={index}
          dims={dims}
          currentPlacedActivity={pa}
          prependLabel={prependLabel}
        />,
      ];
    case "PrecipProbRibbon":
      return [
        <PrecipProbRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <RibbonLabel key={index} dims={dims}>
          {prependLabel && (
            <span className="legend-place legend-text">{prependLabel}</span>
          )}
          <span className="precip legend-text">Chance of Precip.</span>
        </RibbonLabel>,
      ];
    case "PrecipAmountRibbon":
      return [
        <PrecipAmountRibbon
          key={index}
          dims={dims}
          currentPlacedActivity={pa}
        />,
        <RibbonLabel key={index} dims={dims}>
          {prependLabel && (
            <span className="legend-place legend-text">{prependLabel}</span>
          )}
          <span className="qpf legend-text">Precip. Amount</span>
        </RibbonLabel>,
      ];
    case "CloudRibbon":
      return [
        <CloudRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <RibbonLabel key={index} dims={dims}>
          {prependLabel && (
            <span className="legend-place legend-text">{prependLabel}</span>
          )}
          <span className="cloud legend-text">Cloud Cover</span>
        </RibbonLabel>,
      ];
    case "HumidityRibbon":
      return [
        <HumidityRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <RibbonLabel key={index} dims={dims}>
          {prependLabel && (
            <span className="legend-place legend-text">{prependLabel}</span>
          )}
          <span className="humidity legend-text">Humidity</span>
        </RibbonLabel>,
      ];
    case "DewpointRibbon":
      return [
        <DewpointRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <RibbonLabel key={index} dims={dims}>
          {prependLabel && (
            <span className="legend-place legend-text">{prependLabel}</span>
          )}
          <span className="dewpoint legend-text">Dewpoint</span>
        </RibbonLabel>,
      ];
    case "TideRibbon":
      return [
        <TideRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <TideRibbonLabel key={index} dims={dims} currentPlacedActivity={pa} />,
      ];

    case "WaveHeightRibbon":
      return [
        <WaveHeightRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <WaveHeightRibbonLabel
          key={index}
          dims={dims}
          currentPlacedActivity={pa}
          prependLabel={prependLabel}
        />,
      ];

    case "WavePeriodRibbon":
      return [
        <WavePeriodRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <WavePeriodRibbonLabel
          key={index}
          dims={dims}
          currentPlacedActivity={pa}
          prependLabel={prependLabel}
        />,
      ];
    case "WetBulbRibbon":
      return [
        <WetBulbRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <RibbonLabel key={index} dims={dims}>
          {prependLabel && (
            <span className="legend-place legend-text">{prependLabel}</span>
          )}
          <span className="wetbulb legend-text">Wet Bulb</span>
        </RibbonLabel>,
      ];
    case "RecentSnowRibbon":
      return [
        <RecentSnowRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <RibbonLabel key={index} dims={dims}>
          {prependLabel && (
            <span className="legend-place legend-text">{prependLabel}</span>
          )}
          <span className="snow72 legend-text">72hr Snow</span>{" "}
          <span className="snow48 legend-text">48hr Snow</span>{" "}
          <span className="snow24 legend-text">24hr Snow</span>
        </RibbonLabel>,
      ];
    case "SnowfallRibbon":
      return [
        <SnowfallRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
        <RibbonLabel key={index} dims={dims}>
          {prependLabel && (
            <span className="legend-place legend-text">{prependLabel}</span>
          )}
          <span className="snowfall legend-text">Snow Fall</span>
        </RibbonLabel>,
      ];
      case "FreezingLevelRibbon":
        return [
          <FreezingLevelRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
          <RibbonLabel key={index} dims={dims}>
            {prependLabel && (
              <span className="legend-place legend-text">{prependLabel}</span>
            )}
            <span className="freezing_level_height legend-text">Freezing Level</span>
          </RibbonLabel>,
        ];
      case "AstroRibbon":
          return [
            <AstroRibbon key={index} dims={dims} currentPlacedActivity={pa} />,
            <RibbonLabel key={index} dims={dims}>
              {prependLabel && (
                <span className="legend-place legend-text">{prependLabel}</span>
              )}
              <span className="sun legend-text">Sun</span>          
              <span className="moon legend-text">Moon</span>
            </RibbonLabel>,
          ];
    default:
      return [
        <div
          style={{height: dims.svgheight + 2 * dims.verticalPadding}}
          key={index}></div>,
        <RibbonLabel key={index} dims={dims}>
          {prependLabel && (
            <span className="legend-place legend-text">{prependLabel}</span>
          )}
          <span className="legend-text">Error - No {name}</span>
        </RibbonLabel>,
      ];
    // throw new Error("unknown ribbon class name", name);
  }
};

const makeRibbons = (mode, dims, page, pageData, gpsError, currentGeoPlace) => {
  let timeRibbonPA;
  if (page?.tracking && !gpsError) {
    timeRibbonPA = {place: currentGeoPlace, activity: undefined};
  } else {
    // if not tracking, try to use the place associated with the page,
    // otherwise set it to the place associated with the top ribbon
    timeRibbonPA = {
      place: page?.place ? page.place : page?.memberribbons[0].place,
      activity: undefined,
    };
  }
  const timeribbon = (
    <TimeRibbon
      showLocation
      dims={dims}
      currentPlacedActivity={timeRibbonPA}
      mode={mode}
    />
  );

  let new_ribbons = [];
  let new_labels = [];
  page?.memberribbons
    ?.filter((mr) => mr.ribbon.acceptsdata === mode)
    .forEach((memberribbon, index) => {
      // const pa = {place: page.place, activity: locationPlacedActivity?.activity};
      // setup pa with default for page
      const pa = {place: page.place, activity: memberribbon.activity};

      if (page?.tracking) {
        if (pageData) {
          // in the case that we have returned here from the map
          pa.place = pageData.place;
        } else {
          // set the place of the currrentGeoPlace
          pa.place = currentGeoPlace;
        }
      }
      if (memberribbon.place) {
        pa.place = memberribbon.place;
      }
      const pair = getRibbonAndLabelFromName(
        memberribbon.ribbon.jsclass,
        pa,
        dims,
        index,
        memberribbon.place ? memberribbon.place.name : undefined,
      );
      new_ribbons.push(pair[0]);
      new_labels.push(pair[1]);
    });
  // if tracking, use geo place for the timeribbon

  return [new_ribbons, new_labels, timeribbon];
};

export const AllRibbons = ({viewDims}) => {
  const dims = useMemo(() => {
    return {
      svgheight: 150,
      pixelPerHour: 20,
      width: 20 * (24 * 8 - 9),
      horizontalPadding: 50,
      unitHeight: 150,
      verticalPadding: 0,
      legendOffset: 25,
      ribbonTop: 55,
      ribbonMargin: 30,
    };
  }, []);

  const {userdata, updateUserData} = useUserData();
  const {pageslug = "default"} = useParams();
  const {page} = usePage(pageslug);
  const {place: currentGeoPlace, error: gpsError} = useGeoLocation(
    page?.tracking,
  );

  const [dataList, setDataList] = useState([
    {dims: dims, timeribbon: undefined, labels: undefined, ribbons: undefined},
    {dims: dims, timeribbon: undefined, labels: undefined, ribbons: undefined},
  ]);

  const {pageData, navToMap} = useLocationState();
  const [firstVisit, setFirstVisit] = useState(false);
  useEffect(() => {
    if (userdata?.newuser) {
      setFirstVisit(true);
    }
  }, [userdata]);

  useEffect(() => {
    if (gpsError && !isUndefined(pageData?.place) && !isUndefined(page)) {
      page.place = pageData.place;
    }
    // const [new_ribbons_obs, new_labels_obs, timeribbon_obs] = makeRibbons(
    //   "observations",
    //   dims,
    //   page,
    //   pageData,
    //   gpsError,
    //   currentGeoPlace,
    // );

    const [new_ribbons_fcst, new_labels_fcst, timeribbon_fcst] = makeRibbons(
      "forecast",
      dims,
      page,
      pageData,
      gpsError,
      currentGeoPlace,
    );

    setDataList([
      {
        dims: {...dims, width: 0},
        // ribbons: new_ribbons_obs,
        // labels: new_labels_obs,
        // timeribbon: timeribbon_obs,
      },
      {
        dims: dims,
        ribbons: new_ribbons_fcst,
        labels: new_labels_fcst,
        timeribbon: timeribbon_fcst,
      },
    ]);
  }, [page, currentGeoPlace, dims, gpsError, pageData]);

  const selectGPS = () => {
    setFirstVisit(false)
    updateUserData({...userdata, newuser: false});
  }

  const selectMap = () => {
    setFirstVisit(false)
    updateUserData({...userdata, newuser: false});
    navToMap(page)

  }
  return (
    <>
      {firstVisit && page?.tracking ?
        <Modal
          style={{width: viewDims.width}}
          show={firstVisit}
          onHide={() => setFirstVisit(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Welcome!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img alt="Get Out Logo" className="welcome-logo" src={logo} />
            <p>
              Get Out Weather is the ultimate weather dashboard for outdoor
              activities.
            </p>
            <p>What is your Location?</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={() => selectGPS()}>
              Use GPS
            </Button>
            <Button variant="primary" onClick={() => selectMap()}>
              Use a Map
            </Button>
          </Modal.Footer>
        </Modal>
      : // Not firstVisit
      (
      isNull(page?.place) && gpsError && (
        <Modal
          className="no-geo-dialog"
          show={true}
          style={{width: viewDims.width}}
          >
          <Modal.Header closeButton>
            <Modal.Title>Unable to Get Your Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Your browser says{" "}
              <code className="mx-1" style={{fontFamily: "monospace"}}>
                {gpsError.message}.
              </code>{" "}
            </p>
            <p>That's okay!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => navToMap(page)}>
              Pick Place on Map
            </Button>
          </Modal.Footer>
        </Modal>
      ))}
      {/* // Ribbons get passed in as a list of components
          // to ensure that the boundaries are
          // calculated correctly in RibbonView */}

      <RibbonView viewDims={viewDims} dataList={dataList} />
    </>
  );
};
