import * as d3 from "d3";

import useProcessedWeather from "hooks/useProcessedWeather";
import {draw_time_axes_tz, draw_yaxes, draw_line} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import {isUndefined} from "lodash";
import useOpenMeteo from "hooks/useOpenMeteo";
import {draw_horizontal_line} from "util/drawfuncs";

export const FreezingLevelRibbon = (props) => {
  const {data: nwsdata} = useProcessedWeather(
    props.currentPlacedActivity.place,
  );
  const {data} = useOpenMeteo(props.currentPlacedActivity.place, nwsdata);

  if (isUndefined(data?.chartdata) || data.chartdata.length === 0) return;
  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    nwsdata?.chartdata,
  );

  const draw_level_marker_text = (
    svg,
    data,
    attr_name,
    midpoint,
    xScale,
    yScale,
    units = "",
    every = 3,
  ) => {
    // const shift =shift = above ? -10 : +16;
    var lastadded = 0;
    const extrema = data.filter((r, i) => {
      if (
        lastadded + 3 > i ||
        isUndefined(r) ||
        isUndefined(data[i - 1]) ||
        isUndefined(data[i + 1])
      )
        return false;
      if (lastadded + 5 < i && r[attr_name] > 0) {
        lastadded = i;
        return true;
      }
      if (
        (r[attr_name] > data[i - 1][attr_name] &&
          r[attr_name] >= data[i + 1][attr_name]) ||
        (r[attr_name] < data[i - 1][attr_name] &&
          r[attr_name] <= data[i + 1][attr_name])
      ) {
        lastadded = i;
        return r[attr_name] > 0;
      }
    });
    const extrema_with_shift = extrema.map((d) => {
      return {...d, shift: d[attr_name] < midpoint ? -10 : +16};
    });

    svg
      .selectAll("#amarkertext" + attr_name)
      .data(extrema_with_shift)
      .join("text")
      .attr("x", (d) => xScale(d.time) - 6)
      .attr("y", (d) => yScale(d[attr_name]) + d.shift)
      .attr("class", "marker-text " + attr_name)
      .text((d) => d[attr_name] + units)
      .attr("id", "markertext" + attr_name);
  };

  const draw_freezing_level = (g, data, width, height, attrlist, xScale) => {
    //Yscales
    let maxheight = d3.max(data.chartdata, function (d) {
      return d3.max(attrlist.map((name) => d[name]));
    });
    // roundup to closest 10
    maxheight = Math.ceil(maxheight / 100) * 100;

    let minheight = d3.min(data.chartdata, function (d) {
      return d3.min(attrlist.map((name) => d[name]));
    });
    minheight = Math.floor((minheight - maxheight * 0.1)/ 100) * 100;
    const midpoint = (maxheight + minheight) / 2;
    const yScale = d3
      .scaleLinear()
      .domain([minheight, maxheight])
      .range([height, 0])
      .nice();
    
    draw_yaxes(g, yScale, width, "ft", 0);

    draw_time_axes_tz(
      g,
      xScale,
      height,
      nwsdata.nightblocks,
      false,
      data?.metadata?.timezone,
    );

    attrlist.forEach((name) => {
      draw_line(g, data.chartdata, name, xScale, yScale);
      draw_level_marker_text(
        g,
        data.chartdata,
        name,
        midpoint,
        xScale,
        yScale,
        "'",
        6,
      );
    });

    if (!isNaN(yScale(data?.metadata?.elevation.value))) {
      draw_horizontal_line(
        g,
        width,
        height,
        yScale,
        data.metadata.elevation.value,
        "elevationline",
      );
      g.append("text")
        .attr("x", 2) // specify the x position
        .attr("y", yScale(data.metadata.elevation.value) - 5) // specify the y position
        .text(`Ground Level (${data.metadata.elevation.value} ${data.metadata.elevation.units})`) // specify the text
        .attr("font-family", "sans-serif") // specify the font
        .attr("font-size", "18px") // specify the font size
        .attr("class", "elevationline-text"); // specify the fill color
    }
  };

  let draw_func = (svg) => {
    draw_freezing_level(
      svg,
      data,
      props.dims.width,
      props.dims.unitHeight,
      ["freezing_level_height"],
      xScale,
    );
  };
  return (
    <div className="ribbon">
      <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
