import {Row, Col} from "react-bootstrap";
import MultiHandleSlider from "./MultiHandleSlider";

const SliderList = ({factors, allRanges=[], onChange}) => {
  const handleSliderChange = (feature, range) => {
    let updatedRanges;
    if (allRanges.filter((range) => range.feature === feature).length === 0) {
      updatedRanges = [...allRanges, range];
    } else {
      updatedRanges = allRanges.map((r) => (feature === r.feature ? range : r));
    }
    onChange(updatedRanges);
  };

  //   const [mergedRanges, setMergedRanges] = useState(undefined);

  //   useEffect(() => {
  //     setMergedRanges(
  const mergedRanges = factors?.map((f, i) => {
    // find the matching range
    const range = allRanges.filter((r) => f.slug === r.feature)[0];

    return {
      feature: f.slug,
      label: f.name,
      range: {
        min: f.min,
        max: f.max,
        commonMin: f.commonmin,
        commonMax: f.commonmax,
      },
      step: f.step || 1,
      values: range ? range.values : f.default,
      weight: range && "weight" in range ? range.weight : range ? 1 : 0,
      units: f.units,
    };
  });

  return (
      <Row>
        {mergedRanges
          ?.filter((v) => v.range)
          .map((v, i) => {
            if (v.feature !== "ptype")
              return (
                <Col key={i} xs={12} lg={6}>
                  <MultiHandleSlider
                    key={i}
                    feature={v.feature}
                    values={v.values}
                    absoluteMin={v.range?.min}
                    absoluteMax={v.range?.max}
                    commonMin={v.range?.commonMin}
                    commonMax={v.range?.commonMax}
                    step={v.step}
                    weight={v.weight}
                    onChange={(newValues) =>
                      handleSliderChange(v.feature, newValues)
                    }
                    title={v.label}
                    units={v.units}
                  />
                </Col>
              );
            else return <div key={i}></div>;
          })}
      </Row>
  );
};

export default SliderList;
