import useSWR from "swr";

import {calc_activity_grade} from "util/grader";
import useProcessedWeather from "./useProcessedWeather";

const scoreWeatherData = ([data, activity]) => {
  const scoredData = {
    ...data,
    chartdata: data.chartdata.map((record) => {
        // if (data.chartdata[i]["activities"] === undefined) {
        //   data.chartdata[i]["activities"] = {};
        // }
        const grades = calc_activity_grade(activity.requirements, record);
        const newActivities = {...record.activities}
        newActivities[activity.slug] = {
          name: activity.name,
          score: grades[0],
          grade: grades[1],
        };
        return {...record, activities: newActivities}
    }),
  };
  return scoredData;
};

export const useScoredWeather = (placedactivity) => {
  const {data: weatherdata, mutate: mutateWeather} = useProcessedWeather(
    placedactivity?.place
  );

  const {
    data,
    error,
    mutate: mutateScore,
  } = useSWR(
    weatherdata && placedactivity?.activity
      ? [weatherdata, placedactivity?.activity]
      : null,
    scoreWeatherData
  );

  return {
    data,
    error,
    mutateScore,
    mutateWeather,
  };
};

export default useScoredWeather;
