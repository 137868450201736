import {useEffect, useRef} from "react";
import * as d3 from "d3";

export const Ribbon = ({draw_func, dims, style}) => {
  /* The useRef Hook creates a variable that "holds on" to a value across rendering
      passes. In this case it will hold our component's SVG DOM element. It's
      initialized null and React will assign it later (see the return statement) */
  const d3Container = useRef(null);
  useEffect(() => {
    if (d3Container.current) {
      const svg = d3.select(d3Container.current);
      svg.select("#main").remove();
      let root = svg.append("g").attr("id", "main");
      draw_func(root);
    }
  }, [d3Container, draw_func]);

  return (
    <div className="ribbon" style={{marginBottom: dims.ribbonMargin}}>
    <svg
      height={dims.svgheight}
      // width={dims.svgwidth}
      ref={d3Container}
      viewBox={
        -dims.horizontalPadding +
        " " +
        -dims.verticalPadding +
        " " +
        (dims.width + dims.horizontalPadding * 2) +
        " " +
        (dims.unitHeight + 2 * dims.verticalPadding)
      }></svg>
      </div>
  );
};
