import * as d3 from "d3";

import useProcessedWeather from "hooks/useProcessedWeather";
import {
  draw_time_axes_tz,
  draw_yaxes,
  draw_line,
  draw_marker_text,
} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import {isUndefined} from "lodash";

export const WindRibbon = (props) => {
  const {data} = useProcessedWeather(props.currentPlacedActivity.place);

  if (isUndefined(data?.chartdata) || data.chartdata.length === 0) return;
  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    data?.chartdata
  );

  const barb = (speed) => {
    const length = -20;
    const width = 10;
    const hop = length / 10;
    let count = speed;
    let gap = false;
    let path = "M 0 0 v " + length;
    if (count < 10) {
      gap = true;
    }
    while (count > 4) {
      if (count >= 50) {
        path += "l " + width + " " + -hop;
        path += "l " + -width + " " + -hop;
        // path += "m 0 " + (-hop)
        count -= 50;
        gap = true;
      } else if (count >= 10) {
        if (gap) {
          path += "m 0 " + -hop;
        }
        path += "l " + width + " " + hop;
        path += "l " + -width + " " + -hop;
        // path += "m 0 " + (-hop)
        count -= 10;
        gap = true;
      } else if (count >= 4) {
        if (gap) {
          path += "m 0 " + -hop;
        }
        path += "l " + width / 2 + " " + hop / 2;
        path += "l " + -(width / 2) + " " + -hop / 2;
        // path += "m 0 " + (-hop)
        count -= 5;
      }
    }
    return path;
  };

  const getCardinalDirection = (degrees) => {
    // Define the cardinal directions
    var directions = [
      "N",
      "NNE",
      "NE",
      "ENE",
      "E",
      "ESE",
      "SE",
      "SSE",
      "S",
      "SSW",
      "SW",
      "WSW",
      "W",
      "WNW",
      "NW",
      "NNW",
    ];

    // Calculate the index based on the degrees
    var index = Math.round(degrees / 22.5);

    // Adjust the index for values greater than or equal to 16
    if (index >= 16) {
      index = 0;
    }

    // Return the cardinal direction
    return directions[index];
  };

  const draw_wind_barbs = (svg, data, xScale, yScale) => {
    svg
      .selectAll("#windbarb")
      .remove()
      .data(data.filter((d, i) => i !== data.length - 1 && d.windsustained && d.winddirection))
      .join("g")
      .attr(
        "transform",
        (d) =>
          "translate(" +
          xScale(d.time) +
          "," +
          yScale(d.windsustained) +
          ")" +
          "rotate(" +
          d.winddirection +
          ")"
      )
      .append("path")
      .attr("d", (d) => barb(d.windsustained))
      .attr("class", "barb")
      .attr("id", "windbarb");

    svg
      .selectAll("#winddir")
      .remove()
      .data(
        data.filter(
          (d, i) => i % 3 === 2 && i !== data.length - 1 && d.windsustained
        )
      )
      .join("text")
      .attr("x", (d) => xScale(d.time))
      .attr("y", 15)
      .attr("class", "marker-text winddir")
      .style("text-anchor", "middle")
      .text((d) => getCardinalDirection(d.winddirection))
      .attr("id", "markertext winddir");
  };

  const draw_winds = (g, data, width, height, xScale) => {


    //Yscales
    let maxwind = d3.max(data.chartdata, function (d) {
      return d3.max([d.windsustained, d.windgust]);
    });
    // roundup to closest 10
    maxwind = Math.ceil(maxwind / 10) * 10 + 20;

    let minwind = d3.min(data.chartdata, function (d) {
      return d3.min([d.windsustained, d.windgust]);
    });
    minwind = Math.floor(minwind / 10) * 10 - 10;
    const yScale = d3
      .scaleLinear()
      .domain([minwind, maxwind])
      .range([height, 0])

    draw_yaxes(g, yScale, width, "", 0);
    draw_time_axes_tz(
      g,
      xScale,
      height,
      data.nightblocks,
      false,
      data.metadata.timezone
    );
    draw_line(g, data.chartdata, "windsustained", xScale, yScale);
    draw_marker_text(g, data.chartdata, "windsustained", false, xScale, yScale);
    draw_line(g, data.chartdata, "windgust", xScale, yScale);
    draw_marker_text(g, data.chartdata, "windgust", true, xScale, yScale);
    draw_wind_barbs(g, data.chartdata, xScale, yScale);
  };

  let draw_func = (svg) => {
    draw_winds(svg, data, props.dims.width, props.dims.unitHeight, xScale);
  };

  return (
    <div className="ribbon">
      <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
