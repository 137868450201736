import * as d3 from "d3";

import useProcessedWeather from "hooks/useProcessedWeather";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import {Link} from "react-router-dom";
import {isUndefined} from "lodash";
import {DateTime} from "luxon";

export const TimeRibbon = (props) => {
  const mode = props.mode ? props.mode : "forecast";
  const {data} = useProcessedWeather(props.currentPlacedActivity.place, mode);
  if (isUndefined(data?.chartdata)) return;

  const draw_time_ribbon = (g, xScale, height) => {
    const maxMillis = xScale.domain()[1];
    const minMillis = xScale.domain()[0];
    let tickValuesdaily = [minMillis];
    const tickInterval1hr = 1000 * 60 * 60;
    let i = minMillis + tickInterval1hr;
    while (i <= maxMillis) {
      const t = DateTime.fromMillis(i).setZone(data?.metadata?.timezone);
      if (
        t.hour === 0 
        // &&
        // t.minute === 0 &&
        // t.second === 0 &&
        // t.millisecond === 0
      ) {
        tickValuesdaily.push(i);
      }
      i += tickInterval1hr;
    }

    const differences = tickValuesdaily.slice(1).map((value, index) => value - tickValuesdaily[index]);
    differences.push(maxMillis - tickValuesdaily[tickValuesdaily.length - 1]);
    
    const placements = differences.map((d) => d < 50000000 ? 5 : 140)

    const midday = xScale(1000 * 60 * 60 * 12);
    const dayAxis = d3
      .axisBottom(xScale)
      //.ticks(d3.timeDay, "%a, %b %e %Y")
      .tickSize(height)
      .tickFormat((d, i) => {
        const t = DateTime.fromMillis(d).setZone(data?.metadata?.timezone);
        return t.toFormat(t.hour < 16 ? "ccc DD" : t.hour < 22 ? "ccc" : "");
      })
      .tickValues(tickValuesdaily);

    g.select("#dayAxis").remove();
    g.append("g")
      .attr("id", "dayAxis")
      .call(dayAxis)
      .selectAll("text")
      .attr("transform", (d) =>  `translate(${ placements.shift()},-25)`)
      .style("text-anchor",  "start")
      .attr("class", "dayaxis");

    g.select("#dayAxis")
      .selectAll(".tick line")
      .attr("transform", "translate(0,0)");

    g.select("#outline").remove();
    g.append("rect")
      .attr("width", xScale.range()[1] - xScale.range()[0])
      .attr("height", height)
      .attr("class", "outline")
      .attr("id", "outline");
    //.call(make_x_gridlines(xScale).tickSize(-height).tickFormat(""));
  };


  let draw_func = (svg) => {
    draw_time_ribbon(svg, xScale, 30);
  };

  if (isUndefined(data?.chartdata) || data.chartdata.length === 0) return;
  const chartmeta = data.chart_meta;
  const asof_string = new Date(chartmeta.creationdate).toLocaleString("en-us", {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    data?.chartdata
  );

  const maplink = `/map/`

  return (
    <div className="ribbon">
      {!isUndefined(props.showLocation) && (
        <div id="location-string" style={{width: props.dims.width}}>
          <Link
            to={maplink}
            state={{
              pathToPlace: "place",
              pageData: props.currentPlacedActivity,
            }}
            >
            {props.currentPlacedActivity.place.name} 
            {/* ({chartmeta.description}) */}
          </Link>
          <span>{` (elev. ${chartmeta.height.value} ${chartmeta.height.units})`}</span>
          {/* {"As of " + asof_string + " (local time), "} */}

        </div>
      )}
      <div className="timeribbon">
        {/* <div className="floatlegend"> */}
        <Ribbon
          draw_func={draw_func}
          dims={{
            ...props.dims,
            svgheight: props.dims.svgheight / 5,
            unitHeight: 30,
            verticalPadding: 0,
          }}
        />
      </div>
    </div>
  );
};
