import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import ReactDOM from "react-dom/client";
import {BrowserTracing} from "@sentry/browser";

import reportWebVitals from "./reportWebVitals";
import {createApp} from "./setup";

import "./styles/custom.scss";

if (process.env.NODE_ENV !== "development") {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  enabled: process.env.NODE_ENV !== "development",
  // if REACT_APP_BACKEND_API_URL starts with staging, then we are on staging, otherwise prod
  environment: process.env.REACT_APP_BACKEND_API_URL?.includes("staging") ? "staging" : "production",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.NODE_ENV === "development" ? 1.0: 0.1,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(createApp());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
