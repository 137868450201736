import {Link} from "react-router-dom";


/**
 * Sticky footer component
 *
 * @returns {JSX.Element}
 */
export const Footer = ({viewDims}) => {
  
  return (
    <footer style={{width: viewDims.width + 20, top: viewDims.height - 35}} className="footer row mt-2 bg-white px-2 pt-1 pb-1 border-top">
      <small className="col-6">
        <Link to="/privacy">Privacy</Link>{" & "} 
        <Link to="/terms">Terms</Link>
      </small>
      <small className="col-6 text-muted text-end">
        <i>Carpe Diem Serenas.</i>
      </small>
    </footer>
  );
};
