import {
  faLocationArrow,
  faLocationDot,
  faMap,
  faCrown,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EditRibbons} from "components/EditRibbons";
import HelpDialog from "components/HelpDialog";
import usePage from "hooks/usePage";
import useUserData from "hooks/useUserData";
import {isEmpty, isUndefined} from "lodash";
import {useEffect, useState} from "react";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import useStripe from "hooks/useStripe";
import useLocationState from "hooks/useLocationState";
import {useUser} from "hooks/useUser";
import toast from "react-hot-toast";

/**
    Page Editor
    a page editor is a component that allows the user to edit a page by dragging
    and dropping cards that represent the order of ribbons on a page.
    THe page editor pertains to a Page object in the database accessible with the usePage
    hook.  Pages have slugs and are associated with a user.
    THe page editor is a page with a route from Root.js

    When the newPage prop is set to true, the page editor will create a new page instance 
    when the user saves the edits, rather than editting and exisitng page.   
    @returns {JSX.Element}
*/

export const PageEditor = ({newPage}) => {
  const {slug} = useParams();

  // load searchParams with hook
  // const location = useLocation();
  const [toDuplicate, setToDuplicate] = useState(false);
  const {user} = useUser();
  const {
    pageData: dataFromParams,
    setPlace,
    getPlace,
    navToCallback,
    navToMap,
  } = useLocationState();

  const {
    page: loaded_page,
    updatePage,
    postNewPage,
  } = usePage(newPage ? "default" : slug);

  const [pageData, setPageData] = useState(
    // check searchParams for data JSON object. if present, use that instead of loaded_page
    dataFromParams ? dataFromParams : loaded_page,
  );
  const {userdata, updateUserData, mutate: mutateUserData} = useUserData();
  const {isCustomer} = useStripe();
  const navigate = useNavigate();

  useEffect(() => {
    // check if owner, and if not, ensure the user know we will duplicate
    // and then ensure when saving that you duplicate first

    if (isEmpty(dataFromParams)) {
      if (newPage) {
        setPageData({
          ...loaded_page,
          slug: undefined,
          name: "New Page",
          owner: undefined,
          id: undefined,
          post: true,
        });
      } else {
        const isNotMine =
          !newPage &&
          !isUndefined(loaded_page) &&
          loaded_page.owner !== user.id;
        setToDuplicate(isNotMine);
        if (isNotMine) {
          toast(
            "You are not the owner of this page. Edits will be saved to a duplicate.",
            {
              duration: 3000,
              interaction: (toast, interaction) => {
                if (interaction === "dismiss") {
                  return true;
                }
                return false;
              },
              toastId: "duplicatePage",
            },
          );
          const dupePage = {
            ...loaded_page,
            slug: undefined,
            name: loaded_page.name + " (copy)",
            owner: undefined,
            id: undefined,
            post: true,
          };
          setPageData(dupePage);
        } else {
          setPageData(loaded_page);
        }
      }
    }
  }, [loaded_page, newPage, dataFromParams]);

  const handleTrackingChange = (isTracking) => {
    // const new_place = isTracking ? undefined : pageData.place;
    setPageData({...pageData, tracking: isTracking});
  };

  // const handleCardChange = (index, key, value) => {
  //   const new_ribbons = [...pageData.memberribbons];
  //   new_ribbons[index][key] = value;
  //   setPageData({...pageData, memberribbons: new_ribbons});
  // };

  const handleSave = () => {
    // remove place from pageData if saving without tracking
    const newPlace = pageData.tracking ? null : pageData.place;
    const savePageData = {
      ...pageData,
      place: newPlace,
    };

    setPageData(savePageData);

    if (savePageData?.post) {
      postNewPage(savePageData).then((newpageFromBackend) => {
        // now add to userdata
        const userdata_plus_newpage = {
          ...userdata,
          pages: [...userdata.pages, newpageFromBackend],
        };

        updateUserData(userdata_plus_newpage).then(() => {
          navigate(`/page/view/${newpageFromBackend.slug}`);
        });
      });
    } else {
      updatePage(savePageData).then((newpageFromBackend) => {
        mutateUserData();
        navigate(`/page/view/${newpageFromBackend.slug}`);
      });
    }
  };

  const handleCancel = () => {
    navigate("/");
  };

  if (isUndefined(pageData)) {
    return <p>Loading Editor</p>;
  }

  const gotoMapForPage = () => {
    // pathToPlace tells the map tools where to insert the selected or created place inside the pageData object

    navToMap(pageData);
  };

  const saveButton = () => {
    return (!newPage || isCustomer() || isEmpty(userdata?.pages) ? (
      <Button variant="primary" onClick={handleSave}>
        Save & View
      </Button>
    ) : (
      <Button variant="primary" onClick={() => navigate("/pricing")}>
        <FontAwesomeIcon icon={faCrown} /> Go Premium To Add More Pages
      </Button>
    ))
  }
  return (
    <>
      <Container className="page-editor">
        <Row className="my-2">
          <Col>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
          <Col className="d-flex justify-content-end">
            {saveButton()}
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Card className="border border-dark border-1">
              <Card.Header className="compact-card-header">
                <span>
                  <Card.Title>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <HelpDialog title="Page Name">
                        <p>Edit the page name by clicking the pencil button.</p>
                        <p>
                          Pages have two modes: Current - (show by{" "}
                          <FontAwesomeIcon icon={faLocationArrow} />) where the
                          default place is the user's current location, and
                          fixed place (show by{" "}
                          <FontAwesomeIcon icon={faLocationDot} />
                          )- where the page is associated with a specific place.
                        </p>
                        <p>
                          Pages can also be given descriptions which may assist
                          others in understanding the motivation for the page
                          you have made.
                        </p>
                      </HelpDialog>
                      Name and Description
                      {/* {!isEditingTitle && (
                    <Button
                    
                      variant={"link"}
                      onClick={() => setIsEditingTitle(true)}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                  )}
                  <EditableCardTitle
                    title={pageData.name}
                    onSave={handleTitleChange}
                    setEditing={setIsEditingTitle}
                    editing={isEditingTitle}
                  /> */}
                      {/* show the map icon for places and the crosshair for tracking */}
                    </div>
                  </Card.Title>
                </span>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    {/* Text entry field that updates page description */}
                    <Form.Group controlId="exampleForm.ControlTextarea0">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        as="input"
                        rows={1}
                        value={pageData.name}
                        onChange={(e) => {
                          setPageData({...pageData, name: e.target.value});
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {/* Text entry field that updates page description */}
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Page Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        value={pageData.description}
                        onChange={(e) => {
                          setPageData({
                            ...pageData,
                            description: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="my-2 border border-dark border-1">
              <Card.Header className="compact-card-header">
                <Card.Title>
                  <HelpDialog title="Place">
                    <p>
                      The radio buttons select "live location" mode or "fixed
                      place" mode for this page. Fixed Place can only be
                      selected if you have picked a place. Click "Pick Place"
                      button to select and name a place on a map.
                    </p>
                    <p>
                      Pages have two modes: Live Location - (show by{" "}
                      <FontAwesomeIcon icon={faLocationArrow} />) where the
                      default place is the user's current location, and Fixed
                      Place (show by <FontAwesomeIcon icon={faLocationDot} />
                      )- where the page is associated with a specific place.
                    </p>
                  </HelpDialog>
                  Place (<FontAwesomeIcon icon={faLocationArrow} /> GPS or{" "}
                  <FontAwesomeIcon icon={faLocationDot} /> Fixed)
                </Card.Title>
              </Card.Header>

              <Card.Body>
                <Row>
                  <Col>
                    <label style={{display: "flex", alignItems: "center"}}>
                      <Form.Check
                        className="me-2"
                        checked={pageData.tracking || false}
                        onChange={(v) => handleTrackingChange(true)}
                        type="radio"
                      />{" "}
                      <FontAwesomeIcon
                        className="me-1"
                        icon={faLocationArrow}
                      />
                      Live Location (GPS){" "}
                    </label>

                    <label style={{display: "flex", alignItems: "center"}}>
                      <Form.Check
                        className="me-2"
                        checked={!pageData.tracking || false}
                        disabled={isEmpty(pageData.place)}
                        onChange={(v) => handleTrackingChange(false)}
                        type="radio"
                      />
                      <FontAwesomeIcon className="me-1" icon={faLocationDot} />
                      {pageData?.place ? pageData.place.name : "No Place Set"}
                    </label>
                  </Col>
                  <Col>
                    <Button
                      variant="primary"
                      onClick={() => {
                        gotoMapForPage();
                      }}>
                      <FontAwesomeIcon className="me-2" icon={faMap} />
                      Set Place
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="my-2 border border-dark border-1">
              <Card.Header className="compact-card-header">
                <Card.Title>
                  <HelpDialog title="Sharing">
                    <p>
                      This Check Box allows you to make the page shareable or
                      keep private. Private pages are not viewable by other even
                      if you share the link.
                    </p>
                  </HelpDialog>
                  Sharing <FontAwesomeIcon icon={faPeopleGroup} />
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Check
                      checked={pageData.public || false}
                      onChange={(v) => {
                        setPageData({...pageData, public: v.target.checked});
                      }}
                      type="checkbox"
                      label="Shareable"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={6}>
            <EditRibbons pageData={pageData} setPageData={setPageData} />
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
          <Col className="d-flex justify-content-end">
            {saveButton()}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PageEditor;

/**
 * Ribbon Editor
 * @param ribbon
 * @param handleCardChange
 * @returns {JSX.Element}
 */
