import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import {isEmpty, isUndefined} from "lodash";

import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
// import useActivities from "hooks/useActivities";
import useUserData from "hooks/useUserData";
// import NewActivity from "components/NewActivity";
import HelpDialog from "components/HelpDialog";
import {DateTime} from "luxon";
import useStripe from "hooks/useStripe";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart, faMobileScreen} from "@fortawesome/free-solid-svg-icons";
import {Badge} from "react-bootstrap";
import {api_post, MAILINGLIST_ADD_PATH, MAILINGLIST_REMOVE_PATH} from "api/backend";
import {useUser} from "hooks/useUser";

/**
 * Profile page lets users select activities
 * It presents a grid toggle buttons.  Each press of a toggle button
 * will add or remove that activity from the user's userdata.activities
 * many to many on the backend.
 *
 * This page also has a button at the bottom to them to the map view after they
 * have selected one or more activities
 */
export const Profile = () => {
  // const {
  //   activities: allPossibleActivityList,
  //   // deleteActivity,
  //   // addActivity,
  // } = useActivities();
  const {user} = useUser();
  const {userdata} = useUserData();
  const {billingRedirect, checkoutRedirect, isCustomer} = useStripe();
  const [showNewActivity, setShowNewActivity] = useState(false);

  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // if we don't have userdata or activities, don't render yet
  // if (isUndefined(userdata?.activities) || isEmpty(allPossibleActivityList)) {
  //   return;
  // }

  const handleMailingListSubscribe = () => {
    api_post(MAILINGLIST_ADD_PATH, {list:"main"}).then((res) => {
      if ('status' in res) {
        setMessage(res.status);
        setSuccess(true);
      } else if ('error' in res) {
        setMessage(res.error.message)
        setSuccess(false);
      }

    }).catch((err) => {
      setMessage(err.data)
      setSuccess(false);
    })
  }

  const handleMailingListUnsubscribe = () => {
    api_post(MAILINGLIST_REMOVE_PATH, {list:"main"}).then((res) => {
      if ('status' in res) {
        setMessage(res.status);
        setSuccess(true);
      } else if ('error' in res) {
        setMessage(res.error.message)
        setSuccess(false);
      }

    }).catch((err) => {
      setMessage(err.data)
      setSuccess(false);
    })
  }


  // // toggle activity in userdata via the API
  // const toggleSelect = (activity) => {
  //   let newSelectedActivityList;
  //   // if this activity is NOT already in actiities.userdata.ativities, add it
  //   if (userdata.activities.findIndex((a) => activity.slug === a.slug) === -1) {
  //     // was not selected, add to list
  //     newSelectedActivityList = [...userdata.activities, activity];
  //   } else {
  //     // was selected, remove from list
  //     newSelectedActivityList = userdata.activities.filter(
  //       (a) => a.slug !== activity.slug
  //     );
  //   }
  //   // update userdata by changing it in place, but then sending that new object to the backend
  //   userdata.activities = newSelectedActivityList;
  //   updateUserData(userdata);
  // };

  // const onDelete = (slug) => {
  //   deleteActivity(slug);
  // };

  // const onEdit = (slug) => {
  //   navigate(`/score/edit/${slug}`);
  // };

  // const onClone = async (baseActivity) => {
  //   const submitActivity = {
  //     ...baseActivity,
  //     id: undefined,
  //     slug: undefined,
  //     name: "⭐ " + baseActivity.name,
  //     public: false,
  //   };
  //   const newactivity = await addActivity(submitActivity);
  //   navigate(`/score/edit/${newactivity.slug}`);
  // };

  return (
    <div className="container">
      {/* <NewActivity
        showModal={showNewActivity}
        setShowModal={setShowNewActivity}
      /> */}
      <h2 className="py-2 pt-3">Profile</h2>

      {/* Feedback */}
      <Alert variant="primary">
        We welcome ideas, complaints, questions, and feedback of all kinds!{" "}
        <a href="mailto:feedback@getoutweather.com">
          feedback@getoutweather.com
        </a>
      </Alert>

      {/* BILLING */}
      <Card className="my-2">
        <Card.Header>
          <Row>
            <Col xs={6}>
              <h5>
                <HelpDialog title="Billing">
                  This button will take you to your stripe billing portal. From
                  there, you can update your payment method, view your invoices,
                  and update or cancel your subscription. Learn more about{" "}
                  <Link to="/pricing">pricing</Link>.
                </HelpDialog>
                Billing
              </h5>
            </Col>
            <Col xs={6} className="d-flex justify-content-end">
              {userdata?.subscription ? (
                <span>
                  <Button
                    style={{whiteSpace: "nowrap"}}
                    className="m-2"
                    variant="primary"
                    onClick={() => {
                      billingRedirect();
                    }}>
                    Billing Portal
                  </Button>
                </span>
              ) : isCustomer() ? (
                <span>
                  <FontAwesomeIcon className="me-1" icon={faHeart} />
                  Friends & Family get access without subscription
                </span>
              ) : (
                // not a customer yet
                <Button
                  style={{whiteSpace: "nowrap"}}
                  className="m-2"
                  variant="primary"
                  onClick={() => {
                    checkoutRedirect();
                  }}>
                  Subscribe
                </Button>
              )}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Table>
            <tbody>
              <tr>
                <td>Current Tier:</td>
                <td>
                  {/* TODO: Fix This Below */}
                  {userdata?.subscription?.tier?.name
                    ? userdata?.subscription?.tier?.name || ""
                    : isCustomer()
                    ? "Friend of Tim"
                    : "Basic"}
                </td>
              </tr>
              {userdata?.subscription && (
                <>
                  <tr>
                    <td>Status:</td>
                    <td>
                      {userdata?.subscription?.status === "active"
                        ? "Active"
                        : userdata?.subscription?.status}
                    </td>
                  </tr>
                  <tr>
                    <td>Payment Plan:</td>
                    <td>
                      {userdata?.subscription?.plan ? (
                        (
                          userdata?.subscription?.plan?.amount / 100
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        }) +
                        " per " +
                        (userdata?.subscription?.plan?.interval_count === 1
                          ? userdata?.subscription?.plan?.interval
                          : userdata?.subscription?.plan?.interval_count +
                            " " +
                            userdata?.subscription?.plan?.interval +
                            "s")
                      ) : (
                        <b>No Further Payments</b>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>This Plan Expires:</td>
                    <td>
                      {DateTime.fromISO(
                        userdata?.subscription?.enddate
                      ).toFormat("MMMM d, yyyy")}
                    </td>
                  </tr>
                  <tr>
                    <td>Will Renew Automatically:</td>
                    <td>
                      {userdata?.subscription?.willcancel ? (
                        <Badge bg="danger">No</Badge>
                      ) : (
                        <Badge bg="success">Yes</Badge>
                      )}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          {/* Current Tier: {userdata?.subscription?.tier?.name}
            Cost: {(userdata?.subscription?.plan?.amount / 100).toLocaleString("en-US", {style: "currency", currency:"USD"})} per 
            {userdata?.subscription?.plan?.interval_count == 1 ? userdata?.subscription?.plan?.interval: 
              userdata?.subscription?.plan?.interval_count + " " + userdata?.subscription?.plan?.interval + "s"
            }
            Expires: {userdata?.subscription?.enddate}
            AutoRenew: {userdata?.subscription?.willCancel ? "No" : "Yes"}
            
            Manage your billing information and subscription. */}
        </Card.Body>
      </Card>

      {/* PLACES */}
      {/* <Card className="my-2">
        <Card.Header>
          <Row>
            <Col xs={6}>
              <h5>
                Places
                <HelpDialog title="Places">
                  These are the places you have saved to use for your pages and ribbons.
                </HelpDialog>
              </h5>
            </Col>
            <Col xs={6} className="d-flex justify-content-end">
              <Button
                style={{whiteSpace: "nowrap", height: "43px"}}
                disabled={userdata.activities.length === 0}
                variant="primary"
                onClick={() => (window.location.href = "/map")}>
                New Place
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Card.Text>Current Places:</Card.Text>
          <Card.Text>
            {userdata.places.map((place, index) => {
              return (
      
                  <li key={index}>{place.name}</li>
              );
            })}
          </Card.Text>
        </Card.Body>
      </Card> */}

      {/* ACTIVITIES */}
      {/* <Card
        border={userdata?.activities.length === 0 ? "danger" : ""}
        className="my-2">
        <Card.Header>
          <Row>
            <Col style={{whiteSpace: "nowrap"}} xs={6}>
              <h5>
                <HelpDialog title="Activities">
                  Activities define your ideal weather parameters for an
                  activity. These parameters are used to make Score Ribbons to
                  watch for the best weather.
                </HelpDialog>
                Activities
              </h5>
            </Col>
            <Col xs={6} className="d-flex justify-content-end">
              <Button
                style={{whiteSpace: "nowrap"}}
                className="m-2"
                variant="primary"
                onClick={() => {
                  setShowNewActivity(true);
                }}>
                New Activity
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {userdata?.activities.length === 0 && (
            <Alert variant="danger">
              " Please select at least one activity."
            </Alert>
          )}
          <Card.Text>Make a new Score Ribbon to calculate </Card.Text>
          <div className="d-flex flex-wrap">
            {allPossibleActivityList
              .filter((a) => a.public)
              .map((activity) => {
                const selected =
                  userdata.activities.findIndex(
                    (a) => activity.slug === a.slug
                  ) >= 0;
                return (
                  <ToggleButtonWithDropdown
                    key={activity.slug}
                    slug={activity.slug}
                    onEdit={(e) => onClone(activity)}
                    selected={selected}
                    onClick={(e) => toggleSelect(activity)}>
                    {activity.name}
                  </ToggleButtonWithDropdown>
                );
              })}
          </div>
          <hr />
          <Card.Text></Card.Text>
          {allPossibleActivityList
            .filter((a) => !a.public)
            .map((activity) => {
              const selected =
                userdata.activities.findIndex(
                  (a) => activity.slug === a.slug
                ) >= 0;

              return (
                <ToggleButtonWithDropdown
                  key={activity.slug}
                  slug={activity.slug}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  selected={selected}
                  onClick={(e) => toggleSelect(activity)}>
                  {activity.name}
                </ToggleButtonWithDropdown>
              );
            })}
        </Card.Body>
      </Card> */}
      
      <Card className="my-2">
        <Card.Header>
          <Row>
            <Col style={{whiteSpace: "nowrap"}} xs={12}>
              <h5>
                <HelpDialog title="Mailing List">
                  You can subscribe or unsubscribe to our low traffic (~monthly) mailing list to get updates about new features and other news.

                </HelpDialog>
                Mailing List
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Card.Text className="px-2">
            You can subscribe or unsubscribe <b>{user?.email}</b> to our low traffic (~monthly) mailing list.
          </Card.Text>
          <Row>
          <Col xs={6} className="d-flex justify-content-end">
            <Button
              style={{whiteSpace: "nowrap"}}
              className="m-2"
              variant="outline-primary"
              onClick={() => {
                handleMailingListSubscribe()
              }}>
              Subscibe
            </Button>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <Button
              style={{whiteSpace: "nowrap"}}
              className="m-2"
              variant="outline-primary"
              onClick={() => {
                handleMailingListUnsubscribe()
              }}>
              Unsubscribe
            </Button>
          </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {message && <p className={success?"text-success": "text-danger"}>{message}</p>}
            </Col>
          </Row>
        </Card.Body>
      </Card>


      <Card className="my-2">
        <Card.Header>
          <Row>
            <Col style={{whiteSpace: "nowrap"}} xs={12}>
              <h5>
                <HelpDialog title="Home Screen">
                  <p>
                    To learn how to add Get Out on the home screen of your phone
                    (which we recommend!), see
                    <a href="https://www.brandeis.edu/its/support/website-shortcut.html">
                      {" "}
                      this guide from Brandeis University
                    </a>{" "}
                     about adding a web site to your homescreen for
                     both Android and iPhone
                  </p>
                  If you have installed Get Out Weather to your home screen, you
                  may need to reload the app at times to get the latest updates.
                  The reload button bellow does this. It is identical to 
                  refreshing your browser window.
                </HelpDialog>
                Add to Home Screen <FontAwesomeIcon icon={faMobileScreen} className="ms-2"/>
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Card.Text className="px-2">
            To learn how to add Get Out on the home screen of your phone (which
            we recommend!), see
            <a href="https://www.brandeis.edu/its/support/website-shortcut.html">
              {" "}
              this guide from Brandeis University
            </a>{" "}
            about adding a web site to your homescreen for
            both Android and iPhone.
          </Card.Text>
          <Card.Text className="pt-2 px-2">
            If you have installed Get Out Weather to your phone's home screen,
            you may need to reload the app at times to get the latest updates.
            The Reload button bellow does this. It is identical to refreshing
            your web browser.
          </Card.Text>
          <Col xs={12} className="d-flex justify-content-end">
            <Button
              style={{whiteSpace: "nowrap"}}
              className="m-2"
              variant="primary"
              onClick={() => {
                window.location.reload();
              }}>
              Reload
            </Button>
          </Col>
        </Card.Body>
      </Card>

      {/* PLACED ACTIVITY SETS */}

      {/* <PlacedActivitySetSorter /> */}
    </div>
  );
};
