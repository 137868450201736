import * as d3 from "d3";

import useProcessedWeather from "hooks/useProcessedWeather";
import {
  draw_time_axes_tz,
  draw_yaxes,
  xScaleHourWidth,
} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import {isUndefined, round} from "lodash";

export const SnowfallRibbon = (props) => {
  const {data} = useProcessedWeather(props.currentPlacedActivity.place);

  if (isUndefined(data?.chartdata) || data.chartdata.length === 0) return;
  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    data?.chartdata,
  );

  const draw_snowfall = (g, data, width, height, xScale, ymargin = 20) => {
    //Yscales
    let maxsnowfall = d3.max(data.chartdata, function (d) {
      return d.snowfall;
    });
    const unit =
      data.metadata.units.snowfall == "inch"
        ? '"'
        : data.metadata.units.snowfall;
    // roundup to closest 10
    maxsnowfall = Math.max(maxsnowfall + 0.2, 0.25);

    let minsnowfall = d3.min(data.chartdata, function (d) {
      return d.snowfall;
    });
    //minsnowfall = Math.floor(minsnowfall / 10) * 10 - 5;
    const yScale = d3
      .scaleLinear()
      .domain([minsnowfall, maxsnowfall])
      .range([height, 0])
      .nice();
    draw_yaxes(g, yScale, width, unit, ymargin, "0.2f");
    draw_time_axes_tz(
      g,
      xScale,
      height + 2 * ymargin,
      data.nightblocks,
      false,
      data.metadata.timezone,
    );

    g.append("g")
      .selectAll("rect")
      .data(
        data.chartdata.filter(
          (d, i) => d.snowfall > 0 && i < data.chartdata.length - 1,
        ),
      )
      .join("rect")
      .attr("x", (d) => xScale(d.time))
      .attr("y", (d) => yScale(d.snowfall) + ymargin)
      .attr("width", (width / xScaleHourWidth(xScale)) * 0.65)
      .attr("height", (d) => height - yScale(d.snowfall))
      .attr("class", "snowfall");

    // sum up total of all snowfall per day

    let snowfallperday = {};
    data.chartdata.forEach((d) => {
      const day = d.datetime.day;
      if (snowfallperday[day] === undefined) {
        snowfallperday[day] = {total: d.snowfall, time: d.time};
      } else {
        snowfallperday[day] = {
          ...snowfallperday[day],
          total: snowfallperday[day].total + d.snowfall,
        };
      }
    });

    snowfallperday = Object.values(snowfallperday);
    // show totals
    g.append("g")
      .selectAll("rect")
      .data(snowfallperday.filter((d) => d.total > 0))
      .join("rect")
      .attr(
        "x",
        (d) => xScale(d.time) + (width / xScaleHourWidth(xScale)) * 0.5,
      )
      .attr("y", 10)
      .attr("width", (width / xScaleHourWidth(xScale)) * 23)
      .attr("height", (d) => 20)
      .attr("class", "snowfall-total");

    g.append("g")
      .selectAll("text")
      .data(snowfallperday.filter((d) => d.total > 0))
      .join("text")
      .attr("x", (d) => xScale(d.time) + (width / xScaleHourWidth(xScale)) * 12)
      .attr("y", 26)
      .style("text-anchor", "middle")
      .text((d) => round(d.total, 1) + " " + unit)
      .attr("class", "snowfall-total-text");

    //  draw_line(g, chartdata, "snowfall", xScale, yScale);
    //draw_marker_text(g, chartdata.filter((d) => d.snowfall > 0), "snowfall", false, xScale, yScale, '"');

    g.selectAll("#amarkertextsnowfall")
      .data(
        data.chartdata
          .filter((d, i) => i % 2 == 0)
          .filter((d) => d.snowfall > 0),
      )
      .join("text")
      .attr("x", (d) => xScale(d.time))
      .attr("y", (d) => yScale(d["snowfall"]) + ymargin - 5)
      .attr("class", "marker-text  snowfall")
      .text((d) => d["snowfall"] + '"')
      .attr("id", "markertextsnowfall");
  };

  let draw_func = (svg) => {
    draw_snowfall(
      svg,
      data,
      props.dims.width,
      props.dims.unitHeight - 40,
      xScale,
    );
  };

  return (
    <div className="ribbon">
      <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
