import * as d3 from "d3";

import useProcessedWeather from "hooks/useProcessedWeather";
import {
  draw_time_axes_tz,
  draw_yaxes,
  xScaleHourWidth,
} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import {isUndefined} from "lodash";
import textures from "textures";
import useStripe from "hooks/useStripe";
import {Alert} from "react-bootstrap";

export const RecentSnowRibbon = (props) => {
  const {data, nwsError} = useProcessedWeather(
    props.currentPlacedActivity.place,
    "forecast",
    true,
  );
  const {isCustomer} = useStripe();

  const draw_error = (g) => {
    const texture = textures.lines()
    .lighter(5);

    g.call(texture);
   
    g.append("rect")
    .attr("x", 0)
    .attr("y", 0)
    .attr("height", props.dims.svgheight)
    .attr("width", props.dims.width)
    .style("fill", texture.url());

    const rect = g.append("rect");
    const text = g
      .append("text")
      .attr("x", 300)
      .attr("y", props.dims.svgheight / 2)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("font-size", "1em")
      .text(nwsError? nwsError : "Please upgrade to premium see snow history");

    const bbox = text.node().getBBox();
    console.log(bbox);
    rect
      .attr("x", bbox.x)
      .attr("y", bbox.y)
      .attr("width", bbox.width)
      .attr("height", bbox.height)
      .style("fill", "white");

 
  };

  if (nwsError) {
    return  (
      <div className="ribbon">
          <Ribbon draw_func={draw_error} dims={props.dims} />
        
      </div>
    );
  }
  if (isUndefined(data?.chartdata) || data.chartdata.length === 0)
  return  (
    <div className="ribbon">
        <Ribbon draw_func={() => {}} dims={props.dims} />
    </div>
  );

  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    data?.chartdata,
  );

  const draw_fresh_snow = (g, data, width, height, xScale, ymargin = 20) => {
    //Yscales
    let maxsnow = d3.max(data.chartdata, function (d) {
      return d.snowhist ? d.snowhist.total72 : 0;
    });
    maxsnow = d3.max([maxsnow, 1])
    // roundup to closest 10

    let minsnow = d3.min(data.chartdata, function (d) {
      return d.snowhist ? d.snowhist.total72 : 0;
    });
    //minsnow = Math.floor(minsnow / 10) * 10 - 5;
    const yScale = d3
      .scaleLinear()
      .domain([minsnow, maxsnow])
      .range([height, 0])
      .nice();

    const texture = textures.lines();

    // const texture = textures.circles()
    // .radius(4)
    // .fill("transparent")
    // .strokeWidth(2);

    // var snowflake = textures.paths()
    // .d(s => {
    //   // s is the size of the pattern
    //   const radius = s / 4;
    //   let path = '';

    //   // Create 6 radial lines for the snowflake
    //   for (let i = 0; i < 6; i++) {
    //     // Angle for each point of the snowflake
    //     let angle = Math.PI / 3 * i;

    //     // Calculate x and y coordinates for the end of each line
    //     let x = radius * Math.cos(angle);
    //     let y = radius * Math.sin(angle);

    //     // Move to the center and draw a line outward
    //     path += `M ${s/2}, ${s/2} l ${x},${y}`;
    //   }

    //   return path;
    // })
    // .size(10)
    // .strokeWidth(0.8)

    g.call(texture);

    draw_time_axes_tz(
      g,
      xScale,
      height + 2 * ymargin,
      data.nightblocks,
      false,
      data.metadata.timezone,
    );
    draw_yaxes(g, yScale, width, '"', ymargin, "0.2f");
    const filtered_data = data.chartdata.filter(
      (d, i) => d.snowhist?.total72 > 0,
    );
    g.append("g")
      .selectAll("rect")
      .data(filtered_data)
      .join("rect")
      .attr("x", (d) => xScale(d.time))
      .attr("y", (d) => yScale(d.snowhist[72]) + ymargin)
      .attr(
        "width",
        (d) => (width / xScaleHourWidth(xScale)) * (d.snowhist.duration - 0.2),
      )
      .attr("height", (d) => height - yScale(d.snowhist[72]))
      //.style('fill', texture.url())
      //.attr("class", "recentsnow");
      .classed("snow72", true);

    g.append("g")
      .selectAll("rect")
      .data(filtered_data)
      .join("rect")
      .attr("x", (d) => xScale(d.time))
      .attr("y", (d) => yScale(d.snowhist[48] + d.snowhist[72]) + ymargin)
      .attr(
        "width",
        (d) => (width / xScaleHourWidth(xScale)) * (d.snowhist.duration - 0.2),
      )
      .attr("height", (d) => height - yScale(d.snowhist[48]))
      //.style('fill', texture.url())
      //.attr("class", "recentsnow");
      .classed("snow48", true);

    g.append("g")
      .selectAll("rect")
      .data(filtered_data)
      .join("rect")
      .attr("x", (d) => xScale(d.time))
      .attr(
        "y",
        (d) =>
          yScale(d.snowhist[24] + d.snowhist[48] + d.snowhist[72]) + ymargin,
      )
      .attr(
        "width",
        (d) => (width / xScaleHourWidth(xScale)) * (d.snowhist.duration - 0.2),
      )
      .attr("height", (d) => height - yScale(d.snowhist[24]))
      //.style('fill', texture.url())
      //.attr("class", "recentsnow");
      .classed("snow24", true);

    g.selectAll("#markertextrecentsnow")
      .data(filtered_data)
      .join("text")
      .attr(
        "x",
        (d) =>
          xScale(d.time) +
          (width / xScaleHourWidth(xScale)) * (d.snowhist.duration * 0.5),
      )
      .attr("y", (d) => yScale(d.snowhist?.total72) + ymargin - 5)
      .attr("class", "marker-text")
      .text((d) => d.snowhist?.total72 + '"')
      .attr("id", "markertextrecentsnow");
  };

  let draw_func = (svg) => {
    draw_fresh_snow(
      svg,
      data,
      props.dims.width,
      props.dims.unitHeight - 40,
      xScale,
    );
  };

  return (
    <div className="ribbon">
        <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
