import {useState} from "react";
import {Range} from "react-range";
import {Card, Row, Col, Dropdown, Form} from "react-bootstrap";
import {isUndefined} from "lodash";

const MultiHandleSlider = ({
  feature,
  title,
  units,
  values,
  step,
  weight,
  absoluteMin,
  absoluteMax,
  commonMin,
  commonMax,
  onChange,
}) => {
  const enableCommon = isUndefined(values.ideal)
    ? commonMin <= values.min &&
      values.min <= commonMax &&
      commonMin <= values.idealmin &&
      values.idealmin <= commonMax &&
      commonMin <= values.idealmax &&
      values.idealmax <= commonMax &&
      commonMin <= values.max &&
      values.max <= commonMax
    : commonMin <= values.min &&
      values.min <= commonMax &&
      commonMin <= values.ideal &&
      values.ideal <= commonMax &&
      commonMin <= values.max &&
      values.max <= commonMax;

  const [useCommon, setUseCommon] = useState(enableCommon);
  if (isUndefined(values)) return;

  const handleDropdownSelect = (eventKey) => {
    onChange({
      feature: feature,
      values: values,
      weight: parseInt(eventKey),
    });
  };

  const handleSliderChange = (notideal, newValues) => {
    let min, max, idealmin, idealmax, ideal;
    if (notideal === 1) {
      [min, max] = newValues;
      idealmin = values.idealmin;
      idealmax = values.idealmax;
      ideal = values.ideal;
    } else {
      isUndefined(values.ideal)
        ? ([idealmin, idealmax] = newValues)
        : ([ideal] = newValues);
      min = values.min;
      max = values.max;
    }
    if (isUndefined(values.ideal)) {
      // Check that the handles don't cross each other
      if (min <= idealmin && idealmin <= idealmax && idealmax <= max) {
        onChange({
          feature: feature,
          values: {min: min, idealmin: idealmin, idealmax: idealmax, max: max},
          weight: weight,
        });
      }
    } else {
      // Check that the handles don't cross each other
      if (min <= ideal && ideal <= max) {
        onChange({
          feature: feature,
          values: {min: min, ideal: ideal, max: max},
          weight: weight,
        });
      }
    }
  };

  const getHandleColor = (notideal) => {
    if (weight === 0) return "grey";
    else if (weight === 1) {
      return notideal ? "#8b0000" : "#00008b";
    } else {
      return notideal ? "#dd0000" : "#0000dd";
    }
  };

  const getStyle = (notideal, index) => {
    const thumbsize = "10mm";
    return {
      width: thumbsize,
      height: thumbsize,
      border: "1px solid white",
      borderRadius: "50%",
      boxShadow: "0px 2px 6px #aaa",
      touchAction: "none",
      backgroundColor: getHandleColor(notideal),
    };
  };

  const splitvalues = [
    isUndefined(values.ideal)
      ? [values.idealmin, values.idealmax]
      : [values.ideal],
    [values.min, values.max],
  ];

  const needRangeChoice =
    absoluteMin !== commonMin || absoluteMax !== commonMax;
  return (
    <Card className="my-1 editor-card">
      <Card.Header>
        <Row>
          <Col key={0} xs={6}>
            <h5 className="mt-1">
              <b>
                {title} ({units})
              </b>
            </h5>
          </Col>
          <Col key={1} xs={6}>
            <Dropdown
              className="float-end"
              align="end"
              onSelect={handleDropdownSelect}>
              <Dropdown.Toggle
                key={"toggle"}
                variant="outline-secondary"
                id="dropdown-weight">
                {["Not", "", "Very"][weight]} Important
              </Dropdown.Toggle>
              <Dropdown.Menu key={"menu"}>
                <Dropdown.Item key={0} className="text-end" eventKey={0}>
                  Not Important
                </Dropdown.Item>
                <Dropdown.Item key={1} className="text-end" eventKey={1}>
                  Important
                </Dropdown.Item>
                <Dropdown.Item key={2} className="text-end" eventKey={2}>
                  Very Important
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Card.Header>
      {weight > 0 && (
        <Card.Body>
          {needRangeChoice && (
            <Row className="mb-3">
              <Col xs={12}>
                <Form.Check
                  key={'normal-range'}
                  inline
                  checked={useCommon}
                  disabled={!enableCommon}
                  onChange={(v) => setUseCommon(v)}
                  type="radio"
                  label={`Normal Range (${commonMin}-${commonMax})`}
                />
                <Form.Check
                  key={'wide-range'}
                  inline
                  checked={!useCommon}
                  onChange={(v) => setUseCommon(!v)}
                  type="radio"
                  label={`Wide Range (${absoluteMin}-${absoluteMax})`}
                />
              </Col>
            </Row>
          )}
          <Row className="mb-3">
            <Col>
              <div className="d-flex justify-content-between">
                <div>
                  Min
                  <br />
                  <b>{values.min}</b>
                </div>
                {isUndefined(values.ideal) && (
                  <>
                    <div>
                      Ideal Min
                      <br />
                      <b>{values.idealmin}</b>
                    </div>
                    <div>
                      Ideal Max
                      <br />
                      <b>{values.idealmax}</b>
                    </div>
                  </>
                )}
                {!isUndefined(values.ideal) && (
                  <div>
                    Ideal
                    <br />
                    <b>{values.ideal}</b>
                  </div>
                )}
                <div>
                  Max
                  <br />
                  <b>{values.max}</b>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {splitvalues.map((pair, notideal) => {
                return (
                  <Range
                    key={"range"+notideal}
                    draggableTrack
                    disabled={weight === 0}
                    values={pair}
                    step={step}
                    min={useCommon ? commonMin : absoluteMin}
                    max={useCommon ? commonMax : absoluteMax}
                    onChange={(pair) => handleSliderChange(notideal, pair)}
                    renderTrack={({props, children}) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "6px",
                          display: "flex",
                          width: "100%",
                          backgroundColor: "#ddd",
                          borderRadius: "4px",
                          marginTop: "10mm",
                          marginBottom: "10mm",
                        }}>
                        {children}
                      </div>
                    )}
                    renderThumb={({index, props}) => (
                      <div
                        {...props}
                        style={{...props.style, ...getStyle(notideal, index)}}
                      />
                    )}
                  />
                );
              })}
            </Col>
          </Row>
        </Card.Body>
      )}
    </Card>
  );
};

export default MultiHandleSlider;
