// useRibbon hook for ribbon end point of backend

import useSWR from "swr";

import {api_get, RIBBON_PATH} from "api/backend";

function useRibbons() {
    const url = `${RIBBON_PATH}`;
    const {
        data,
        error,
        mutate,
    } = useSWR(url, api_get);
    
    return {
        ribbons: data?.results,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
    }

export default useRibbons;
