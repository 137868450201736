import {useEffect, useState} from "react";

import {
  Route,
  Routes,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";

import {Footer} from "components/Footer";
import PrivateRoute from "components/PrivateRoute";
import TopNav from "components/TopNav";
import {AllRibbons} from "components/ribbons/allribbons";
import useUserData from "hooks/useUserData";
import {About} from "pages/About";

import {PageEditor} from "pages/PageEditor";
import {Privacy} from "pages/Privacy";
import {Profile} from "pages/Profile";
import {Register} from "pages/Register";
import {Terms} from "pages/Terms";
import {Button, Modal} from "react-bootstrap";
import {ActivityEditor} from "./ActivityEditor";
import Map from "./Map";
import {PricingTable} from "./PricingTable";
import {RibbonAdder} from "./RibbonAdder";
import {AllPages} from "./AllPages";
import {WorkPricing} from "./WorkPricing";
import ChatCreateDialog from "components/ChatCreateDialog";
/**
 * Routes object that contains all other components
 *
 * important jobs are routing and loading state to direct the user
 * to the correct URL based on localStorage and the URL
 */

const RootRedirect = () => {
  const navigate = useNavigate();
  const {userdata, isLoggedIn} = useUserData();

  useEffect(() => {
    if (userdata) {
      if (isLoggedIn() && userdata?.pages.length > 0) {
        navigate("/page/view/" + userdata.pages[0].slug);
      } else {
        navigate("/page/view/default");
      }
    }
  }, [userdata, isLoggedIn, navigate]);

  return <div></div>;
};

const Root = () => {
  const [viewDims, setViewDims] = useState({
    width: window.visualViewport.width,
    height: window.visualViewport.height,
  });

  const location = useLocation();
  const path = location.pathname;

  // Setup current location - to be replaced with API calls
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSubscribeSuccess, setShowSubscribeSuccess] = useState(false);
  const [showSubscribeCancelled, setShowSubscribeCancelled] = useState(false);
  const [showChatDialog, setShowChatDialog] = useState(false);

  useEffect(() => {
    if (searchParams.has("subscribe")) {
      const subscribe_status = searchParams.get("subscribe");
      if (subscribe_status === "success") {
        setShowSubscribeSuccess(true);
      }
      if (subscribe_status === "cancelled") {
        setShowSubscribeCancelled(true);
      }
      searchParams.delete("subscribe");
      setSearchParams(searchParams);
    }
    // const chat_status = searchParams.get("chat");
    // if (chat_status === "true") {
    //   console.log("showing chat dialog");
    //   setShowChatDialog(true);
    // }
  }, [searchParams, setSearchParams]);

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.visualViewport.height * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  useEffect(() => {
    // We also listen to the resize event
    const onRotate = () => {
      window.scrollTo({top: 0, behavior: "instant"});
    };
    const onResize = () => {
      // We execute the same script as before
      let vh = window.visualViewport.height * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      setViewDims({
        width: Math.min(window.innerWidth, window.visualViewport.width),
        height: Math.min(window.innerHeight, window.visualViewport.height),
      });
    };
    // this is to prevent pinch zoom on mobile which breaks topnav

    const onPinch = (event) => {
      if (event.scale !== 1) {
        event.preventDefault();
      }
    };

    const onWheel = (event) => {
      const { ctrlKey } = event
      if (ctrlKey) {
         event.preventDefault();
         return
      }
   }
    window.addEventListener('wheel', onWheel, { passive: false });
    window.addEventListener("touchmove", onPinch, {passive: false});
    window.addEventListener("orientationchange", onRotate);
    window.addEventListener("resize", onResize);
    return (_) => {
      window.removeEventListener("wheel", onWheel);
      window.removeEventListener("orientationchange", onRotate);
      window.removeEventListener("resize", onResize);
      window.removeEventListener("touchmove", onPinch);
    };
  }, []);

  return (
    <main>
      <TopNav width={viewDims.width} />

      <Modal
        style={{width: viewDims.width}}
        show={showSubscribeCancelled}
        onHide={() => setShowSubscribeCancelled(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Subciption Incomplete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Subsciption was not completed. You can return to the{" "}
          <a href="/pricing/">pricing</a> page if this was a mistake.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSubscribeCancelled(false)}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        style={{width: viewDims.width}}
        show={showSubscribeSuccess}
        onHide={() => setShowSubscribeSuccess(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Welcome!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Thank you <i>very</i> much for subscribing! You now have access to
          unlimited pages and premium ribbons!
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSubscribeSuccess(false)}>
            Let's Go!
          </Button>
        </Modal.Footer>
      </Modal>



      <Routes>
        <Route
          exact
          path="/page/view/:pageslug"
          element={<AllRibbons viewDims={viewDims} />}
        />

        <Route exact path="/" element={<RootRedirect />} />

        {["/map"].map((path) => {
          return <Route key={path} exact path={path} element={<Map />} />;
        })}
        <Route
          exact
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/page/new/addribbon"
          element={
            <PrivateRoute>
              <RibbonAdder />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/page/prompt"
          element={
            <PrivateRoute requireBeta>
              
              <ChatCreateDialog
                showOnLoad={true}
                />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/page/new"
          element={
            <PrivateRoute>
              <PageEditor newPage />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/page/edit/:slug/addribbon"
          element={
            <PrivateRoute>
              <RibbonAdder />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/page/edit/:slug"
          element={
            <PrivateRoute>
              <PageEditor />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/page/all"
          element={
            <PrivateRoute>
              <AllPages />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/score/edit/:activityslug"
          element={
            <PrivateRoute>
              <ActivityEditor />
            </PrivateRoute>
          }
        />

        {/* <Route exact path="/start" element={<Landing />} /> */}
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/pricing" element={<PricingTable />} />
        <Route exact path="/forwork" element={<WorkPricing />} />
        <Route exact path="/about" element={<About />} />

        <Route exact path="/register" element={<Register />} />

        <Route
          exact
          path="/login"
          element={
            <PrivateRoute>
              <RootRedirect />
            </PrivateRoute>
          }
        />
        <Route exact key="*" path="*" element={<div>404!</div>} />
      </Routes>
      {!path.startsWith("map") && <Footer viewDims={viewDims} />}
    </main>
  );
};

export default Root;
