import useProcessedWeather from "hooks/useProcessedWeather";
import {RibbonLabel} from "./ribbonlabel";

export const TempRibbonLabel = (props) => {
  const {data} = useProcessedWeather(props.currentPlacedActivity.place);

  // if (isUndefined(data?.chartdata) || data.chartdata.length === 0) return;

  const temp_types = data.temp_types
    ? data.temp_types?.filter((n) => n !== "dewpoint")
    : ["temp"];

  const label_lookup = {
    temp: "Temperature",
    windchill: "Wind Chill",
    heatindex: "Heat Index",
    atemp: "Apparent Temperature",
  };
  return (
    <RibbonLabel dims={props.dims}>
      {props.prependLabel && (
        <span className="legend-place legend-text">{props.prependLabel}</span>
      )}
      {temp_types?.map((t) => {
        return (
          <span key={t} className={"legend-text " + t}>
            {label_lookup[t]}
          </span>
        );
      })}
    </RibbonLabel>
  );
};
