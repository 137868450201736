import useSWR from "swr";

import {api_get, ASTRO_PATH} from "api/backend";
import {isUndefined} from "lodash";
import {DateTime} from "luxon";

const useAstro = (place, weatherdata) => {
  const {data: astroData, error, mutate} = useSWR(
    place?.lat && place?.lon && weatherdata?.chartdata?.length > 0
      ? `${ASTRO_PATH}?lat=${place.lat}&lon=${place.lon}&elevation=${weatherdata?.metadata?.elevation.value}&start=${weatherdata?.chartdata[0].utctimestr}&hours=${weatherdata?.chartdata.length}`
      : null,
    api_get,
  );
  const newData = {...weatherdata};

  newData.metadata = {...newData.metadata, ...astroData?.metadata};

  newData?.chartdata &&
  astroData?.chartdata &&
  newData.chartdata.forEach((record, i) => {
    newData.chartdata[i] = {...record, astro: astroData.chartdata[record.utctimestr]}    
  });

  return {data: newData, error, mutate};
};

export default useAstro;
