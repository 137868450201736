import {useEffect, useState} from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import {Popup, CircleMarker, useMapEvents} from "react-leaflet";
import {Row} from "react-bootstrap";

import useUserData from "hooks/useUserData";
import {useNavigate, useLocation} from "react-router-dom";
import {isUndefined, isEmpty} from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import useLocationState from "hooks/useLocationState";

export const PlaceMarkers = ({curPlace}) => {
  const {userdata, updateUserData} = useUserData();
  const [radius, setRadius] = useState(0);
  const [lineWeight, setLineWeight] = useState(1);
  const navigate = useNavigate();
  // const location = useLocation();
  const {setPlace, hasCallback, getPlace, navToCallback} = useLocationState()
  // const {
  //   datasources,
  //   isLoading: isLoadingDatasources,
  //   attachDataSource,
  //   clearDataSources,
  // } = useDataSources(place);

  const setRadiusFromZoom = (zoom) => {
    return setRadius(Math.min(15, Math.max(0, (parseInt(zoom) - 2) * 1.5)));
  };

  const map = useMapEvents({
    zoomend(e) {
      setRadiusFromZoom(e.target._zoom);
      setLineWeight(parseInt(e.target._zoom) / 5.0);
    },
  });

  const removePlace = (input_place) => {
    if (userdata)
      userdata.places = userdata.places.filter(
        (p) => p.slug !== input_place.slug
      );

    // remove place from current url
    updateUserData(userdata);
  };

  // const deletePlacedActivity = async (deletepa) => {
  //   if (userdata.placedactivitysets[0].placedactivities) {
  //     userdata.placedactivitysets[0].placedactivities = userdata.placedactivitysets[0].placedactivities.filter(
  //       (pa) => pa.slug !== deletepa.slug
  //     );
  //     updateUserData(userdata);
  //   }
  //   updatePath("map", "", "");
  // };

  if (userdata && isEmpty(userdata?.places)) {
    userdata.places = []
  }
  const placesToShow =
    curPlace && !userdata?.places.includes(curPlace)
      ? [...userdata?.places, curPlace]
      : userdata?.places;

  // const [popupRef, setPopupRef] = useState({});

  useEffect(() => {
    setRadiusFromZoom(map.getZoom());
  }, [map]);

  // use effect to open the popup
  // useEffect(() => {
  //   if (popupRef) {
  //     map.openPopup(popupRef);
  //   }
  // }, [map, popupRef]);

  // const hidePopup = () => {
  //   if (!popupRef?.current) return;
  //   setPopupRef(undefined);
  //   if (!map) return;
  //   map.closePopup();
  // };

  // handles the situation where we are on the map
  // from another page where we wanted to select a place
  // in this case, we are selecting from a predefined place
  const handleSelectPlace = (selected_place) => {
    setPlace(selected_place)
    // const callback = location.state?.callback;
    // const pathToPlace = location.state?.pathToPlace;
    // const pageData = location.state?.pageData;
    // if (pageData) {
    //   pageData[pathToPlace] = selected_place;
    //   pageData.tracking = false;
    // }
    navToCallback()
    // if (callback) {
    //   navigate(callback, {state: {pageData: pageData}});
    // }
  };

  return placesToShow?.map((place, i) => {
    // search userdata.page and userdata.pages.memberribbons for any links to this same place
    // if found, save to an array to use to make a list of links in the popup
    const linkedPages = [];
    if (place.slug) {
      userdata?.pages?.forEach((page) => {
        if (page.place && page.place.slug === place.slug) {
          linkedPages.push(page);
        } else if (page.memberribbons) {
          page.memberribbons.forEach((ribbon) => {
            if (ribbon.place && ribbon.place.slug === place.slug) {
              linkedPages.push(page);
              // break out of forEach
              return false;
            }
          });
        }
      });
    } else {
      // this is current location place, so search for tracking pages
      userdata?.pages?.forEach((page) => {
        if (page.tracking) {
          linkedPages.push(page);
        }
      });
    }

    if (place === undefined) return {};
    // add if for in-between states when places have been deleted but not the PA yet
    const isSelected =
      (place && isUndefined(place?.slug)) || curPlace?.slug === place.slug;
    const color = isSelected ? "red" : "orange";
    const opacity = isSelected ? 0.7 : 0.4;
    const strokecolor = isSelected ? "green" : "green";
    const useRadius = isSelected ? radius : radius / 2;

    return (
      <CircleMarker
        key={i}
        center={{lat: place.lat, lon: place.lon}}
        pathOptions={{
          fillColor: color,
          color: strokecolor,
          weight: lineWeight,
          fillOpacity: opacity,
          opacity: 1,
        }}
        radius={useRadius}
        // eventHandlers={{
        //   click: (e) => {
        //     updatePath(undefined, pa.activity.slug, pa.place.slug);
        //     //e.originalEvent.view.L.DomEvent.stopPropagation(e);
        //   },
        // }}
      >
        <Popup
          autoPan={true}
          // maxWidth={dims.width + 50}
          minWidth={280}
          // ref={(r) => {
          //   setPopupRef(r);
          // }}
          >
          <h3>{place.name}</h3>
          <Container>
            <Row>
              {hasCallback() && (
                <Col xs={12}>
                  {isSelected ? (
                    <h5>
                      <span>
                        <FontAwesomeIcon icon={faLocationDot} /> Current
                        Selection
                      </span>
                    </h5>
                  ) : (
                    <Button
                      size="sm"
                      variant={"primary"}
                      onClick={(e) => {
                        handleSelectPlace(place);
                      }}>
                      Select
                    </Button>
                  )}
                </Col>
              )}

              {!hasCallback() && (
                <>
                  <Col xs={6}>
                    {linkedPages.length === 0 && (
                      <Dropdown>
                        <Dropdown.Toggle size="sm" variant="outline-secondary">
                          Edit
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                              removePlace(place);
                              e.stopPropagation();
                            }}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </Col>
                  <Col xs={6}>
                  {linkedPages.length > 0 ? (
                    <Dropdown>
                      <Dropdown.Toggle size="sm" variant="outline-secondary">
                        Go To Page
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {linkedPages.map((page) => (
                          <Dropdown.Item
                            key={page.slug}
                            onClick={(e) => {
                              navigate("/page/view/" + page.slug);
                              e.stopPropagation();
                            }}>
                            {page.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                  <p>No Pages Associated with this place.</p>
                    )}
                  </Col>
                </>
              )}
            </Row>
          </Container>
        </Popup>
      </CircleMarker>
    );
  });
};
