import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useMediaQuery} from "react-responsive";
import {useNavigate} from "react-router-dom";

import useLogin from "hooks/useLogin";
import logo from "img/logo.svg";
import {useLocation} from "react-router-dom";
import useStripe from "hooks/useStripe";
import {isEmpty} from "lodash";
import {useState} from "react";
import usePage from "hooks/usePage";
import {toast} from "react-hot-toast";

import {
  faLocationDot,
  faLocationArrow,
  faPencilAlt,
  faBarsProgress,
  faPlusCircle,
  faMap,
  faCrown,
  faUser,
  faPersonRunning,
  faCoins,
  faArrowRight,
  faShare,
  faHeart,
  faToolbox,
  faHeartCirclePlus,
  faWandSparkles,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// const ForMobile = ({children}) => {
//   const isMobile = useMediaQuery({maxWidth: 767});
//   return isMobile ? children : null;
// };

// const ForGoAndMapPage = ({children}) => {
//   const location = useLocation();
//   const root_path =  location.pathname.split("/").replace(/\/$/, "")[0]
//   const isRightPage = root_path === "go" || root_path === "map";
//   return isRightPage ? children : null;
// };

const WrapForMobile = ({wrapper, children}) => {
  const isMobile = useMediaQuery({maxWidth: 767});
  return isMobile ? wrapper(children) : children;
};

const WelcomeMenu = ({isMobile}) => {
  const navigate = useNavigate();
  return (
    <Nav key="welcome-menu" className={isMobile ? "me-auto" : "ms-auto"}>
      <NavDropdown
        align="end"
        menuVariant="dark"
        title="Welcome"
        show={isMobile ? true : undefined}>
        <NavDropdown.Item
          href="#"
          key="sign up"
          onClick={() => navigate("/register")}>
          <FontAwesomeIcon className="me-2" icon={faArrowRight} />
          Register for Free
        </NavDropdown.Item>
        <NavDropdown.Item
          href="#"
          key="about"
          onClick={() => {
            navigate("/about");
          }}>
          <FontAwesomeIcon className="me-2" icon={faPersonRunning} />
          About Get Out
        </NavDropdown.Item>
        <NavDropdown.Item
          href="#"
          key="newpage"
          onClick={() => {
            navigate("/page/new");
          }}>
          <FontAwesomeIcon className="me-2" icon={faPencilAlt} />
          Customize Page
        </NavDropdown.Item>
        <NavDropdown.Item
          href="#"
          key="pricing"
          // active={true}
          onClick={() => {
            navigate("/pricing");
          }}>
          <FontAwesomeIcon className="me-2" icon={faCoins} />
          Pricing
        </NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
};

const PagesMenu = ({user, userdata, isMobile, currentPage}) => {
  const navigate = useNavigate();
  const {isCustomer} = useStripe();

  //if not customer slide to only first item
  const toShow = isCustomer() ? userdata?.pages : userdata?.pages.slice(0, 1);
  return (
    <>
      <Nav key="pages-menu" className={isMobile ? "me-auto" : "ms-auto"}>
        <NavDropdown
          align="end"
          menuVariant="dark"
          title={
            <span>
              <FontAwesomeIcon className={"me-1"} icon={faHeart} />
              Pages
            </span>
          }
          show={isMobile ? true : undefined}>
          {isEmpty(toShow) ? (
            <NavDropdown.Item
              href="#"
              key="newpage"
              // active={true}
              onClick={() => {
                navigate("/page/new");
              }}>
              <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
              Create First Page!
            </NavDropdown.Item>
          ) : (
            toShow?.map((page, i) => (
              <NavDropdown.Item
                href="#"
                key={i}
                active={currentPage?.slug === page.slug} // TODO: this is broken
                onClick={() => navigate(`/page/view/${page.slug}`)}>
                {page.tracking ? (
                  <FontAwesomeIcon className="me-2" icon={faLocationArrow} />
                ) : (
                  <FontAwesomeIcon className="me-2" icon={faLocationDot} />
                )}
                {page.name}
              </NavDropdown.Item>
            ))
          )}
          {!isCustomer() && (
            <>
              <NavDropdown.Divider />
              <NavDropdown.Item
                href="#"
                key={"__upgrade"}
                onClick={() => navigate(`/pricing`)}>
                <FontAwesomeIcon className="me-2" icon={faCrown} />
                Get Unlimited Pages
              </NavDropdown.Item>
            </>
          )}
        </NavDropdown>
      </Nav>
      {/* <Nav key="action-menu" className={isMobile ? "me-auto" : ""}>
        <NavDropdown
          menuVariant="dark"
          title="Actions"
          show={isMobile ? true : undefined}>
         
        </NavDropdown>
      </Nav> */}
    </>
  );
};

const ManageMenu = ({
  user,
  userdata,
  isMobile,
  currentPage,
  updateUserData,
}) => {
  const navigate = useNavigate();
  const {isCustomer} = useStripe();

  const isBeta = userdata?.usergroups && userdata?.usergroups.includes("beta");

  // set to true if the currentpage is not in userdata.pages
  const pageIsNewToUser =
    currentPage?.slug &&
    isEmpty(userdata?.pages?.filter((page) => page.slug === currentPage?.slug));
  // function to add this page to the user's pages
  const addPageToUser = () => {
    const newPages = [...userdata?.pages, currentPage];
    const newUserData = {...userdata, pages: newPages};
    updateUserData(newUserData);
  };

  const sharePage = () => {
    if (navigator.share) {
      navigator
        .share({
          // Title that occurs over
          // web share dialog
          title: currentPage.name,
          // URL to share
          url: window.location.href,
        })
        .then(() => {
          toast("Thanks for sharing!", {icon: "🙏", duration: 1000});
        })
        .catch((err) => {
          // Handle errors, if occurred
          toast.error("Error while using Web share API");
        });
    } else {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          toast.success("Copied to clipboard", {duration: 1000});
        })
        .catch(() => {
          toast.error(
            "Error while copying to clipboard, after failing to share.",
          );
        });
    }
  };
  return (
    <Nav key="manage-menu" className={isMobile ? "me-auto" : ""}>
      <NavDropdown
        align="end"
        menuVariant="dark"
        title={
          <span>
            <FontAwesomeIcon className={"me-1"} icon={faToolbox} />
            Tools
          </span>
        }
        show={isMobile ? true : undefined}>
        {currentPage?.slug && (
          <>
            {pageIsNewToUser && (
              <NavDropdown.Item
                href="#"
                key="addpagetouser"
                // active={true}
                // disabled = {}  // TODO: disable when the user does not have permission to edit
                onClick={() => {
                  addPageToUser();
                }}>
                <FontAwesomeIcon className="me-2" icon={faHeartCirclePlus} />
                Save to Pages
              </NavDropdown.Item>
            )}
            <NavDropdown.Item
              href="#"
              key="sharepage"
              // disable when page not shareable
              disabled={!currentPage?.public}
              onClick={() => {
                sharePage();
              }}>
              <FontAwesomeIcon className="me-2" icon={faShare} />
              Share
            </NavDropdown.Item>

            <NavDropdown.Item
              href="#"
              key="editpage"
              // disabled={user.id !== currentPage?.owner}
              onClick={() => {
                navigate("/page/edit/" + currentPage?.slug);
              }}>
              <FontAwesomeIcon className="me-2" icon={faPencilAlt} />
              {user.id == currentPage?.owner ? "Edit" : "Edit"}
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </>
        )}
        {isBeta && (
          <NavDropdown.Item
            href="#"
            key="chatcreate"
            // active={true}
            onClick={() => {
              navigate("/page/prompt");
            }}>
            <FontAwesomeIcon className="me-2" icon={faWandSparkles} />
            Prompt New Page
          </NavDropdown.Item>
        )}

        {(isCustomer() || isEmpty(userdata?.pages)) && (
          <NavDropdown.Item
            href="#"
            key="newpage"
            // active={true}
            onClick={() => {
              navigate("/page/new");
            }}>
            <FontAwesomeIcon className="me-2" icon={faPlusCircle} />
            New Page
          </NavDropdown.Item>
        )}

        <NavDropdown.Item
          href="#"
          key="allpages"
          // active={true}
          // disabled = {}  // TODO: disable when the user does not have permission to edit
          onClick={() => {
            navigate("/page/all");
          }}>
          <FontAwesomeIcon className="me-2" icon={faBarsProgress} />
          Manage All Pages
        </NavDropdown.Item>

        <NavDropdown.Item
          href="#"
          key="map"
          // active={true}
          // disabled = {}  // TODO: disable when the user does not have permission to edit
          onClick={() => {
            navigate("/map");
          }}>
          <FontAwesomeIcon className="me-2" icon={faMap} />
          View Map
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          href="#"
          key="about"
          // active={true}
          // disabled = {}  // TODO: disable when the user does not have permission to edit
          onClick={() => {
            navigate("/about");
          }}>
          <FontAwesomeIcon className="me-2" icon={faPersonRunning} />
          About Get Out
        </NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
};

const ProfileMenu = ({user, isMobile, root_path}) => {
  const {logout, isLoggedIn} = useLogin();
  const navigate = useNavigate();
  const {isCustomer} = useStripe();
  return (
    <Nav className={isMobile ? "me-auto" : isLoggedIn() ? "" : ""}>
      <NavDropdown
        menuVariant="dark"
        align="end"
        title={
          isLoggedIn() ? (
            <img
              alt={`${user?.username} profile`}
              className="profile-image"
              src={user?.picture}
            />
          ) : (
            <FontAwesomeIcon className="me-2" icon={faUser} />
          )
        }
        id="profile-dropdown"
        show={isMobile ? true : undefined}>
        {!isLoggedIn() && (
          <NavDropdown.Item
            href="#"
            key="sign in"
            onClick={() => navigate("/login")}>
            Sign In
          </NavDropdown.Item>
        )}
        {!isCustomer() && (
          <NavDropdown.Item
            href="#"
            key={"manage-pricing"}
            active={root_path === "pricing"}
            onClick={() => navigate("/pricing")}>
            <FontAwesomeIcon className="me-2" icon={faCrown} />
            Go Premium
          </NavDropdown.Item>
        )}

        {isLoggedIn() && (
          <>
            <NavDropdown.Item
              href="#"
              key={"manage-profile"}
              active={root_path === "profile"}
              onClick={() => navigate("/profile")}>
              Profile
            </NavDropdown.Item>
            <NavDropdown.Item key="logout" href="#" onClick={() => logout()}>
              Logout
            </NavDropdown.Item>
          </>
        )}
      </NavDropdown>
    </Nav>
  );
};
/**
 * Top Navigation bar
 *
 * It logs in user from button, calling our backend to retrieve/create
 * user object, and then setting it into local storage
 */
const TopNav = ({width}) => {
  const {user, userdata, isLoggedIn, updateUserData} = useLogin();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const root_path = path.split("/")[1];
  const isMobile = useMediaQuery({maxWidth: 767});

  let pageSlug = undefined;
  if (path.startsWith("/page/") && path.split("/")[2] !== "new") {
    pageSlug = path.split("/")[3];
  }

  const {page} = usePage(pageSlug);

  const current_title = page?.name;
  const [expanded, setExpanded] = useState(false);

  function overrideToggle() {
    setExpanded((prevExpanded) => !prevExpanded);
  }
  return (
    <Navbar
      id="mainNavbar"
      className="py-0 fs-5"
      bg="dark"
      collapseOnSelect
      expand="md"
      fixed="top"
      variant="dark"
      style={{width: width}}
      // OVERRIDING DEFAULT BEHAVIOR SO WE CAN DETECT CURRENT TOGGLED STATE
      expanded={expanded} // only need to add this when configuring the nav to detect its expanded state
      onToggle={overrideToggle}>
      <Navbar.Text className="py-0">
        <img
          onClick={(e) => navigate("")}
          alt="Get Out Logo"
          className="d-inline-block "
          src={logo}
          style={{cursor: "pointer", height: "56px"}}
        />
      </Navbar.Text>
      <Navbar.Brand onClick={overrideToggle}>
        {page && (
          <Navbar.Text className="ms-3 me-1 mobile-title">
            {page?.tracking ? (
              <FontAwesomeIcon icon={faLocationArrow} />
            ) : (
              <FontAwesomeIcon icon={faLocationDot} />
            )}
          </Navbar.Text>
        )}
        <Navbar.Text className="ms-1 me-auto mobile-title">
          {current_title}
        </Navbar.Text>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <WrapForMobile
          wrapper={(children) => (
            // In mobile, we wrap this in a Nav to allow scrolling
            <Nav
              key="mobile-wrap"
              className="me-auto my-2 mx-3 my-lg-0"
              style={{maxHeight: "vh", touchAction: "pan-y"}}
              navbarScroll>
              {children}
            </Nav>
          )}>
          {isLoggedIn() ? (
            <>
              {/* PAGES MENU */}
              <PagesMenu
                user={user}
                userdata={userdata}
                isMobile={isMobile}
                currentPage={page}
              />
              {/* MANAGE MENU */}
              <ManageMenu
                user={user}
                userdata={userdata}
                isMobile={isMobile}
                currentPage={page}
                updateUserData={updateUserData}
              />
            </>
          ) : (
            <WelcomeMenu isMobile={isMobile} />
          )}
          {/* PROFILE Picture */}
          {/* <Nav
                key="profile"
                className="px-2 px-xs-0 text-end text-xs-start">
                <Navbar.Text>
                  <img
                    alt={`${user?.username} profile`}
                    className="profile-image"
                    src={user?.picture}
                  />
                </Navbar.Text>
              </Nav> */}
          <ProfileMenu user={user} isMobile={isMobile} root_path={root_path} />
        </WrapForMobile>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopNav;
