export const WEATHER_TO_NUM = {
    undefined: 0,
    "": 0,
    isolated: 1,
    slight_chance: 2,
    chance: 3,
    likely: 4,
    definite: 4,
    patchy: 2,
    areas: 3,
    dense: 4,
  };