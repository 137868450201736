import * as Sentry from "@sentry/react";
import {toast, Toaster, ToastBar} from "react-hot-toast";
import {SWRConfig} from "swr";
import Root from "./pages/Root";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet";

// function localStorageProvider() {
//   // When initializing, we restore the data from `localStorage` into a map.
//   const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'))

//   // Before unloading the app, we write back all the data into `localStorage`.
//   window.addEventListener('beforeunload', () => {
//     const appCache = JSON.stringify(Array.from(map.entries()))
//     localStorage.setItem('app-cache', appCache)
//   })

//   // We still use the map for write & read for performance.
//   return map
// }

export default function App() {
  return (
    <Sentry.ErrorBoundary
      fallback={({error, componentStack}) => (
        <div className="container">
          <h2 className="my-4">You have encountered an error</h2>

          <div className="my-4">
            <em>{error.toString()}</em>
          </div>

          <div className="my-4">{componentStack}</div>
        </div>
      )}>
      <SWRConfig
        value={
          {
            // promise: localStorageProvider,
            // onError: (error, key) => {
            //   if (error.status !== 403 && error.status !== 404) {
            //     toast.error(error.message)
            //   }
            // },
          }
        }>
        <Helmet>
          <meta
            name="robots"
            content={process.env.NODE_ENV === "production" ? "all" : "noindex"}
          />
          {/* Add more meta tags as needed */}
        </Helmet>
        <Toaster position="top-right">
          {(t) => (
            <ToastBar toast={t}>
              {({icon, message}) => (
                <>
                  {icon}
                  {message}
                  {t.type !== "loading" && (
                    <button onClick={() => toast.dismiss(t.id)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
        <Root />
      </SWRConfig>
    </Sentry.ErrorBoundary>
  );
}
