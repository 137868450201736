import {WEATHER_TO_NUM} from "util/constants";
import {isUndefined} from "lodash";

// Calculate the score for a single factor/feature
// desired is an object with min, max, and ideal (or idealmin, and idealmax in the case of a "trapezoidal" 4 part score)
// feature is a string with the name
// values is all the values for this time slot
// so we use feature string to extract the value we need to compare with
export const calc_score = (desired, feature, values) => {
  var value;
  if (feature.startsWith("ptype")) {
    const weather_type = feature.split(".")[1];
    if (values["ptype"] && weather_type in values["ptype"]) {
      const amount = values["ptype"][weather_type];
      value = WEATHER_TO_NUM[amount];
    } else {
      value = 0;
    }
  } else if (feature === "tide") {
    value = values?.tide?.hourly_height;
  } else {
    value = values[feature];
  }

  // manage special scoring cases
  if (feature === "windgust" && isNaN(value)) {
    value = 0;
  }

  // return zero in the both cases where you cant calculate a slope to project
  // a value less than zero

  if (value < desired.min || value > desired.max) {
    return "NA";
  }

  if (isNaN(value)) {
    return "NA"
  }
  // if ideal is defined, it is a 3 PART SCORE
  if ("ideal" in desired) {
    if (value === desired.ideal) {
      return 1;
    }
    if (value === desired.min) {
      return 0;
    }
    if (value === desired.max) {
      return 0;
    }
    if (value <= desired.ideal) {
      return (value - desired.min) / (desired.ideal - desired.min);
    }
    if (value >= desired.ideal) {
      return 1 - (value - desired.ideal) / (desired.max - desired.ideal);
    }
  } else {
    // 4 PART SCORE
    if (value >= desired.idealmin && value <= desired.idealmax) {
      return 1;
    }
    if (desired.min === desired.idealmin && value <= desired.min) {
      return 0;
    }
    if (desired.max === desired.idealmax && value >= desired.max) {
      return 0;
    }

    if (value <= desired.idealmin) {
      return (value - desired.min) / (desired.idealmin - desired.min);
    }
    if (value >= desired.idealmax) {
      return 1 - (value - desired.idealmax) / (desired.max - desired.idealmax);
    }
  }
};

const gradeLevels = [
  [0, "F"],
  [0.2, "D"],
  [0.3, "D+"],
  [0.4, "C-"],
  [0.5, "C"],
  [0.6, "C+"],
  [0.7, "B-"],
  [0.75, "B"],
  [0.8, "B+"],
  [0.85, "A-"],
  [0.9, "A"],
  [0.95, "A+"],
];

export const calc_activity_grade = (ideals, actuals) => {
  if (
    // if cant be daytime and it is daytime or cant be nighttime and it is night
    (!ideals.times.day && !actuals.night) ||
    (!ideals.times.night && actuals.night)
  ) {
    return [undefined, "NA"];
  }

  // Call calc_score on each individual score
  const scores = ideals.ranges
    .map((ideal) => {
      return {
        score: calc_score(ideal.values, ideal.feature, actuals),
        weight: isUndefined(ideal.weight) ? 1 : ideal.weight,
      };
    })
    .filter((s) => s.score !== undefined);

  // Trigger a return on an NA values (which would be any values out of range)
  if (scores.find((s) => s.weight > 0 && s.score === "NA")) {
    return [undefined, "NA"];
  }

  // Next, weight the scores after removing NA

  const weighted_scores = scores
    .filter((s) => s.score > 0)
    .map((s) => s.weight * s.score);

  const max_score = ideals.ranges.reduce(
    (partialSum, ideal) =>
      partialSum + (isUndefined(ideal.weight) ? 1 : ideal.weight),
    0
  );
  const number_grade = weighted_scores.reduce((a, b) => a + b, 0) / max_score;

  // if (number_grade === -Infinity || isNaN(number_grade)) {
  //   console.log(scores)
  // }
  var letter_grade = "";
  for (let g of gradeLevels) {
    if (number_grade >= g[0]) {
      letter_grade = g[1];
    } else {
      break;
    }
  }

  if (letter_grade === "") {
    return [undefined, "NA"];
  }

  return [number_grade, letter_grade];
};
