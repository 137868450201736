import {clamp, isNaN, isEmpty} from "lodash";
import {useRef} from "react";
import {useGesture} from "@use-gesture/react";
import {useSpring, animated} from "@react-spring/web";
import {useEffect, useState} from "react";
import {Alert, Row, Col} from "react-bootstrap";

export const RibbonView = ({viewDims, dataList}) => {
  const {dims, timeribbon, labels, ribbons} = dataList
    ? dataList[1]
    : undefined;
  const scrollSpeed = -10; // about 10 - 100, the minus makes it match OS scrolling
  const minScale = 0.25;
  const maxScale = 1.5;
  const spacer = 0;
  // calculate the sum of all dataList widths

  const totalwidth =
    dataList.reduce((sum, v) => sum + v.dims.width, 0) +
    spacer * dataList.length;

  const ratiosOfTotal = dataList.map(
    (v) => (v.dims.width + spacer) / totalwidth,
  );

  const testHeight =
    dims.ribbonTop +
    (dims.unitHeight + 20) * ribbons?.length +
    dims.verticalPadding;
  const height = isNaN(testHeight) ? 0 : testHeight;
  // useEffect(() => {
  //   const handler = (e) => {
  //     console.log("prevent");
  //     e.preventDefault();
  //   };
  //   document.addEventListener("gesturestart", handler);
  //   document.addEventListener("gesturechange", handler);
  //   document.addEventListener("gestureend", handler);
  //   return () => {
  //     document.removeEventListener("gesturestart", handler);
  //     document.removeEventListener("gesturechange", handler);
  //     document.removeEventListener("gestureend", handler);
  //   };
  // }, []);

  // function to swap the url path from page/view/name to page/edit/name
  // so that I can make a hyperlink to edit this page
  const editMe = () => {
    // grab url from react router
    const url = window.location.pathname;
    const parts = url.split("/");
    parts[2] = "edit";
    return parts.join("/");
  };

  const config = {
    mass: 1,
    tension: 170,
    friction: 27,
    // easing: easings.easeInOutQuint,
    // clamp: true,
  };

  const [style, api] = useSpring(() => ({
    x: 0,
    y: dims.ribbonTop + dims.ribbonMargin,
    scale: 1,
    config: config,
  }));

  const [legendstyle, legendapi] = useSpring(() => ({
    y: dims.ribbonTop + dims.ribbonMargin,
    scale: 1,
    config: config,
  }));

  const [rightlegendstyle, rightlegendapi] = useSpring(() => ({
    y: dims.ribbonTop + dims.ribbonMargin,
    scale: 1,
    config: config,
  }));

  const [timebarstyle, timebarapi] = useSpring(() => ({
    x: 0,
    scale: 1,
    config: config,
  }));

  const topref = useRef(null);
  const ribbonref = useRef(null);

  // dynamic clamping of where users can pan and zoom
  const clamp_bounds = (
    x,
    y,
    scale,
    down = false,
    startx = 0,
    endx = totalwidth,
  ) => {
    const clampscale = clamp(scale, minScale, maxScale);
    const maxx = startx; // -(dims.width  - (dims.width ) * clampscale);
    const minx = Math.min(
      maxx,
      // remember that this is the negative position of the left side of the ribbons
      maxx - ((endx + 100) * clampscale - viewDims.width),
    );
    const maxy = (dims.ribbonTop + dims.ribbonMargin) * clampscale; // top
    const miny = Math.min(
      -clampscale * height + (viewDims.height - maxy - 40 / clampscale),
      maxy,
      //  (dims.ribbonTop + (height / 2 - (height / 2) * scale))
    );

    // for dragging, we skip clamping when the mouse is down
    // this gives the effect of dragging past the edge and springing back to clamped edge
    const finalx = down ? x : clamp(x, minx, maxx);
    const finaly = down ? y : clamp(y, miny, maxy);

    const leftRibbonScreenWidth =
      endx * ratiosOfTotal[0] * clampscale - viewDims.width;

    const leftlegendx =
      (leftRibbonScreenWidth < 0 &&
        endx * ratiosOfTotal[0] * clampscale + finalx > 400 * clampscale) ||
      finalx > -leftRibbonScreenWidth
        ? 0
        : finalx + leftRibbonScreenWidth;
    const rightlegendx =
      finalx > -endx * ratiosOfTotal[0] * clampscale
        ? finalx + endx * ratiosOfTotal[0] * clampscale
        : 0;

    // set stickyx to -(endx * 0.5 * clampscale - viewDims.width) if it within 100 pixels of that value
    // otherwise set it to 0
    // const stickyx =
    //   Math.abs(x + (endx * 0.5 + spacer) * clampscale) < 80
    //     ? -((endx * 0.5 + spacer) * clampscale)
    //     : x;

    return {
      clampx: finalx,
      clampy: finaly,
      clampscale,
      leftlegendx,
      rightlegendx,
    };
  };

  //   useWheel(({event}) => event.preventDefault(), {
  //     target: topref,
  //     eventOptions: {passive: false},
  //   });

  useGesture(
    {
      onDrag: ({
        pinching,
        cancel,
        offset: [ox, oy],
        memo,
        down,
        velocity: [vx, vy],
        direction: [dx, dy],
      }) => {
        if (pinching) return cancel();
        let x;
        let y;
        let config = {};

        if (down) {
          // Handle the dragging gesture
          x = ox;
          y = oy;
        } else {
          // Handle the end of the gesture with momentum
          x = ox + dx * (memo ? memo[0] + vx : vx) * 150;
          y = oy + dy * (memo ? memo[1] + vy : vy) * 150;
        }

        const {clampx, clampy, leftlegendx, rightlegendx} = clamp_bounds(
          x,
          y,
          style.scale.get(),
          down,
        );
        api.start({x: clampx, y: clampy, immediate: down, config});
        legendapi.start({x: leftlegendx, y: clampy, immediate: down, config});
        rightlegendapi.start({
          x: rightlegendx,
          y: clampy,
          immediate: down,
          config,
        });
        timebarapi.start({x: clampx, immediate: down, config});

        memo = [
          memo ? memo[0] * 0.1 + vx * 0.9 : vx,
          memo ? memo[1] * 0.1 + vy * 0.9 : vy,
        ];
        return memo;
      },
      onWheel: ({
        last,
        memo,
        velocity: [vx, vy],
        direction: [dx, dy],
        event,
      }) => {
        event.preventDefault(); // Prevent the default scroll behavior
        let x;
        let y;

        if (!last) {
          // If it's not the last wheel event, mimic the drag behavior
          if (memo === undefined) {
            // This is the first scroll, initialize memo
            memo = {ox: style.x.get(), oy: style.y.get(), vx, vy};
          }

          // Mimic the drag "down" behavior using the wheel's velocity and direction
          x = memo.ox + dx * vx * scrollSpeed;
          y = memo.oy + dy * vy * scrollSpeed;
        } else {
          // On the last event, apply a final momentum to the scroll
          x = memo.ox + dx * (memo.vx * 0.2 + vx * 0.8) * scrollSpeed;
          y = memo.oy + dy * (memo.vy * 0.2 + vy * 0.8) * scrollSpeed;
        }

        const {clampx, clampy, leftlegendx, rightlegendx} = clamp_bounds(
          x,
          y,
          style.scale.get(),
        );
        api.start({x: clampx, y: clampy, immediate: true});
        legendapi.start({x: leftlegendx, y: clampy, immediate: true});
        rightlegendapi.start({x: rightlegendx, y: clampy, immediate: true});

        timebarapi.start({x: clampx, immediate: true});

        // Update the memo object to store the most recent velocity
        memo = {
          ox: clampx,
          oy: clampy,
          vx: vx,
          vy: vy,
        };
        return memo;
      },
      onPinch: ({origin: [ox, oy], first, offset: [s], memo}) => {
        if (first) {
          // in this case "Image" = the weather ribbons
          const box = ribbonref.current?.getBoundingClientRect();

          const xVisibleImagePixels = (viewDims.width / box.width) * dims.width;
          const originScreenRatioX = ox / viewDims.width;
          const completeRatioX =
            (-style.x.get() / style.scale.get() +
              originScreenRatioX * xVisibleImagePixels) /
            dims.width;

          const yVisibleImagePixels = (viewDims.height / box.height) * height;
          const originScreenRatioY = oy / viewDims.height;
          const completeRatioY =
            ((-style.y.get() - dims.ribbonTop) / style.scale.get() +
              originScreenRatioY * yVisibleImagePixels) /
            height;

          memo = {
            style: {
              x: style.x.get(),
              y: style.y.get(),
              scale: style.scale.get(),
            },
            startratio: {
              x: completeRatioX,
              y: completeRatioY,
            },
            startbox: {w: box.width, h: box.height},
            scale: s,
            box: box,
          };
        }

        const diffwidth = memo.box.width * (s / memo.scale) - memo.box.width;
        const diffheight = memo.box.height * (s / memo.scale) - memo.box.height;

        const x = memo.style.x - diffwidth * memo.startratio.x;
        const y = memo.style.y - diffheight * memo.startratio.y;

        const {clampx, clampy, clampscale, leftlegendx, rightlegendx} =
          clamp_bounds(x, y, s);
        api.start({x: clampx, y: clampy, scale: clampscale, immediate: true});
        legendapi.start({
          x: leftlegendx,
          y: clampy,
          scale: clampscale,
          immediate: true,
        });
        rightlegendapi.start({
          x: rightlegendx,
          y: clampy,
          scale: clampscale,
          immediate: true,
        });
        timebarapi.start({x: clampx, scale: clampscale, immediate: true});
        return memo;
      },
    },
    {
      target: topref,
      drag: {
        from: () => [style.x.get(), style.y.get()],
        filterTaps: true,
        pointer: {lock: false},
      },
      wheel: {target: topref, eventOptions: {passive: false}},
      pinch: {
        scaleBounds: {min: 0.25, max: 1.5},
      },
    },
  );

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  // const totalHeight =
  //   dims.svgheight * (placedActivitySet?.placedactivities.length + 1.5);

  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    const preventDefaultScroll = (e) => e.preventDefault();

    window.addEventListener("wheel", preventDefaultScroll, {passive: false});

    return () => {
      window.removeEventListener("wheel", preventDefaultScroll);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowMessage(isEmpty(ribbons));
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [ribbons]);

  if (showMessage) {
    return (
      <Alert variant="warning">
        <b>This Page Needs some Ribbons!</b>
        <p>
          Got to <a href={editMe()}>Page Editor</a> for this page and click the
          "Add Ribbon" Button{" "}
        </p>
      </Alert>
    );
  }

  if (isEmpty(ribbons)) {
    return <div></div>;
  }

  return (
    <div ref={topref} className="ribbons-container">
      <animated.div
        id="timeribbon-layer"
        className="ribbon-content"
        style={{
          ...timebarstyle,
          transformOrigin: "top left",
          left: 0,
          zIndex: 10,
          height: dims.ribbonTop,
          //height: Math.max(height * timebarstyle.scale.get(), window.innerHeight),
          width: dims.width,
        }}>
        <Row style={{width: totalwidth}}>
          {dataList.map((data, i) => (
            <Col key={i} style={{width: data.dims.width, marginRight: spacer}}>
              {data.timeribbon}
            </Col>
          ))}
        </Row>

        <div id="top-blocker" style={{height: dims.ribbonTop}} />
      </animated.div>

      <animated.div
        id="legend-layer"
        className="ribbon-content"
        style={{
          ...legendstyle,
          transformOrigin: "top left",
          zIndex: 5,
          top: dims.ribbonTop,
          height: height,
          width: dims.width,
          cursor: "grab",
        }}>
        {dataList[0].labels}
      </animated.div>

      <animated.div
        id="legend-layer"
        className="ribbon-content"
        style={{
          transformOrigin: "top left",
          zIndex: 5,
          top: dims.ribbonTop,
          height: height,
          width: dims.width,
          cursor: "grab",
          ...rightlegendstyle,
        }}>
        {dataList[1].labels}
      </animated.div>

      <animated.div
        id="ribbon-layer"
        ref={ribbonref}
        className="ribbon-content"
        style={{
          ...style,
          transformOrigin: "top left",
          height: height,
          width: dims.width,
        }}>
        <Row style={{width: totalwidth}}>
          {dataList.map((data, i) => (
            <Col key={i} style={{width: data.dims.width, marginRight: spacer}}>
              {data.ribbons}
            </Col>
          ))}
        </Row>
      </animated.div>
    </div>
  );
};
