import * as d3 from "d3";

import useProcessedWeather from "hooks/useProcessedWeather";
import {draw_time_axes_tz, draw_yaxes, draw_line, xScaleHourWidth} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import {isUndefined} from "lodash";
import {draw_horizontal_line, draw_marker_text} from "util/drawfuncs";
import useAstro from "hooks/useAstro";

export const AstroRibbon = (props) => {
  const {data: nwsdata} = useProcessedWeather(
    props.currentPlacedActivity.place,
  );
  const {data} = useAstro(props.currentPlacedActivity.place, nwsdata);

  if (isUndefined(data?.chartdata) || data.chartdata.length === 0) return;
  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    nwsdata?.chartdata,
  );

  const draw_body_line = (svg, data, attr_name, xScale, yScale) => {
    const isGood = (v) => v !== null && isFinite(v);
    const line = d3
      .line()
      .x((d) => xScale(d.time))
      .y((d) => yScale(d.astro[attr_name].alt))
      .defined((d) => isGood(d.astro[attr_name].alt))
      .curve(d3.curveCardinal.tension(0.7));

    svg.select("#" + attr_name).remove();
    svg
      .append("path")
      .attr("d", (value) => line(data))
      .attr("id", attr_name)
      .attr("class", "line " + attr_name);

    // svg
    //   .selectAll("#marker" + attr_name)
    //   .data(data.filter((d) => isGood(d.astro[attr_name].alt)))
    //   .join("circle")
    //   .attr("class", "marker " + attr_name)
    //   .attr("cx", (d) => xScale(d.time))
    //   .attr("cy", (d) => yScale(d.astro[attr_name].alt))
    //   .attr("r", 1.5)
    //   .attr("id", "marker" + attr_name);
  };

  const draw_astro = (g, data, width, height, attrlist, xScale) => {
    if (
      isUndefined(data.chartdata[0]) ||
      isUndefined(data.chartdata[0].astro)
    ) {
      return;
    }
    //Yscales
    let maxheight = d3.max(data.chartdata, function (d) {
      if (d.astro) return d3.max(attrlist.map((name) => d.astro[name].alt));
      else return 0;
    });
    // roundup to closest 10
    maxheight = Math.ceil(maxheight / 100) * 100;

    const minheight = -12;
    // const midpoint = (maxheight + minheight) / 2;
    const yScale = d3
      .scaleLinear()
      .domain([minheight, maxheight])
      .range([height, 0])
      .nice();

    draw_yaxes(g, yScale, width, "°", 0);
    draw_time_axes_tz(
      g,
      xScale,
      height,
      nwsdata.nightblocks,
      false,
      data?.metadata?.timezone,
    );
    draw_horizontal_line(g, width, height, yScale, 0, "horizon");
    attrlist.forEach((name) => {
      draw_body_line(g, data.chartdata, name, xScale, yScale);
    });
    g.selectAll("#amarkertext" + "moonphase")
      .data(
        data.chartdata?.filter(
          (d, i) => (i + 1) % 3 === 0 && i !== data.length - 1,
        ),
      )
      .join("text")
      .attr("x", (d) => xScale(d.time) - 8)
      .attr("y", (d) => yScale(d.astro.moon.alt) + 5)
      .attr("class", "marker-text " + "moon")
      .text((d) => d.astro["moon"]["icon"])
      .attr("id", "markertext" + "moonphase");
    
      g.selectAll(".darknight") // use class instead of id for selection
      .data(data.moonless_blocks)
      .join("rect")
      .attr("x", (b) => xScale(b[0]))
      .attr("width", (b) => xScale(b[1]) - xScale(b[0])) // removed the redundant return statement
      .attr("height", height)
      .attr("class", "darknight"); // removed the id attribute, class is enough for CSS styling and JavaScript selection
  
      // take pairs of data.nightblocks and create a single list of times
      const suntimes = data.nightblocks.reduce((acc, cur) => {
        acc.push(cur[0]);
        acc.push(cur[1]);
        return acc;
      }, []).slice(1, -1);
      g
      .selectAll("#setrisetimes")
      .remove()
      .data(
        suntimes
      )
      .join("text")
      .attr("x", (d) => xScale(d))
      .attr("y", 15)
      .attr("class", "marker-text")
      .style("text-anchor", "middle")
      .text((d) => d3.timeFormat("%I:%M %p")(d))
      .attr("id", "markertext setrisetimes");
  }; // end draw_astro

  let draw_func = (svg) => {
    draw_astro(
      svg,
      data,
      props.dims.width,
      props.dims.unitHeight,
      ["sun", "moon"], //, "mars", "venus", "jupiter", "saturn", "mercury", "neptune", "uranus"],
      xScale,
    );
  };
  return (
    <div className="ribbon">
      <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
