import * as d3 from "d3";

import useProcessedWeather from "hooks/useProcessedWeather";
import {draw_time_axes_tz, draw_yaxes, draw_line} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import {isUndefined, max} from "lodash";
import {DateTime} from "luxon";
import useStripe from "hooks/useStripe";
import useTides from "hooks/useTides";

export const TideRibbon = (props) => {
  const {data} = useProcessedWeather(props.currentPlacedActivity.place);
  // retrieve tides if they are not already present in this data.
  const {data: dataPlusTides} = useTides(
    props.currentPlacedActivity.place,
    data,
  );

  const {isCustomer} = useStripe();

  if (
    isUndefined(dataPlusTides?.chartdata) ||
    dataPlusTides.chartdata.length === 0
    // isUndefined(dataPlusTides?.metadata?.tide)
  )
    return;

  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    dataPlusTides?.chartdata,
  );

  const draw_tides = (g, data, width, height, xScale, ymargin) => {
    //Yscales
    let maxheight = d3.max(data.chartdata, (d) => d.tide?.hourly_height);
    // roundup to closest 10
    maxheight = maxheight + 1;

    let minheight = d3.min(data.chartdata, (d) => d.tide?.hourly_height);

    const yScale = d3
      .scaleLinear()
      .domain([minheight, maxheight])
      .range([height-ymargin, 0])
      .nice();

    draw_time_axes_tz(
      g,
      xScale,
      height,
      data.nightblocks,
      true,
      data.metadata.timezone,
    );

    draw_yaxes(g, yScale, width, "ft");

    const hourly_heights = data.chartdata.map((d) => {
      return {tide: d.tide?.hourly_height, time: d.time};
    });

    const extremes = data.chartdata
      .filter((d) => !isUndefined(d.tide?.exact_time))
      .map((d) => {
        // insert this tide and time into the hourly_heights array
        hourly_heights.push({
          tide: d.tide.extreme_height,
          time: d.tide.exact_time.toMillis(),
        });
        return {
          height: d.tide.extreme_height,
          time: d.tide.exact_time.toMillis(),
          timestr: d.tide.exact_time.toLocaleString(DateTime.TIME_SIMPLE),
          high: d.tide.action === "H",
        };
      });
    // sort hourly heights by time
    hourly_heights.sort((a, b) => a.time - b.time);
    if (isCustomer()) {
      draw_line(g, hourly_heights, "tide", xScale, yScale);
      g.selectAll("#markertext tide")
        .data(extremes)
        .join("text")
        .attr("x", (d) => xScale(d.time))
        .attr("y", (d) => yScale(d.height) +(d.high ? 25 : -12))
        .attr("class", "marker-text tide")
        .text((d) => d.height + "'")
        .style("text-anchor", "middle")
        .attr("id", "markertext tide");

      g.selectAll("#markertext tide")
        .data(extremes)
        .join("text")
        .attr("x", (d) => xScale(d.time))
        .attr("y", height - 3)
        // .attr("y", (d) => yScale(d.height) +(d.high ? 40 : 45))
        .attr("class", "marker-text")
        .text((d) => d.timestr)
        .style("text-anchor", "middle")
        .attr("id", "markertext tide");
       
        g.selectAll("#marker tide")
        .data(extremes)
        .join("circle")
        .attr("class", "marker tide")
        .attr("cx", (d) => xScale(d.time))
        .attr("cy", (d) => yScale(d.height))
        .attr("r",3)
        .attr("id", "marker tide");
    } else {
      g.selectAll("#upgradetext")
        .data(extremes)
        .join("text")
        .attr("x", (d) => xScale(d.time))
        //.attr("y", yScale(minheight + 2))
        .attr("y", (d) => yScale(d.height))
        .attr("class", "marker-text tide")
        .text("Upgrade For Tides")
        .style("text-anchor", "middle")
        .attr("id", "upgradetext");
    }
  };
  let draw_func = (svg) => {
    draw_tides(
      svg,
      dataPlusTides,
      props.dims.width,
      props.dims.unitHeight,
      xScale,
      10
    );
  };

  return (
    <div className="ribbon">
      <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
