import useSWR from "swr";
import useNWSWeather from "./useNWSWeather";
import {DateTime, FixedOffsetZone} from "luxon";
import {isUndefined} from "lodash";
import {makeNightBlock, is_night} from "util/nightblocks";

export const processWeatherData = ([data, place]) => {
  // Get the current local time
  //const localTime = DateTime.local();
  // Calculate the difference in minutes
  // let nightblocks_orig;

  let offsetInSeconds = data.metadata.utcoffset;

  // Convert the offset to hours and minutes
  // let offsetSign = offsetInSeconds >= 0 ? '+' : '-';
  // let absoluteOffset = Math.abs(offsetInSeconds);
  // let offsetHours = Math.floor(absoluteOffset / 3600);
  // let offsetMinutes = Math.floor((absoluteOffset % 3600) / 60);

  // // Format the offset as a string
  // let offsetString = `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;

  const customZone = new FixedOffsetZone(offsetInSeconds / 60);

  
  if (!isUndefined(data?.chartdata) && !isUndefined(place)) {
    // TODO: move this to server side to support alerts.
    // calc and add activity data to chartdata
    //const timeDifference = localTime.setZone(data.metadata.timezone).offset - localTime.offset;
    // const times = data.chartdata.map((d) =>
    //   DateTime.fromISO(d.utctimestr, {
    //     zone: data.metadata.timezone,
    //   }),
    // );

    // const [lat, lon] = [place.lat, place.lon];

    // nightblocks_orig = makeNightBlock(times, lat, lon, data.metadata.timezone);


    const datetime_nightblocks = data.nightblocks.map((block) => {
      return [
        DateTime.fromISO(block[0]).setZone(customZone),
        DateTime.fromISO(block[1]).setZone(customZone),
      ];
    });
    // console.log(datetime_nightblocks);
    const datetime_moonless_blocks = data.moonless_blocks.map((block) => {
      return [
        DateTime.fromISO(block[0]).setZone(customZone),
        DateTime.fromISO(block[1]).setZone(customZone),
      ];
    });

    const new_chartdata = data.chartdata.map((record) => {
      const new_record = {
        ...record,
        time: new Date(record.utctimestr),
        datetime: DateTime.fromISO(record.utctimestr),
      };

      if (new_record.tide?.exact_time) {
        new_record.tide.exact_time = DateTime.fromISO(record.tide.exact_time).setZone(customZone);
      }
      return new_record;
    });
    return {
      ...data,
      metadata: {...data.metadata, timezone: customZone},
      nightblocks: datetime_nightblocks,
      moonless_blocks: datetime_moonless_blocks,
      chartdata: new_chartdata,
    };
  } else {
    // end check if data isUndefined
    return undefined;
  }
};

const useProcessedWeather = (place, mode = "forecast", snowhist = false) => {
  const {data: nwsData, error: nwsError} = useNWSWeather(place, snowhist);
  const {data, error, mutate} = useSWR(
    nwsData?.weather && place ? [nwsData.weather[mode], place] : null,
    processWeatherData,
  );

  // data,
  // chart_meta, // holds height (elevation), description, creationdate
  // temp_types,  // list of strings: temp, windchill, heatindox
  // precip_types, // list of srings seen of ptypes aka. weather
  // nightblocks, // list of pairs of sunset and sunrise times for coloring bckgrounds

  // TODO: refactor users of this data so that they use it straight from server, this is a hack
  const restructured_data =
    mode === "forecast"
      ? {
          metadata: data?.metadata,
          chartdata: data?.chartdata,
          chart_meta: {
            height: data?.metadata.elevation,
            description: data?.metadata.description,
            creationdate: data?.metadata.creationdate,
          },
          temp_types: data?.metadata.temp_types,
          precip_types: data?.metadata.weather_types,
          weather_types: data?.metadata.weather_types,
          nightblocks: data?.nightblocks,
          moonless_blocks: data?.moonless_blocks,
        }
      : data;

  return {
    data: restructured_data,
    error,
    nwsError,
    mutate,
  };
};

export default useProcessedWeather;
