import toast from "react-hot-toast";
import {backend_login} from "api/google-login";
import {useGoogleLogin, googleLogout} from "@react-oauth/google";
import {useNavigate} from "react-router-dom";

import useUserData from "hooks/useUserData";
import {useUser} from "hooks/useUser";
import {isUndefined} from "lodash";
import {useState} from "react";

const useLogin = (successurl, errorurl, defaultMailingList = false) => {
  const {user, mutate: mutateUser} = useUser();
  const [addToMailingList, setAddToMailingList] = useState(defaultMailingList);
  const {userdata, isLoggedIn, updateUserData} = useUserData(
    user?.userdata?.id,
  );
  const navigate = useNavigate();
  /**
   * Performs a Google Login on user clicking on button, gets/create user
   * in our backend and sets localStorage with user data
   */

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async ({code}) => {
      try {
        const user = await backend_login(code, addToMailingList || false);
        mutateUser(user);
        if (user?.userdata) {
          updateUserData(user?.userdata);
        }
        if (!isUndefined(successurl)) {
          navigate(successurl);
        }
      } catch (error) {
        toast.error("Google Login Error", {});
        console.log(error);
        if (!isUndefined(errorurl)) {
          navigate(errorurl);
        }
      }
    },
    onError: (errorResponse) => {
      toast.error("Google Login Error", {});
      console.log(errorResponse);
      if (!isUndefined(errorurl)) {
        navigate(errorurl);
      }
    },
  });

  /**
   * Logouts user: calls Google logout, remove data from localStorage
   * and unsets state
   *
   * We consider a user as logged in where we can find data in localStorage
   */
  const logout = () => {
    googleLogout();
    localStorage.removeItem("user");
    localStorage.removeItem("drfToken");

    mutateUser(undefined);
    navigate("/");
  };

  return {
    login,
    logout,
    user,
    userdata,
    isLoggedIn,
    updateUserData,
    addToMailingList,
    setAddToMailingList,
  };
};

export default useLogin;
