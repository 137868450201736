import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {
  faHand,
  faHandBackFist,
  faMap,
  faSquareMinus,
} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import HelpDialog from "components/HelpDialog";
import {ScoreRibbonSettings} from "components/ScoreRibbonSettings";

import {Button, Card, Col, Row, Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {TideRibbonSettings} from "./TideRibbonSettings";
import {useState, useRef, useEffect} from "react";
import useLocationState from "hooks/useLocationState";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

export const EditRibbons = ({pageData, setPageData}) => {
  const navigate = useNavigate();
  const {navToMap} = useLocationState();

  // if place is set for any pageData.memberribbons, then expert mode is true
  const hasRibbonPlace = pageData?.memberribbons?.some(
    (member) => member.place,
  );
  const [expertMode, setExpertMode] = useState(true);

  const [expandedCards, setExpandedCards] = useState({});
  const cardBodyRefs = useRef({});

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    // Reorder memberribbons
    const items = Array.from(pageData.memberribbons);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setPageData({...pageData, memberribbons: items});

    // Reorder expandedCards
    const expandedItems = Array.from(expandedCards);
    const [reorderedExpandedItem] = expandedItems.splice(
      result.source.index,
      1,
    );
    expandedItems.splice(result.destination.index, 0, reorderedExpandedItem);
    setExpandedCards(expandedItems);
  };

  const handleDelete = (index) => {
    const new_ribbons = pageData.memberribbons.filter((_, i) => i !== index);
    setPageData({...pageData, memberribbons: new_ribbons});

    const new_expandedCards = {...expandedCards};
    delete new_expandedCards[index];
    setExpandedCards(new_expandedCards);
  };

  const getItemStyle = (isDragging) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "lightyellow" : "white",
    boxShadow: isDragging ? "-5px 5px 4px rgba(0, 0, 0, 0.3)" : "",
    marginLeft: isDragging ? "6px" : "0px",
  });

  // Keep this for when we want to allow advanced users to edit the place of an individual ribbon

  const gotoMapForRibbon = (index) => {
    // pathToPlace tells the map tools where to insert the selected or created place inside the pageData object
    navToMap(pageData, index);
    // navigate("/map", {
    //     state: {
    //       callback: window.location.pathname,
    //       pathToPlace: index,
    //       pageData: pageData,
    //     },
    //   });
  };

  const clearRibbonPlace = (index) => {
    const new_ribbons = [...pageData.memberribbons];
    new_ribbons[index].place = undefined;
    setPageData({...pageData, memberribbons: new_ribbons});
  };

  const toggleCard = (index) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    Object.keys(cardBodyRefs.current).forEach((key) => {
      const el = cardBodyRefs.current[key];
      if (el) {
        if (expandedCards[key]) {
          const style = window.getComputedStyle(el);
          const totalHeight =
            el.scrollHeight +
            parseInt(style.marginTop) +
            parseInt(style.marginBottom) +
            40;
          el.style.height = `${totalHeight}px`;
        } else {
          el.style.height = "0px";
        }
      }
    });
  }, [expandedCards]);

  return (
    <Card className={"border border-dark border-1 "}>
      <Card.Header className="compact-card-header">
        <Card.Title>
          <HelpDialog title="Ribbons">
            <p>
              Ribbons are the horizontal bands of data that appear on a page.
              Each ribbon has one job. The class determines the type of ribbon.
              For example, Score Ribbons display a score for the selected
              activity. The Temperature Ribbon will display the temperature at
              the page's place.
            </p>
            <p>
              Ribbons can be dragged and dropped to change their order on the
              page. Click the <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon> for more options. Click the Add Ribbon
              button to add a new ribbon to the page.
            </p>
          </HelpDialog>
          Ribbons (Drag and Drop)
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col className="m-2">
            <FontAwesomeIcon icon={faHandBackFist} /> Drag and Drop{" "}
            <FontAwesomeIcon icon={faHand} /> Ribbons to change order. Expand with <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>.
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <Button
              variant="primary"
              onClick={() =>
                navigate("addribbon", {
                  state: {
                    pageData: pageData,
                    callback: window.location.pathname,
                  },
                })
              }>
              Add New Ribbon
            </Button>
          </Col>
          {/* <Col className="ms-auto d-flex justify-content-end">
            <Form.Check
              type="switch"
              id="expert-mode-switch"
              label="Expert Mode"
              disabled={hasRibbonPlace}
              checked={expertMode}
              onChange={() => setExpertMode(!expertMode)}
            />
          </Col> */}
        </Row>
        <Row>
          <Col>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="ribbons">
                {(provided) => (
                  <div
                    className="ribbons"
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {/* BEGIN RIBBON LOOP */}
                    {pageData?.memberribbons?.map((memberribbon, index) => (
                      <Draggable
                        key={memberribbon.ribbon.jsclass + index}
                        draggableId={memberribbon.ribbon.jsclass + index}
                        index={index}>
                        {(provided, snapshot) => (
                          <div
                            className="ribbon"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <Card
                              key={index}
                              style={getItemStyle(snapshot.isDragging)}
                              className="mb-2">
                              <Card.Header className="very-compact-card-header">
                                <Row>
                                  <Col xs={2}>
                                  <HelpDialog
                                        title={memberribbon.ribbon.name}
                                        buttonText="Got it">
                                        {memberribbon.ribbon.description}
                                      </HelpDialog>
                                  </Col>
                                  <Col xs={8}>
                                    <Card.Title>
                                      {memberribbon.ribbon.name} 
                                      {memberribbon.ribbon.jsclass ==="GradeRibbon" && memberribbon?.activity?.name && (" for " + memberribbon.activity.name)} 
                                      {memberribbon.place && ("(" + memberribbon.place.name+ ")")}
                                    </Card.Title>
                                  </Col>
                                  <Col xs={2}>
                                    <Button
                                      variant="link"
                                      onClick={() => toggleCard(index)}>
                                      <FontAwesomeIcon
                                        icon={
                                          expandedCards[index]
                                            ? faChevronUp
                                            : faChevronDown
                                        }
                                      />
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Header>
                              <Card.Body
                                ref={(el) => (cardBodyRefs.current[index] = el)}
                                className={
                                  expandedCards[index]
                                    ? "card-body-expanded"
                                    : "card-body-collapsed"
                                }>
                                {expertMode && (
                                  <div className="py-2">
                                    <div className="pb-1">
                                      {memberribbon.place ? (
                                        <>
                                          Place:{" "}
                                          <b>{memberribbon.place.name}</b>
                                        </>
                                      ) : (
                                        <>
                                          Place: <b>Inherits from Page</b>
                                        </>
                                      )}
                                    </div>

                                    {memberribbon.place ? (
                                      <Button
                                        variant="outline-primary"
                                        onClick={() => {
                                          clearRibbonPlace(index);
                                        }}>
                                        <FontAwesomeIcon
                                          className="me-2"
                                          icon={faSquareMinus}
                                        />
                                        Disconnect Place
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="outline-primary"
                                        onClick={() => {
                                          gotoMapForRibbon(index);
                                        }}>
                                        <FontAwesomeIcon
                                          className="me-2"
                                          icon={faMap}
                                        />
                                        Set Unique Ribbon Place
                                      </Button>
                                    )}
                                  </div>
                                )}

                                {memberribbon.ribbon.jsclass ===
                                  "GradeRibbon" && (
                                   <> <hr />
                                  <ScoreRibbonSettings
                                    pageData={pageData}
                                    setPageData={setPageData}
                                    memberribbon={memberribbon}
                                    index={index}
                                  /></>
                                )}
                                {memberribbon.ribbon.jsclass ===
                                  "TideRibbon" && (
                                    <> <hr />
                                  <TideRibbonSettings
                                    pageData={pageData}
                                    setPageData={setPageData}
                                    memberribbon={memberribbon}
                                    index={index}
                                    place={memberribbon.place || pageData.place}
                                  />
                                  </>
                                )}

                                <Button
                                  variant="danger"
                                  className="mt-3"
                                  onClick={() => handleDelete(index)}>
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    className="me-1"
                                  />
                                  Remove
                                </Button>
                              </Card.Body>
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
