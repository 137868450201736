import * as d3 from "d3";

import useProcessedWeather from "hooks/useProcessedWeather";
import {
  draw_yaxes,
  draw_line,
  draw_marker_text,
  draw_time_axes_tz,
} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import {isUndefined} from "lodash";

const draw_percents = (g, data, width, height, xScale, ymargin = 20) => {
  // draw_time_axes(g, xScale, height + 2 * ymargin, data?.nightblocks);

  const yScale = d3.scaleLinear().domain([0, 100]).range([height, 0]).nice();
  draw_yaxes(g, yScale, width, "%", ymargin);
  draw_time_axes_tz(
    g,
    xScale,
    height + 2 * ymargin,
    data?.nightblocks,
    false,
    data?.metadata?.timezone,
  );



  const plotg = g.append("g").attr("transform", `translate(0,${ymargin + 10})`); // plus 10 account for labels above line


  draw_line(plotg, data.chartdata, "cloud", xScale, yScale);
  draw_marker_text(plotg, data.chartdata, "cloud", true, xScale, yScale, "%");
};

export const CloudRibbon = (props) => {
  const {data} = useProcessedWeather(props.currentPlacedActivity.place);

  if (isUndefined(data?.chartdata) || data.chartdata.length === 0) return;
  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    data?.chartdata,
  );

  let draw_func = (svg) => {
    draw_percents(
      svg,
      data,
      props.dims.width,
      props.dims.unitHeight - 40,
      xScale,
    );
  };
  return (
    <div className="ribbon">
      <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
