import {useState, useEffect, useRef} from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import {Marker, Popup} from "react-leaflet";

import useUserData from "hooks/useUserData";
import {useNavigate, useLocation} from "react-router-dom";
import useLocationState from "hooks/useLocationState";
import {isUndefined} from "lodash";

/**
 * The Marker that appears when someone click a new place on the map
 *
 * It drops the marker and a popup that allows you to the name the place.
 * You can cancel with "Nevermind" and remove the market, or proceed and it will
 * call the backend to create a new place with addPlace function
 *
 * addPlace is provided by Map and passed in as a prop
 */
export const NewMarker = ({
  isActive,
  map,
  clickedMapLocation,
  clearNewLocation,
  isLoggedIn,
}) => {
  const [newPlaceName, setNewPlaceName] = useState("");
  const [searchedName, setSearchedName] = useState("");
  const [refReady, setRefReady] = useState(false);
  const [highlight, setHighlight] = useState(false);
  let popupRef = useRef();
  let inputRef = useRef();
  const {userdata, updateUserData} = useUserData();
  const {setPlace, hasCallback, getPlace, navToCallback} = useLocationState()
  const navigate = useNavigate();
  // const location = useLocation();
  // const callback = location.state?.callback;

  // handle adding a new place only
  //
  // in this "new world" (after Sept 2023), this place will get attached to a ribbon or page
  const handleAddPlaceOnly = (place) => {

    // const pathToPlace = location.state?.pathToPlace;
    // const pageData = location.state?.pageData;
    // if (pageData) {
    //   pageData[pathToPlace] = place;
    //   pageData.tracking = false;
    // }
    setPlace(place)

    if (isLoggedIn() && !isUndefined(userdata?.places)) {
      userdata.places.push(place);
    }
    updateUserData(userdata);
    navToCallback()
    // if (callback) {
    //   navigate(callback, {state: {pageData: pageData}});
    // }
  };

  const suggestedName = searchedName ? searchedName.split(",")[0] : "";

  useEffect(() => {
    if (refReady && isActive) {
      map.openPopup(popupRef);
      if (inputRef.current !== null && inputRef.current !== undefined) {
        inputRef.current.focus();
      }
      // inputRef.current.focus()
    }
  }, [isActive, refReady, map]);

  useEffect(() => {
    setRefReady(false);
    setHighlight(false);
    setSearchedName(clickedMapLocation?.name);
    setNewPlaceName(suggestedName);
  }, [clickedMapLocation, suggestedName]);

  if (clickedMapLocation === null) return;

  const handleFormTextEntry = (event) => {
    setNewPlaceName(event.target.value);
  };
  const clear = () => {
    setNewPlaceName("");
    setSearchedName("");
    clearNewLocation(clickedMapLocation);
    setHighlight(false);
  };
  const saveAndClear = () => {
    if (!isLoggedIn()) {
      handleAddPlaceOnly({
        ...clickedMapLocation,
        name: "Current Location",
        preposition: "at",
      });
      clear();
    } 
    else if ( newPlaceName !== "") {
      handleAddPlaceOnly({
        ...clickedMapLocation,
        name: newPlaceName,
        preposition: "at",
      });

      clear();
    } else {
      setHighlight(true);
    }
  };

  return (
    <Marker
      isActive
      position={[clickedMapLocation.lat, clickedMapLocation.lon]}
      autoPanPadding={[50, 50]}>
      <Popup
        ref={(r) => {
          popupRef = r;
          setRefReady(true);
        }}>
        {isLoggedIn() ? (
          <Form
            onSubmit={(e) => {
              saveAndClear();
              e.preventDefault();
            }}>
            <Stack gap={2} className="mx-auto">
             
              {highlight && (
                <Form.Label className="warning">(Cant be blank!)</Form.Label>
              )}
              <Form.Control
                autoFocus
                autoComplete="off"
                ref={inputRef}
                name="name"
                type="text"
                value={newPlaceName}
                onChange={handleFormTextEntry}
                placeholder="Place Name"
              />
              {searchedName && <Form.Label>{searchedName}</Form.Label>}
              <Stack direction="horizontal">
                <Button
                  type="button"
                  variant="outline-secondary"
                  onClick={(e) => {
                    clear();
                    e.stopPropagation();
                  }}>
                  Cancel
                </Button>

                <Button
                  className="ms-auto"
                  type="button"
                  variant="primary"
                  onClick={(e) => {
                    saveAndClear();
                    e.stopPropagation();
                    e.preventDefault();
                  }}>
                  New Place
                </Button>
              </Stack>
            </Stack>
          </Form>
        ) : (
          // you have come here from the all ribbons, 
          // not logged in, and needing a location
          hasCallback() ? (
            <Form
            // onSubmit={(e) => {
            //   setNewPlaceName("Current Location")
            //   saveAndClear();
            //   e.preventDefault();
            // }}
            >
            <Stack gap={2} className="mx-auto">
              <h3>Current Location</h3>
              {searchedName && <Form.Label>{searchedName}</Form.Label>}
              <Stack direction="horizontal">
                <Button
                  type="button"
                  variant="outline-secondary"
                  onClick={(e) => {
                    clear();
                    e.stopPropagation();
                  }}>
                  Cancel
                </Button>

                <Button
                  className="ms-auto"
                  type="button"
                  variant="primary"
                  onClick={(e) => {
                    saveAndClear();
                    e.stopPropagation();
                    e.preventDefault();
                  }}>
                  Select Place
                </Button>
              </Stack>
            </Stack>
          </Form>  
          ) :(
          <Form>
            <Form.Group className="mb-3" controlId="form.name">
              <Form.Label style={{fontSize: "1.2em"}}>
                "Please Log in to define your own places"
              </Form.Label>
            </Form.Group>

            <Button
              type="button"
              variant="secondary"
              onClick={(e) => {
                clear();
                e.stopPropagation();
              }}>
              Ok, Sounds Good.
            </Button>
          </Form>


        ))}
      </Popup>
    </Marker>
  );
};
