import * as d3 from "d3";


import useProcessedWeather from "hooks/useProcessedWeather";
import {
  draw_time_axes_tz,
  draw_yaxes,
  draw_line,
  draw_marker_text,
} from "@src/util/drawfuncs";
import {make_xscale} from "@src/util/drawfuncs";
import {Ribbon} from "./ribbon";
import { isUndefined } from "lodash";

export const WetBulbRibbon = (props) => {
  const {data} = useProcessedWeather(
    props.currentPlacedActivity.place
  );
    
  if (isUndefined(data?.chartdata) || data.chartdata.length === 0) return;
  const xScale = make_xscale(
    props.dims.width,
    props.dims.pixelPerHour,
    data?.chartdata
  );

  const draw_temps = (
    g,
    data,
    width,
    height,
    tempTypeList,
    xScale
  ) => {
    //Yscales
    let maxtemp = d3.max(data.chartdata, function (d) {
      return d3.max(tempTypeList.map((name) => d[name]));
    });
    // roundup to closest 10
    maxtemp = Math.ceil(maxtemp / 10) * 10 + 5;

    let mintemp = d3.min(data.chartdata, function (d) {
      return d3.min(tempTypeList.map((name) => d[name]));
    });
    mintemp = Math.floor(mintemp / 10) * 10 - 5;
    const yScale = d3
      .scaleLinear()
      .domain([mintemp, maxtemp])
      .range([height, 0])
      .nice();

    draw_yaxes(g, yScale, width, "°", 0);
    draw_time_axes_tz(g, xScale, height, data.nightblocks, false, data?.metadata?.timezone);
    
    tempTypeList.forEach((name) => {
      draw_line(g, data.chartdata, name, xScale, yScale);
      draw_marker_text(
        g,
        data.chartdata,
        name,
        false,
        xScale,
        yScale,
        "°"
      );
    });
  };
  let draw_func = (svg) => {
    draw_temps(
      svg,
      data,
      props.dims.width,
      props.dims.unitHeight,
      ["wetbulb", "stullwetbulb"],
      xScale
    );
  };
  return (
    <div className="ribbon">
      <Ribbon draw_func={draw_func} dims={props.dims} />
    </div>
  );
};
