/* usePage is a hook to to access and edit page objects
from the page end point of the backend API.  Pages are
associated with users and have slugs.  they define the 
order and inclusion of ribbons on a page.
*/

import useSWR from "swr";

import {api_get, api_put, api_post, PAGE_PATH} from "api/backend";
import useUserData from "./useUserData";
import {isEmpty} from "lodash";

const usePage = (slug) => {
  const {mutate: mutateUserData} = useUserData();
  const url = `${PAGE_PATH}${slug}/`;
  const {data, error, mutate} = useSWR(slug ? url : null, api_get);

  const updatePage = async (newpage) => {
    // if we are logged in, then update the backend
    if (!isEmpty(localStorage.getItem("drfToken"))) {
      try {
        const updated_page = await api_put(`${PAGE_PATH}${slug}/`, newpage);
        mutate(updated_page);
        mutateUserData(); // tell user data to update - this will change the current forecast
      } catch (e) {
        console.log("Error from update page", e);
      }
      return newpage;
    }
    // else {
    //   // for anon users, just replace the pages with a new page.
    //   if (!userdata.pages) {
    //     userdata.pages = [];
    //   }
    //   const pageIndex = userdata.pages.findIndex((page) => page.slug === slug);
    //   if (pageIndex !== -1) {
    //     userdata.pages.splice(pageIndex, 1, newpage);
    //   } else {
    //     userdata.pages.push(newpage);
    //   }
    //   updateUserData(userdata);
    // }
  };

  const postNewPage = async (page) => {
    const newpage = await api_post(`${PAGE_PATH}`, page);
    return newpage;
  };

  return {
    page: data,
    isLoading: !error && !data,
    isError: error,
    updatePage,
    postNewPage,
    mutate,
  };
};

export default usePage;
