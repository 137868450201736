import useSWR from "swr";

import {api_get, ACTIVITY_PATH} from "api/backend";
import {api_put} from "api/backend";
import {isEmpty} from "lodash";
import useActivities from "./useActivities";
import useUserData from "./useUserData";
import useScoredWeather from "./useScoredWeather";

const useActivity = (slug) => {
  const {
    data: activity,
    error,
    mutate,
  } = useSWR(`${ACTIVITY_PATH}${slug}`, api_get);

  const {mutate: mutateUserData} = useUserData();
  const {mutate: mutateActivities} = useActivities();
  const {mutateScore} = useScoredWeather();

  const updateActivity = async (activity) => {
    if (!isEmpty(localStorage.getItem("drfToken"))) {
      try {
        const updated_activity = await api_put(
          `${ACTIVITY_PATH}${slug}/`,
          activity
        );
        mutate(updated_activity);
        mutateScore();
        mutateUserData(); // tell user data to update - this will change the current forecast
        mutateActivities(); // tell activities to update - this is just to ensure other containers with this data are updated with change
      } catch (e) {
        console.log("Error from updateActivity", e);
      }
      return activity;
    }
  };

  return {
    activity: activity,
    isLoading: !error && !activity,
    isError: error,
    mutate,
    updateActivity,
  };
};

export default useActivity;
