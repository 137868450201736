import useSWR from "swr";

import {api_get, DATASOURCE_PATH, PLACE_PATH} from "api/backend";
import {api_post, api_put} from "api/backend";
import {isEmpty, isUndefined} from "lodash";
import useUserData from "./useUserData";
// import useScoredWeather from "./useScoredWeather";
import useNWSWeather from "./useNWSWeather";

const useDataSources = (place, sourcetype=undefined, radius = 100, limit = 10) => {
  const lat = place?.lat;
  const lon = place?.lon;

  const {mutate: mutateUserDate} = useUserData();
  const {mutate: mutateWeather} = useNWSWeather(place);

  let querystring = `lat=${lat}&lon=${lon}&radius=${radius}&limit=${limit}`;
  if (sourcetype) {
    querystring += `&sourcetype=${sourcetype}`;
  }
  const {data: datasources, error} = useSWR(
    isUndefined(lat) || isUndefined(lon)
      ? null
      : `${DATASOURCE_PATH}?${querystring}`,
    api_get
  );

  const attachDataSource = async (place_slug, datasource_id) => {
    if (!isEmpty(localStorage.getItem("drfToken"))) {
      try {
        const updated_place = await api_post(
          `${PLACE_PATH}${place_slug}/attach_datasource/`,
          {
            id: datasource_id,
          }
        );

        mutateWeather(); // re-fetch weather data now that a new datasource is attached
        mutateUserDate();
        return updated_place;
      } catch (e) {
        console.log("Error attaching datasource to place", e);
      }
    } else {
      throw new Error("must be logged in");
    }
  };

  const clearDataSources = async (place_slug) => {
    if (!isEmpty(localStorage.getItem("drfToken"))) {
      let url = `${PLACE_PATH}${place_slug}/clear_datasources/`
      if (sourcetype) {
        url += `?sourcetype=${sourcetype}`
      }
      try {
        const place_with_no_datasources = await api_put(url);
        mutateWeather(); // re-fetch weather data now that a new datasource is attached
        mutateUserDate();

        return place_with_no_datasources;
      } catch (e) {
        console.log("Error clearing datasources from place", e);
      }
    } else {
      throw new Error("must be logged in");
    }
  };

  return {
    datasources: datasources?.results,
    isLoading: !error && !datasources,
    isError: error,
    attachDataSource,
    clearDataSources,
  };
};

export default useDataSources;
