import useProcessedWeather from "hooks/useProcessedWeather";
import {RibbonLabel} from "./ribbonlabel";

export const WeatherTypeRibbonLabel = (props) => {
  const {data} = useProcessedWeather(props.currentPlacedActivity.place);

  const label_lookup = {
    blowing_dust: "Blowing Dust",
    blowing_sand: "Blowing Sand",
    blowing_snow: "Blowing Snow",
    drizzle: "Drizzle",
    fog: "Fog",
    freezing_fog: "Freezing Fog",
    freezing_drizzle: "Freezing Drizzle",
    freezing_rain: "Freezing Rain",
    freezing_spray: "Freezing Spray",
    frost: "Frost",
    hail: "Hail",
    haze: "Haze",
    ice_crystals: "Ice Crystals",
    ice_fog: "Ice Fog",
    rain: "Rain",
    rain_showers: "Rain Showers",
    sleet: "Sleet",
    smoke: "Smoke",
    snow: "Snow",
    snow_showers: "Snow Showers",
    thunderstorms: "Thunderstorms",
    volcanic_ash: "Volcanic Ash",
    water_spouts: "Water Spouts",
    weather: "Weather Types", // this appears while loading
  };
  const showlabels = data.metadata?.weather_types
    ? data.metadata.weather_types
    : ["weather"];
  return (
    <RibbonLabel dims={props.dims}>
      {props.prependLabel && (
        <span className="legend-place legend-text">{props.prependLabel}</span>
      )}
      {showlabels.map((t) => {
        if (t in label_lookup) {
          return (
            <span key={t} className={"legend-text " + t}>
              {label_lookup[t]}
            </span>
          );
        }
      })}
    </RibbonLabel>
  );
};
