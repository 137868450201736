import useSWR from "swr";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";

import {api_get, api_put, USERDATA_PATH} from "api/backend";
import {loadUserProfile} from "hooks/useUser";

const useUserData = (id) => {
  const fetcher = async () => {
    const user = loadUserProfile();
    let userdata;
    if (isUndefined(id) && isUndefined(user?.userdata?.id)) {
      // neither the id passed in or the localstorate userdata contains an ID
      // this should mean the user has never logged in
      userdata = user.userdata;
    } else {
      // otherwise, there is an so go fetch user data from backend.
      userdata = await api_get(
        `${USERDATA_PATH}${id ? id : user?.userdata?.id}/`
      );
    }
    // when you get fresh userdata from the server update the local storage
    const json_user = JSON.stringify({...user, userdata: userdata});
    localStorage.setItem("user", json_user);
    return userdata;
  };

  const {data: userdata, error, mutate} = useSWR("userdata", fetcher);

  const updateUserData = async (userdata) => {
    // always update local storage
    if (isEmpty(userdata)) {
      throw new Error("userdata cannot be empty");
    }
    const user = loadUserProfile();
    const json_user = JSON.stringify({...user, userdata: userdata});
    localStorage.setItem("user", json_user);

    // optionally update backend when you have an id
    const userdata_id = id ? id : user?.userdata?.id;
    if (
      !(isUndefined(userdata_id) || isEmpty(localStorage.getItem("drfToken")))
    ) {
      mutate(api_put(`${USERDATA_PATH}${userdata_id}/`, userdata), {
        optimisticData: userdata,
        populateCache: true,
        revalidate: false,
        rollbackOnError: true,
      });
      return userdata;
    } else {
      // mutate and return for when there is no backend
      mutate(userdata);
      return userdata;
    }
  };

  const isLoggedIn = () => localStorage.getItem("drfToken")?.length > 0;

  return {
    userdata,
    isLoading: !error && !userdata,
    isError: error,
    mutate,
    updateUserData,
    isLoggedIn,
  };
};

export default useUserData;
